import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;

const ChangePasswordFunction = () => {
  const [authentication_with, setAuthentication_with] = useState(null);
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [type3, setType3] = useState("password");
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [OldPasswordError, setOldPasswordError] = useState("");
  const [NewPasswordError, setNewPasswordError] = useState("");
  const [CPasswordError, setCPasswordError] = useState("");
  const [TwoFA, setTwoFA] = useState("");
  const [StateValue, setStateValue] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [respStatus, setRespStatus] = useState(false);

  useEffect(() => {
    SwitchChange.bind();
    getUserDetails();
    GoogleAuthStatus();
    LoginActivity();
    SwitchApi();
  }, []);

  const getUserDetails = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      setAuthentication_with(resp.data[0].authentication_with);
      setTwoFA(resp.data[0].authentication_with);
    });
  };

  const GoogleAuthStatus = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/G_auth_status", {}, config).then((res) => {
      var resp = res.data;
    });
  };

  const SwitchApi = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/enable2faStatus", {}, config)
      .then((res) => {
        var resp1 = res.data;
        setStateValue(resp1.Data[0].enable_2fa);

        if (resp1.Data[0].enable_2fa === "N") {
          return false;
        } else {
        }
      });
  };

  const SwitchChange = (checked) => {
    if (StateValue == "N" && authentication_with === null) {
      toast.dismiss();
      toast.error("Please Select 2FA Option First.");
      return false;
    }

    this.setState({ checked });
    var ToogleValue = "";

    if (StateValue === "Y") {
      ToogleValue = "N";
    } else {
      ToogleValue = "Y";
    }

    if (authentication_with !== null || ToogleValue === "N") {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      };

      let LoginData = {
        enableValue: ToogleValue,
      };

      axios
        .post(baseUrl + "/frontapi/Update2faStatus", LoginData, config)
        .then((resp) => {
          var resp1 = resp.data;

          if (resp1.status === true) {
            return false;
          }
        });
    } else {
      toast.dismiss();
      toast.error("Verify Google Authentication !");
    }
  };

  const handleChange = (event) => {
    let { value, id } = event.target;
    // this.setState({ [id]: value, errMsg: "" });

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (id === "OldPassword") {
      setOldPassword(value);
      if (!value) {
        setOldPasswordError("This field is required");

        return false;
      }
      setOldPasswordError("");
    }

    if (id === "NewPassword") {
      setNewPassword(value);
      if (!value) {
        setNewPasswordError("This field is required");
        setCPasswordError("");

        return false;
      }

      if (!value.match(regex)) {
        setNewPasswordError(
          "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character"
        );
        setCPasswordError("");

        return false;
      } else {
      }

      if (CPassword && CPassword.length > 0) {
        if (value !== CPassword) {
          setNewPasswordError("");
          setCPasswordError("Confirm password miss-matched");

          return false;
        }
      }
      setNewPasswordError("");
      setCPasswordError("");
    }

    if (id === "CPassword") {
      setCPassword(value);
      if (!value) {
        setCPasswordError("This field is required");

        return false;
      }

      if (NewPassword && NewPassword.length > 0) {
        if (NewPassword !== value) {
          setCPasswordError("Confirm password miss-matched");

          return false;
        }
      }
      setCPasswordError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!OldPassword) {
      setOldPasswordError("This field is required");
      return false;
    }

    if (!NewPassword) {
      setNewPasswordError("This field is required");
      return false;
    }

    if (!NewPassword.match(regex)) {
      setNewPasswordError(
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character"
      );

      return false;
    }

    if (!CPassword) {
      setCPasswordError("This field is required");

      return false;
    }

    if (NewPassword != CPassword) {
      setCPasswordError("Confirm password miss-matched");
      return false;
    }

    setOldPasswordError("");
    setNewPasswordError("");
    setCPasswordError("");

    let LoginData = {
      NewPassword: NewPassword,
      OldPassword: OldPassword,
      CPassword: CPassword,
    };

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/changePassword", LoginData, config)
      .then((resp) => {
        var response = resp.data;

        if (response.status === true) {
          setOldPasswordError("");
          setNewPasswordError("");
          setCPasswordError("");

          toast.dismiss();
          toast.success(response.message);

          // localStorage.clear();
          setTimeout(() => {
            localStorage.removeItem("id");
            localStorage.removeItem("token");
            window.location.href = "/login";
          }, 1000);

          return false;
        } else {
          toast.dismiss();
          toast.error(response.message);
          return false;
        }
      });
  };

  const LoginActivity = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    if (!config) {
      window.location.href = "/Login";
    }

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTabledata(respNew.findData);
        setRespStatus(respNew.status);
      }
    });
  };
  const handleClickOld = () => {
    if (type1 === "text") {
      setType1("password");
    } else {
      setType1("text");
    }
  };

  const handleClickNew = () => {
    if (type2 === "text") {
      setType2("password");
    } else {
      setType2("text");
    }
  };
  const handleClickConfirm = () => {
    if (type3 === "text") {
      setType3("password");
    } else {
      setType3("text");
    }
  };

  return (
    <>
      <div className="col-lg-4 mb-3">
        <div className="white_box h_100 mb-0">
          <h5>Change Password</h5>
          <div className="bank-info info-main-outer mt-2">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group eye_pass eye_pass_two">
                    <label>Old Password</label>
                    <input
                      className="form-control"
                      type={type1}
                      name="password"
                      id="OldPassword"
                      maxLength={30}
                      onChange={handleChange}
                      value={OldPassword}
                      placeholder="Enter Old Password"
                    />
                    <span
                      className="password__show eye1"
                      onClick={handleClickOld}
                    >
                      {type1 === "text" ? (
                        <i className="las la-eye"></i>
                      ) : (
                        <i className="las la-low-vision"></i>
                      )}
                    </span>

                    <div style={{ color: "red" }}>{OldPasswordError}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group eye_pass eye_pass_two">
                    <label>New Password</label>
                    <input
                      type={type2}
                      className="form-control"
                      name="npassword"
                      id="NewPassword"
                      maxLength={30}
                      value={NewPassword}
                      onChange={handleChange}
                      placeholder="Enter New Password"
                    />
                    <span
                      className="password__show eye1"
                      onClick={handleClickNew}
                    >
                      {type2 === "text" ? (
                        <i className="las la-eye"></i>
                      ) : (
                        <i className="las la-low-vision"></i>
                      )}
                    </span>

                    <div style={{ color: "red" }}>{NewPasswordError}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group eye_pass eye_pass_two">
                    <label>Confirm New Password</label>
                    <input
                      type={type3}
                      className="form-control"
                      name="cpassword"
                      id="CPassword"
                      value={CPassword}
                      maxLength={30}
                      onChange={handleChange}
                      placeholder="Enter Confirm Password"
                    />
                    <span
                      className="password__show eye1"
                      onClick={handleClickConfirm}
                    >
                      {type3 === "text" ? (
                        <i className="las la-eye"></i>
                      ) : (
                        <i className="las la-low-vision"></i>
                      )}
                    </span>

                    <div style={{ color: "red" }}>{CPasswordError}</div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <button className="btn btn_man fs14">Change Password</button>
                </div>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default ChangePasswordFunction;
