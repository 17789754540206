import React from "react";

let error = "";

export const ImageValidation = (name, value) => {
  const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
  if (!value) {
    error = `This field is required`;
    return error;
  }

  if (value && value.name && !value.name.match(/\.(jpg|jpeg|png)$/)) {
    error = `Please select a valid image format jpg, jpeg & png`;
    return error;
  }

  if (value && value.size <= 10240) {
    error = `Image size should not be less than 10KB`;
    return error;
  }

  if (name === "Selfie") {
    if (value && value.size >= 5242880) {
      error = `Image size should not be more than 5MB`;
      return error;
    }
  } else {
    if (value && value.size >= 2097152) {
      error = `Image size should not be more than 2MB`;
      return error;
    }
  }
  // if(value.replace(/\s+/g, "").length == 0){
  //     error = `Please enter valid ${capitalized}`
  //     return error
  //  }
};
export const imageBase64 = (file, name, type) => {
  var base64 = localStorage[file];
  var base64Parts = base64.split(",");
  var fileFormat = base64Parts[0].split(";")[1];
  var fileContent = base64Parts[1];
  var pancardImagefile = new File([fileContent], name, { type: type });
  return pancardImagefile;
};
