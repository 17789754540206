import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ThirdPartyApi } from "./ThirdPartyApis";
import * as myConstList from "../Common/BaseUrl";
import { NomineeApi } from "./Apis";
const baseUrl = myConstList.baseUrl;
const Nominee = (props) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [nomineeIdStatus, setNomineeIdStatus] = useState("0");

  // errors
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [dobError, setDobError] = useState("");

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setName(props.userinfo[0].nominee_name);
      setMobile(props.userinfo[0].nominee_number);
      setDob(props.userinfo[0].nominee_dob);
      props.userinfo[0].nominee_status &&
        setNomineeIdStatus(props.userinfo[0].nominee_status);
    }
  }, []);

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameError("Please enter nominee name");
        return false;
      }
      setNameError("");
    }

    if (name === "mobile") {
      setMobile(value);

      if (!value) {
        setMobileError("Please enter nominee mobile number");
        return false;
      }

      let charAt1 = value.charAt(0);

      // if (charAt1 < 5) {
      //   setMobileError("Please enter valid nominee mobile number");
      //   return false;
      // }

      // if (value.length < 10 || value.length > 15) {
      //   setMobileError("Mobile number should be of 10-15 digits");
      //   return false;
      // }

      setMobileError("");
    }
    if (name === "dob") {
      setDob(value);
      setDobError("");
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!name && !mobile && !dob) {
      setNameError("Please enter nominee name");
      setMobileError("Please enter nominee mobile number");
      setDobError("Please enter nominee DOB");
      return false;
    }

    if (!name) {
      setNameError("Please enter nominee name");
      return false;
    }

    let error = checkSpace("nominee name", name);
    if (error.length > 0) {
      setNameError(error);
      return false;
    }

    if (!mobile) {
      setMobileError("Please enter nominee mobile number");
      return false;
    }

    // if (mobile.length < 10) {
    //   setMobileError("Mobile number should be of 10-15 digits");
    //   return false;
    // }

    // if (mobile.length > 15) {
    //   setMobileError("Mobile number should be of 10-15 digits");
    //   return false;
    // }

    const firstDigit = parseInt(mobile.charAt(0));

    // if (firstDigit < 5) {
    //   setMobileError("Please enter valid nominee mobile number");
    //   return false;
    // }

    if (!dob) {
      setDobError("Please enter nominee DOB");
      return false;
    }

    // Convert the input date string to a Date object
    const inputDate = new Date(dob);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    let age = currentDate.getFullYear() - inputDate.getFullYear();

    // Check if the input date has occurred 18 years ago or more
    if (
      currentDate.getMonth() < inputDate.getMonth() ||
      (currentDate.getMonth() === inputDate.getMonth() &&
        currentDate.getDate() < inputDate.getDate())
    ) {
      age--;
    }

    if (age < 16) {
      setDobError("Nominee age should be least 16 years old");
      return false;
    }

    setBtnDisable(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      name: name,
      mobile: mobile,
      dob: dob,
    };

    let result = await NomineeApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      props.resendResult(true);
      window.location.reload();
      toast.success(result.message);
    } else {
      props.resendResult(true);
      toast.error(result.message);
    }
  };

  return (
    <>
      <div className="upi-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">
                Nominee Verification (Optional)
              </h6>
              <b className="mt-0">Requirements</b>
              <ul className="dit_ul mb-4">
                <li>Please Fill Your Nominee Form</li>
              </ul>
              <div className="row">
                <div className="form-group col-lg-4 col-md-6">
                  <label>Nominee Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Nominee Name"
                    name="name"
                    value={name}
                    onChange={handleChange101}
                    disabled={
                      nomineeIdStatus !== "0" && nomineeIdStatus !== "3"
                        ? true
                        : false
                    }
                  />
                  {nameError && <div className="text-danger">{nameError}</div>}
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label>Nominee Mobile Number </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Nominee Mobile Number"
                    name="mobile"
                    value={mobile}
                    onKeyDown={handleValidation}
                    onChange={handleChange101}
                    disabled={
                      nomineeIdStatus !== "0" && nomineeIdStatus !== "3"
                        ? true
                        : false
                    }
                  />
                  {mobileError && (
                    <div className="text-danger">{mobileError}</div>
                  )}
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label>Nominee DOB </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Nominee DOB"
                    name="dob"
                    value={dob}
                    onChange={handleChange101}
                    disabled={
                      nomineeIdStatus !== "0" && nomineeIdStatus !== "3"
                        ? true
                        : false
                    }
                  />
                  {dobError && <div className="text-danger">{dobError}</div>}
                </div>
              </div>

              {nomineeIdStatus !== "1" && (
                <div className="save-btn">
                  <button
                    className="btn btn_man mt-3"
                    disabled={btnDisable || nomineeIdStatus == "1"}
                    onClick={submitForm}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nominee;
