import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const InrDepositRequest = () => {
  const meta = {
    title: `Inr Deposit Requests | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [allData, setAllData] = useState("");
  const [TransactionsStatus, setTransactionsStatus] = useState("");

  useEffect(() => {
    
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    inrDepositReqApi();
  }, []);

  const inrDepositReqApi = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/depositInrRequest", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          setAllData(resp.data);
          setTransactionsStatus(resp.status);
        }
      });
  };

  const showTableHtml = (value) => {
    if (TransactionsStatus === true) {
      const html = [];
      value &&
        value.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{e.amount}</td>
              <td>{e.actual_amount ? e.actual_amount : "-"}</td>
              <td>{Moment(e.createdAt).format("lll")}</td>
              <td>
                {e.is_approved === "3" ? (
                  <span className="text-danger">Rejected</span>
                ) : e.is_approved === "2" ? (
                  <span> Completed </span>
                ) : (
                  "Pending"
                )}
              </td>
              <td>{e.rejected_message ? e.rejected_message : "-"}</td>
            </tr>
          );
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="">
          <div className="bg_light">
            <div className="new_inpage">
              <div className="container container2">
                <div className="white_box">
                  <ul className="nav nav-tabs2 mb-3 ">
                    <li className="nav-item">
                      <Link to="/mainWalletTransactions"> Main Wallet </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/spotHistory"> Spot </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinDeposite"> Deposit Coin </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinFundRequest"> Withdrawal </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrDepositRequest" className="active">
                        {" "}
                        INR Deposit Request{" "}
                      </Link>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="Balance">
                      <div className=" top_balance"></div>
                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Amount</th>
                                    <th>Requested Amount</th>
                                    <th>Date & Time</th>
                                    <th>Status</th>
                                    <th>Remark</th>
                                  </tr>
                                </thead>
                                {showTableHtml(allData || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane " id="Transactions">
                      <div className="tab-content">
                        <div className="tab-pane active " id="Ledger"></div>
                        <div className="tab-pane" id="Transactions2">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Created</th>
                                    <th>Deposit</th>
                                    <th>Withdraw</th>
                                    <th className="tr">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Nov 20, 2020</td>
                                    <td>200</td>
                                    <td>Description </td>
                                    <td className="tr">0</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </DocumentMeta>
  );
};

export default InrDepositRequest;
