import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment";
import LeftTab from "../Common/LeftTab";
import ChangePasswordFunction from "./ChangePasswordFunction";
const meta = {
  title: "Profile | VenCrypto  Exchange ",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://venrypto.com",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Profile = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [kycStatus, setKycStatus] = useState("N");
  const [selfieStatus, setSelfieStatus] = useState(0);
  const [passportStatus, setPassportStatus] = useState(0);
  const [identityStatus, setIdentityStatus] = useState(0);
  const [pancardStatus, setPancardStatus] = useState(0);
  const [image, setImage] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [qrcodeurl, setQrcodeurl] = useState("assets/img/qr-dummy.png");
  const [SKey, setSKey] = useState("");
  const [checkTwoFa, setCheckTwoFa] = useState("");
  const [vcode, setVcode] = useState("");
  const [TwoFaText, setTwoFaText] = useState("");
  const [message, setMessage] = useState("");
  const [isBlur, setIsBlur] = useState(true);
  const [blockStatus, setBlockStatus] = useState(false);
  useEffect(() => {
    getUserDetails();
    LoginActivity();
    generateGoogleCode();
  }, []);

  const getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setFirstName(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        setEmail(resp.data[0].email);
        setMobile_number(resp.data[0].mobile_no);
        setSelfieStatus(resp.data[0].selfie_status);
        setPassportStatus(resp.data[0].passport_status);
        setIdentityStatus(resp.data[0].identity_status);
        setPancardStatus(resp.data[0].image3_status);
        setKycStatus(resp.data[0].kycVerification_status);
        setImage(resp.data[0].profile_image);
        setBlockStatus(resp.data[0].is_deleted);
        if (
          resp.data[0].enable_2fa == "N" ||
          resp.data[0].enable_2fa == "null"
        ) {
          setTwoFaText("Enable");
        }
        if (resp.data[0].enable_2fa == "Y") {
          setTwoFaText("Disable");
        }
        setCheckTwoFa(resp.data[0].enable_2fa);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") != "null" &&
          localStorage.getItem("token") !== null
        ) {
          toast.error(resp.message);
          localStorage.clear();
          setTimeout(() => this.props.history.push("/login"), 2000);
        }
      }
    });
  };

  const deleteUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/delete-user", {}, config).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status === true) {
        toast.success(resp.message);
        localStorage.clear();
        setTimeout(() => (window.location.href = "/login"), 2000);
      } else {
        toast.error(resp.message);
      }
    });
  };
  const generateGoogleCode = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/generateGoogleCode", {}, config)
      .then((resp1) => {
        var resp = resp1.data;
        setQrcodeurl(resp.data.qrCodeUrl);
        setSKey(resp.data.secretKey);
      });
  };

  const handleChange = (event) => {
    var vcode = event.target.value;

    if (vcode.replace(/\s+/g, "").length == 0) {
      setMessage("This field is required");
      setVcode(vcode);
      return false;
    }
    setMessage("");
    setVcode(vcode);
  };

  const verifyCode = () => {
    if (!vcode) {
      setMessage("This field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let faData = {
      code: vcode,
      TwoFaText: TwoFaText,
    };
    axios
      .post(baseUrl + "/frontapi/verifyGoogleCode", faData, config)
      .then((resp) => {
        setIsBlur(false);

        if (resp.data.status === true) {
          toast.dismiss();
          toast.success(resp.data.message);
          setVcode("");
          getUserDetails();
          return false;
        }
        toast.dismiss();
        toast.error(resp.data.message);
      });
  };

  const LoginActivity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTabledata(respNew.findData);
        setRespStatus(respNew.status);
      }
    });
  };

  function modifyText(text) {
    if (text.length <= 16) {
      return text;
    } else {
      return text.substr(0, 13);
    }
  }

  function modifyMobile(text) {
    if (text.length <= 13) {
      return text;
    } else {
      return text.substr(0, 10) + "...";
      // return text.substr(0, 13);
    }
  }

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const updateProfileImage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .post(baseUrl + "/frontapi/update-profile-image", formData, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div class="container container2">
              <LeftTab />
              <div className="google-auth ">
                <div className="row">
                  <div className="auth-left col-lg-3 mb-3">
                    <div className="white_box h_100 mb-0">
                      <div className="user_profile">
                        {image == "" || image == undefined || image == null ? (
                          <img
                            src="/assets/img/user_profile.png"
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={baseUrl + "/static/profile/" + image}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                        <div className="edit-profile position-absolute">
                          <input
                            type="file"
                            // ref={myref}
                            id="img1"
                            style={{ display: "none" }}
                            onChange={updateProfileImage}
                          />
                          <label htmlFor="img1" className="editlabel">
                            <i className="fa fa-edit"></i>
                          </label>
                        </div>
                      </div>

                      {/* <div className="user_profile">
                        {image == "" || image == undefined || image == null ? (
                          <img
                            src="/assets/img/user_profile.png"
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={baseUrl + "/static/profile/" + image}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div> */}

                      <h5 className="user text-center mt-3 mb-5">
                        {firstName} {lastName == "null" ? "" : lastName}
                      </h5>

                      <div className="profile-info info-main-outer ">
                        <ul>
                          <li className="user row mb-2">
                            <span className="col-auto">Email:</span>
                            <span className="col-auto ml-auto">
                              {email && email.length > 16
                                ? ["top"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          <strong>{email}</strong>
                                        </Tooltip>
                                      }
                                    >
                                      <a>
                                        {email.length > 0 && modifyText(email)}
                                        ...
                                      </a>
                                    </OverlayTrigger>
                                  ))
                                : !email
                                ? "-"
                                : email}
                            </span>{" "}
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">Mobile No.:</span>
                            <span className="col-auto ml-auto">
                              {mobile_number && mobile_number.length > 13
                                ? ["top"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          <strong>{mobile_number}</strong>
                                        </Tooltip>
                                      }
                                    >
                                      <a>
                                        {mobile_number.length > 0 &&
                                          modifyMobile(mobile_number)}
                                      </a>
                                    </OverlayTrigger>
                                  ))
                                : !mobile_number
                                ? "-"
                                : mobile_number}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">KYC:</span>
                            <span className="col-auto ml-auto">
                              {selfieStatus == "3" ||
                              passportStatus == "3" ||
                              identityStatus == "3" ||
                              pancardStatus == "3"
                                ? "Rejected"
                                : kycStatus === "Y"
                                ? "Completed"
                                : "Pending"}
                            </span>
                          </li>

                          {blockStatus != 2 && (
                            <div className="form-group col-lg-6 col-12">
                              <button
                                onClick={deleteUser}
                                className="btn btn_man"
                              >
                                Delete User
                              </button>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ChangePasswordFunction />

                  <div className="auth-left col-lg-5 mb-3">
                    <div className="white_box h_100 mb-0">
                      <h5>Google Authentication</h5>
                      <div className="google-auth">
                        <div className="auth-left mb-3">
                          <div
                            className={
                              isBlur == true
                                ? "qr-outer kyc_bg qr_load"
                                : "qr-outer kyc_bg"
                            }
                          >
                            <img
                              className="img-fluid"
                              src={qrcodeurl}
                              alt="qrCode"
                            />
                          </div>
                        </div>

                        <div className="auth-right">
                          <h5 className="kyc_h5">
                            Key :<span> {SKey}</span>{" "}
                          </h5>

                          <h5>
                            Status: {checkTwoFa == "Y" ? "Enabled" : "Disabled"}
                          </h5>
                          <label className="fw-500">Authentication Code</label>
                          <div className="row">
                            <div className="form-group col-lg-7 col-12">
                              <input
                                type="number"
                                className="form-control"
                                name="vcode"
                                placeholder="Enter Code"
                                onKeyDown={handleValidation}
                                value={vcode}
                                onChange={handleChange}
                              />

                              <div
                                style={
                                  message === "code verified"
                                    ? { color: "green" }
                                    : { color: "red" }
                                }
                              >
                                {message}
                              </div>
                            </div>
                            <div className="form-group col-lg-5 col-12">
                              <button
                                onClick={verifyCode}
                                className="btn btn_man w100px"
                              >
                                {TwoFaText}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default Profile;
