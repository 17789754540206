import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,
      spotTab: "",
      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: "",
      showData: 1,
      totalBalance: 0,
      defaultValue: "",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    this.trasactionList();
    this.WalletAllCoinBalance();
  }

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };

  trasactionList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/transaction-list", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;
        if (resp.status) {
          this.setState({
            allData: resp.data,
            trasactionList: resp.data.main_wallet,
            cryptoIdWallet: resp.data.coin_id,
            TransactionsStatus: resp.status,
            defaultValue: resp.data[0].walletSum[0].walletAmount,
            defaultWalletTable: resp.data[0].wallet,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };

  showTableHtml = (value) => {
    if (this.state.TransactionsStatus === true) {
      const html = [];
      let balance = 0;
      let index;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].walletAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;
      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>
                {e.tx_type == "buy_launchpad"
                  ? "Launchpad Bought"
                  : e.tx_type == "add_launchpad_coin"
                  ? "Launchpad Coin"
                  : e.tx_type == "joining_bonus"
                  ? "Joining Bonus"
                  : e.tx_type == "referral_bonus"
                  ? "Referral Bonus"
                  : e.tx_type == "buy_stake"
                  ? "Stake Bought"
                  : e.tx_type == "blocked_by_admin"
                  ? "Stake Blocked By Admin"
                  : e.tx_type == "un_stake_amount"
                  ? "UnStaked Stake"
                  : e.tx_type == "staking_closed_amount"
                  ? "Staking Closed"
                  : e.trans_type === "deposit"
                  ? "Deposit"
                  : e.tx_type == "Wallet to transactions"
                  ? "Wallet to Spot"
                  : e.tx_type == "purchase"
                  ? "Withdrawal Refund"
                  : e.tx_type}
              </td>
              {/* <td>{Math.abs(e.coin_amount)}</td> */}
              <td>
                {e.coin_amount - (e.coin_amount * e.admin_commission) / 100}
              </td>
              <td>{WalletBAlnace}</td>
              <td className="ip_width">{e.tx_id}</td>
              <td>{value.name}</td>
              <td className="td_date">
                {Moment.utc(e.created).local().format("lll")}
              </td>

              <td>
                {e.tx_type == "withdrawal"
                  ? e.WithdrawlRequest == "0"
                    ? "Pending"
                    : e.WithdrawlRequest == "1"
                    ? "Completed"
                    : "Rejected"
                  : e.status == "completed"
                  ? "Completed"
                  : "Pending"}

                {/* {e.status == "completed" ? "Completed" : "Pending"} */}
              </td>
            </tr>
          );
          WalletBAlnace = WalletBAlnace - e.coin_amount;
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  renderCoinList = (allData) => {
    let { mainWallet } = this.state;
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li key={index} onClick={() => this.OpenMain(item)}>
          <span className={active ? "active" : <></>}>{item.name}</span>
        </li>
      );
    });
  };
  render() {
    const meta = {
      title: `Transactions | ${ProductName}  Exchange`,
      description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { showData, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  {/* <LeftTab /> */}
                  <div className="white_box">
                    <ul className="nav nav-tabs2 mb-3 ">
                      <li className="nav-item">
                        <Link to="/mainWalletTransactions" className="active">
                          {" "}
                          Main Wallet{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/spotHistory"> Spot </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinDeposite"> Deposit Coin </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinFundRequest"> Withdrawal </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/deposit-inr-request">
                          {" "}
                          Deposit INR Requests{" "}
                        </Link>
                      </li> */}
                    </ul>
                    <ul className="nav nav-tabs offer_beu spot">
                      {this.renderCoinList(allData || [])}
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className="top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Transaction Type</th>
                                      {/* <th>Transfer Amount</th> */}
                                      <th>Amount</th>
                                      <th>Final Balance</th>
                                      <th className="ip_width">
                                        Transaction ID
                                      </th>
                                      <th>Currency Type </th>
                                      <th>Date & Time</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  {this.showTableHtml(
                                    this.state.showData || []
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

export default transactions;
