import axios from "axios";
import * as myConstList from "../Common/BaseUrl";
const baseUrl = myConstList.baseUrl;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
export const MobileApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/mobileNumber", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const panNumberApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/pancardNumber", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const panImageApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/pancard", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const aadhaarNumberApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/docNumber", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const aadhaarImageApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/identity", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const NomineeApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/nomineeApi", params, config)
    .then((res) => {
      return res.data;
    }));
};

export const UpiIdApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/upiId", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const passportApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/passport", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const SelfieApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/selfie", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const SelfieClickByUser = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/cameraSelfie", params, config)
    .then((res) => {
      return res.data;
    }));
};
export const faceMatchApi = async (config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/facethirdparty", {}, config)
    .then((res) => {
      return res.data;
    }));
};
export const BankApi = async (params, config) => {
  let result;
  return (result = await axios
    .post(baseUrl + "/frontapi/BankInfo", params, config)
    .then((res) => {
      return res.data;
    }));
};
