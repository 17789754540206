import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import LeftTab from "../Common/LeftTab";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "Refer & Earn | VenCrypto  Exchange ",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://VenCrypto  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const ReferFunction = () => {
  const [ReferCode, setReferCode] = useState("");
  const [referUrl, setReferUrl] = useState("");
  const [referList, setReferList] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [copySuccessUrl, setcopySuccessUrl] = useState("");
  const [referStatus, setReferStatus] = useState(false);
  const [referAmount, setReferAmount] = useState("");
  const [reerCoin, setReerCoin] = useState("");

  useEffect(() => {
    GetUserData();
    getReferalInfo();
  }, []);

  const copyCodeToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = ReferCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopySuccess(true);
  };

  const copyCodeToClipboardUrl = () => {
    const el = document.createElement("textarea");
    el.value = referUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setcopySuccessUrl(true);
  };

  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };

    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.data[0].kycVerification_status === "Y") {
        setReferCode(resp.data[0].refer_id);

        if (window.location.hostname === "192.168.1.9") {
          setReferUrl(
            `http://192.168.1.9:3002/register/${resp.data[0].refer_id}`
          );
        }

        if (window.location.hostname === "localhost") {
          setReferUrl(
            `http://localhost:3002/register/${resp.data[0].refer_id}`
          );
        }
        if (
          window.location.hostname === "vencrypto.technoloaderitservices.com"
        ) {
          setReferUrl(
            `http://vencrypto.technoloaderitservices.com/register/${resp.data[0].refer_id}`
          );
        }
        if (window.location.hostname === "vencrypto.com") {
          setReferUrl(
            `https://vencrypto.com/register/${resp.data[0].refer_id}`
          );
        }
      } else {
        setReferUrl("");
        setReferCode("");
      }

      GetReferList(resp.data[0].refer_id);
    });
  };

  const getReferalInfo = () => {
    axios.get(baseUrl + "/api/getReferralInfo").then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setReferAmount(resp.data[0].referral_bonus);
        setReerCoin(resp.data[0].name);
      }
    });
  };

  const GetReferList = (ReferCode) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/refer-data", {}, config).then((res) => {
      let resp = res.data;
      if (resp.status === true) {
        setReferStatus(resp.status);
        setReferList(resp.data);
      }
    });
  };

  function maskEmail(email) {
    // Split the email address into local part and domain part

    if (email) {
      var parts = email.split("@");

      // Get the local part and domain part
      var localPart = parts[0];
      var domainPart = parts[1];

      // Mask all characters in the local part except the first 4
      var maskedLocalPart =
        localPart.substring(0, 4) + "*".repeat(localPart.length - 4);

      // Concatenate the masked local part with the domain part
      var maskedEmail = maskedLocalPart + "@" + domainPart;

      return maskedEmail;
    } else {
      return "-";
    }
  }

  const showReferListHtml = () => {
    if (referStatus) {
      var html = [];
      referList.forEach((item, s) => {
        let showName = "";

        if (item.user_country_code == "+91") {
          showName = item.user_name;
        } else {
          showName = maskEmail(item.user_email);
        }

        html.push(
          <tr>
            <td>{s + 1}</td>
            <td>
              <>
                {
                  <img
                    className="img-fluid"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                }
                {item.short_name}
              </>
            </td>
            <td>{item.coin_amount}</td>
            <td>{showName}</td>
            {/* <td>{item.tx_type}</td> */}
            <td>{Moment.utc(item.created).local().format("lll")}</td>

            {/* <td>{item.email || item.mobile}</td> */}
          </tr>
        );
      });
      return html;
    }
  };

  return (
    <div className="">
      <DocumentMeta {...meta}>
        <LoginHeader />
        <div className="new_inpage">
          <div className="container container2">
            <LeftTab />

            <div className="refer-main-outer">
              <div className="row">
                <div className="col-md-4">
                  <h5>Refer & Earn</h5>
                  <div class="refer-left-outer white_box_refer">
                    <div className="refer-img-outer text-center mb-4">
                      <img
                        className="img-fluid"
                        src="/assets/img/refer.png"
                        alt="refer"
                      />
                    </div>
                    <h6>
                      Refer us to your friends or family & get {referAmount}{" "}
                      {reerCoin} as reward.
                    </h6>
                    <h6>Refer Code</h6>
                    <div class="form-group position-relative">
                      <input
                        className="form-control fs12"
                        type="textarea"
                        readOnly
                        name="wallet_address"
                        value={ReferCode}
                      />
                      {copySuccess ? (
                        <div
                          style={{ color: "rgb(0, 155, 33)", fontWeight: 600 }}
                        >
                          Referral Code Copied
                        </div>
                      ) : null}
                      {ReferCode && (
                        <button
                          type="button"
                          className="btn btn_man position-absolute copy_button"
                          onClick={copyCodeToClipboard}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>

                    <h6>Refer URL</h6>
                    <div class="form-group position-relative">
                      <input
                        className="form-control fs12"
                        type="textarea"
                        readOnly
                        name="wallet_address"
                        value={referUrl}
                      />
                      {copySuccessUrl ? (
                        <div
                          style={{ color: "rgb(0, 155, 33)", fontWeight: 600 }}
                        >
                          Referral Code Url Copied
                        </div>
                      ) : null}
                      {referUrl && (
                        <button
                          type="button"
                          className="btn btn_man position-absolute copy_button"
                          onClick={copyCodeToClipboardUrl}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="refer-history-outer white_box_refer">
                    <h5>Refer History</h5>
                    <div className="refer-right-outer">
                      <table className="w-100 text-left refer-history">
                        <tr>
                          <th>Sr No.</th>
                          <th>Currency Name</th>
                          <th>Amount</th>
                          <th>Referred To</th>
                          <th>Date & Time</th>
                        </tr>
                        {showReferListHtml()}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default ReferFunction;
