import React, { Component } from "react";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";

class TradingViewChart extends Component {
  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.tvWidget = null; // Initialize a reference to the TradingView widget
    this.theam = localStorage.getItem("theme") === "true" ? "light" : "dark";
  }

  componentDidMount() {
    if (localStorage.getItem("theme") === "true") {
      this.theam = "light";
    } else {
      this.theam = "dark";
    }

    // if (localStorage.getItem("exType") == "binance") {
    // } else {
    //   this.initChart();
    // }
    this.checkExchange();
  }

  checkExchange = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        var apiResp = respData.data;
        var apiRespData = apiResp.data;
        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp.success === true) {
                localStorage.setItem("exType", apiExchangeResp.data.exchange);
              } else {
                this.initChart();
                localStorage.setItem("exType", "notExchange");
              }
            });
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (localStorage.getItem("theme") === "true") {
      this.theam = "light";
    } else {
      this.theam = "dark";
    }
    if (this.tvWidget) {
      this.tvWidget.onChartReady(() => {
        this.tvWidget.changeTheme(this.theam);
      });
    }
    const { params } = this.props;
    if (
      params.firstCoin != prevProps.params.firstCoin ||
      params.secondCoin != prevProps.params.secondCoin
    ) {
      this.updateSymbol();
    }
  }

  initChart() {
    const { params } = this.props;
    const pairName = params.firstCoin + params.secondCoin;
    // Initialize the TradingView widget with the initial symbol

    this.tvWidget = new window.TradingView.widget({
      fullscreen: false,
      symbol: pairName,
      interval: "1D",
      container: this.chartContainerRef.current,
      height: 400,
      width: "100%",
      //   custom_css_url:"/css/TradingView.css",
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        baseUrl + "/frontapi"
      ),
      library_path: "/charting_library/",
      locale: "en",

      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "om",
      user_id: "public_user_id",
      theme: this.theam,
      timezone: "Asia/Kolkata",
      overrides: {
        "mainSeriesProperties.style": 8,
      },
      drawings_access: {
        type: "black",
        tools: [
          {
            name: "Brush",
          },
          {
            name: "Rectangle",
          },
        ],
      },
    });
    this.tvWidget.onChartReady(() => {
      this.updateSymbol();
      // this.checkTheamChange();
    });
  }

  toggleChartTheme() {
    this.initChart();
  }

  updateSymbol() {
    // Update the symbol property of the existing widget instance
    if (this.tvWidget) {
      const { params } = this.props;
      const pairName = params.firstCoin + params.secondCoin;
      this.tvWidget && this.tvWidget.setSymbol(pairName, "1D", () => {});
    }
  }

  checkTheamChange() {
    if (this.tvWidget) {
      const currentThema = this.tvWidget.getTheme();
    }
  }

  componentWillUnmount() {
    if (this.tvWidget) {
      this.tvWidget.remove();
    }
  }

  render() {
    return <div ref={this.chartContainerRef} id="tv_chart_container"></div>;
  }
}

export default TradingViewChart;
