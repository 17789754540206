import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import ExchangeHeader from "../Common/ExchangeHeader";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { Oval } from "react-loader-spinner";

const ExchangeVerify = (props) => {
  const params = useParams();
  const [themeRefresh, setThemeRefresh] = useState(false);
  const [portfolioData, setPortfolioData] = useState("");

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      axios
        .get(baseUrl + "/frontapi/getUrl1")
        .then((res) => {
          if (res.data.status) {
            const pairName = `/${res.data.data[0].firstCoinName}/${res.data.data[0].secondCoinName}`;
            window.location.href = `/exchange${pairName}`;
          }
        })
        .catch((err) => {});
    }
  }, [params.firstCoin, params.secondCoin]);
  const changeTheme = () => {
    setThemeRefresh(!themeRefresh);
  };
  return (
    <div>
      <ExchangeHeader graphColor={changeTheme} portfolioData={portfolioData} />
      <div
        className="d-flex justify-content-center w-100 align-items-center"
        style={{ height: "100vh" }}
      >
        <Oval
          visible={true}
          height="250"
          width="150"
          color="#20376a"
          ariaLabel="oval-loading"
          secondaryColor="#20376a"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
};
export default ExchangeVerify;
