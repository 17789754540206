import React from "react";
import axios from "axios";
import { baseUrl, socketUrl } from "../../Common/BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import socketIOClient from "socket.io-client";

function formatPrice(value) {
  if (!value) return "0.00";

  // Convert the value to a float to avoid errors
  const num = parseFloat(value);

  // Determine the number of decimal places based on the value itself
  let formattedNum;
  if (num === 0) {
    formattedNum = "0.00";
  } else if (Math.abs(num) < 0.0001) {
    formattedNum = num.toFixed(8);
  } else if (Math.abs(num) < 0.01) {
    formattedNum = num.toFixed(6);
  } else {
    formattedNum = num.toFixed(2);
  }

  // Regex to remove unnecessary trailing zeros and any unnecessary decimal point
  return formattedNum.replace(/(\.0+|(\.[0-9]*[1-9])0+)$/, "$2");
}

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      topGainerData: [],
      topLoserData: [],
    };
    this.clientRef = React.createRef();
  }

  componentDidMount() {
    // this.GetCoinData();
    this.GetGainerData();
    this.GetLoserData();
    if (!this.clientRef.current) {
      this.clientRef.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      this.clientRef.current.on("connect", () => {
        this.handleSocketData(
          this.clientRef.current,
          this.clientRef.current.id
        );
      });
      this.clientRef.current.emit("coinPairDataLive");
    }
  }

  componentWillUnmount() {
    console.log("WebSocket connection will be closed on unmount.");
    this.clientRef.current.disconnect();
  }

  handleSocketData = (socket, id) => {
    socket.off("coinPairDataLiveResult");
    socket.on("coinPairDataLiveResult", (coinListData) => {
      const { data, status } = coinListData;
      this.setState({ CoinTableData: data, coinTableStatus: status });
    });
  };

  GetCoinData = () => {
    axios.get(baseUrl + "/frontapi/Coin_Pair_Data", {}).then((res) => {
      var resp = res.data;

      this.setState({ CoinTableData: resp.data, coinTableStatus: resp.status });
    });
  };

  GetGainerData = () => {
    axios.get(baseUrl + "/frontapi/top_gainers", {}).then((res) => {
      var resp = res.data;

      this.setState({ topGainerData: resp.data });
    });
  };

  GetLoserData = () => {
    axios.get(baseUrl + "/frontapi/top_loosers", {}).then((res) => {
      var resp = res.data;

      this.setState({ topLoserData: resp.data });
    });
  };

  AddressListHtml = () => {
    if (this.state.coinTableStatus === true) {
      const html = [];

      this.state.CoinTableData.map((value, i) => {
        return html.push(
          <tr>
            <td>
              <img
                style={{ maxWidth: "35px" }}
                src={baseUrl + "/static/currencyImage/" + value.coinIcon}
                alt=""
              />
            </td>
            <td>
              {value.coin_first_name} / {value.coin_Second_name}
            </td>
            <td>{formatPrice(value.currentPrice)}</td>
            <td
              className={
                value.changePriceAmount < 0 ? "text-danger" : "text-success"
              }
            >
              {formatPrice(value.changePriceAmount)}
            </td>
          </tr>
        );
      });
      return <tbody className="main">{html}</tbody>;
    }
  };

  changeClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  GainerHtml = () => {
    if (this.state.topGainerData && this.state.topGainerData.length > 0) {
      const html = [];
      this.state.topGainerData.map((value, i) => {
        var percentShow =
          ((parseFloat(value.current_price) -
            parseFloat(value.oneday_before_price)) *
            100) /
          value.oneday_before_price;

        var color = percentShow >= 0 ? "green" : "red";

        return html.push(
          <div className="col-md-4 col-12 more-space">
            <div className="winloss-inner d-flex flex-wrap align-items-center">
              <div className="winloss-left">
                <div className="left-top d-flex align-items-center">
                  <div className="img-outer">
                    <img
                      src={baseUrl + "/static/currencyImage/" + value.icon}
                      alt="CurrencyImage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content-outer">
                    <span>
                      <b>
                        {value.first_coin_name}/{value.second_coin_name}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="left-bottom">
                  <p className="text" style={{ color: color }}>
                    <span className="d-block text-white">Last Price</span>{" "}
                    {percentShow == 0 || isNaN(percentShow)
                      ? "0"
                      : percentShow.toFixed(2)}{" "}
                    %
                  </p>
                </div>
              </div>
              <div className="winloss-right">
                <p className="text" style={{ color: color }}>
                  {" "}
                  {percentShow == 0 || isNaN(percentShow)
                    ? "0"
                    : percentShow.toFixed(2)}{" "}
                  %
                </p>
                <div className="trade-btn">
                  <a
                    href={
                      "/exchange/" +
                      value.first_coin_name +
                      "/" +
                      value.second_coin_name
                    }
                    className="btn btn_man"
                  >
                    Trade Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return html;
    }
  };

  LoserHtml = () => {
    if (this.state.topLoserData && this.state.topLoserData.length > 0) {
      const html = [];
      this.state.topLoserData.map((value, i) => {
        var percentShow =
          ((parseFloat(value.current_price) -
            parseFloat(value.oneday_before_price)) *
            100) /
          value.oneday_before_price;

        var color = percentShow >= 0 ? "green" : "red";

        return html.push(
          <div className="col-md-4 col-12 more-space">
            <div className="winloss-inner d-flex flex-wrap align-items-center">
              <div className="winloss-left">
                <div className="left-top d-flex align-items-center">
                  <div className="img-outer">
                    <img
                      src={baseUrl + "/static/currencyImage/" + value.icon}
                      alt="CurrencyImage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content-outer">
                    <span>
                      <b>
                        {value.first_coin_name}/{value.second_coin_name}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="left-bottom">
                  <p className="text" style={{ color: color }}>
                    <span className="d-block text-white">Last Price</span>{" "}
                    {percentShow == 0 || isNaN(percentShow)
                      ? "0"
                      : percentShow.toFixed(2)}{" "}
                    %
                  </p>
                </div>
              </div>
              <div className="winloss-right">
                <p className="text" style={{ color: color }}>
                  {" "}
                  {percentShow == 0 || isNaN(percentShow)
                    ? "0"
                    : percentShow.toFixed(2)}{" "}
                  %
                </p>
                <div className="trade-btn">
                  <a
                    href={
                      "/exchange/" +
                      value.first_coin_name +
                      "/" +
                      value.second_coin_name
                    }
                    className="btn btn_man"
                  >
                    Trade Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return html;
    }
  };

  render() {
    return (
      <div className="intro-main-outer" id="token" data-scroll-index="3">
        <div className="container">
          <div className="card-slider">
            <div class="slider multiple-items">
              {/* <Slider {...settings}> */}
              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div>
                <div className="card-outer">
                  <img
                    src="assets/img/card-img2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              {/* </Slider> */}
            </div>
          </div>
          <div className="intro-outer">
            <div className="row">
              <div className="col-md-3 col-12">
                <div className="intro-left">
                  <img
                    src="assets/img/logo-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-9 col-12">
                <div className="intro-right">
                  <h2>Introducing VenCrypto Exchange</h2>
                  <p>
                    VenCrypto Exchange is India's one of the most crypto
                    exchange platforms in India where you can buy or sell
                    Bitcoin, Ethereum, Tron, Ripple and various other
                    cryptocurrencies. Our crypto trading platform never leaves a
                    single stone unturned to make it the most secure crypto
                    exchange where you can get an experience of hassle-free
                    trading with an efficient crypto wallet. VenCrypto can
                    handle thousands of transactions within a few seconds during
                    surging demand. VenCrypto offers a user-friendly interface
                    with its simple & efficient design. We have used the most
                    advanced technology at our crypto exchange platform by
                    prioritizing the needs of our customers. So, whether you are
                    a novice or a pro-crypto trader, just give it a try to use
                    our crypto exchange platform to get a unique experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="white_box5">
            <h4 className="p-3 mb-0">
              Popular Crypto Currencies and Their Prices
            </h4>
            <div className="table-responsive">
              <div className="scrool_wallet_box">
                <table className=" table table-striped mb-0 ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Pair</th>
                      <th>Last Price ($)</th>
                      <th>24h Change ($)</th>
                    </tr>
                  </thead>

                  {this.AddressListHtml()}
                </table>
              </div>
            </div>
          </div>
          <div className="winloss-outer">
            <div className="section-heading">
              <h5 className="mb-0">Top Gainer</h5>
              <p className="m-0">Coins that have gained the must in 24 hours</p>
            </div>
            <div className="row">{this.GainerHtml()}</div>
          </div>

          <div className="winloss-outer">
            <div className="section-heading">
              <h5 className="mb-0">Top Losers</h5>
              <p className="m-0">
                Coins that have corrected the must in 24 hours
              </p>
            </div>
            <div className="row">{this.LoserHtml()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
