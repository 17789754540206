import React, { useEffect, useState } from "react";
import LoginHeader from "./Common/LoginHeader";
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";
import { Component } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "./Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { ImageValidation } from "./Validation/ImageValidation";
import LeftTab from "./Common/LeftTab";
import { ProductName } from "./Common/ProductName";
const baseUrl = myConstList.baseUrl;

const HelpFunction = () => {
  const verifyidpic = "./assets/img/support.jpg";
  const [isInputShow, setIsInputShow] = useState(false);
  const [userUploadImage, setUserUploadImage] = useState(
    "/assets/img/support.jpg"
  );
  const [btnnDisable, setBtnnDisable] = useState(true);
  const [issueDesc, setIssueDesc] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [respStatus, setRespStatus] = useState("");
  const [message, setMessage] = useState("");
  const [issue, setIssue] = useState("");
  const [issueType, setIssueType] = useState("");
  const [issueImage, setIssueImage] = useState(false);
  const [IssueTypeDesc, setIssueTypeDesc] = useState("");
  const [IssueError, setIssueError] = useState("");

  const meta = {
    title: `Support | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  useEffect(() => {
    TicketList();
  }, []);

  const TicketList = (event) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/helplist-by-user", {}, config)
      .then((resp) => {
        var respNew = resp.data;
        if (respNew.status === true) {
          setTabledata(respNew.data);
          setRespStatus(respNew.status);
        }
      });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const helpsbmt = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("userId", localStorage.getItem("token"));

    if (issueType === "Others") {
      formData.append("issue", issue);
    } else {
      formData.append("issue", issueType);
    }
    formData.append("issueType", issueDesc);
    formData.append("issueImage", issueImage);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data",
      },
    };
    // Validations //
    if (issueType === "Select Subject") {
      setIssueError("This field is required");

      return false;
    }
    if (issueType === "Others") {
      if (issue === undefined || !issue) {
        setIssueError("This field is required");
        return false;
      }

      const spaceError = checkSpace("others subject", issue);
      if (spaceError.length > 0) {
        setIssueError(spaceError);
        return false;
      }
    }
    if (!issueDesc) {
      setIssueTypeDesc("This field is required");
      return false;
    }

    const spaceError = checkSpace("description message", issueDesc);
    if (spaceError.length > 0) {
      setIssueTypeDesc(spaceError);
      return false;
    }

    // V end //
    setBtnnDisable(true);

    setTimeout(() => setBtnnDisable(false), 2000);
    axios.post(baseUrl + "/frontapi/help", formData, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        TicketList();
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
      setTimeout(() => window.location.reload(), 2000);

      setBtnnDisable(true);
      setMessage(resp.message);
      //   setRespStatus(resp.status);
    });
  };

  const onChangeImage = (e) => {
    e.preventDefault();
    setIssueImage("");
    setUserUploadImage(false);

    var issueImage = e.target.files[0];
    setIssueImage(e.target.files[0]);
    setUserUploadImage(URL.createObjectURL(issueImage));

    let error = ImageValidation("Help", issueImage);

    if (error) {
      setMessage(error);
      //   respStatus(false);
      return false;
    }
    setMessage("");
    // respStatus(true);
  };

  const handleChange = (event) => {
    let { value } = event.target;
    setIssueType(value);

    if (value === "Select Subject" || !value) {
      setIssueError("This field is required");
      setIsInputShow(false);
      setBtnnDisable(true);
      return false;
    }

    if (value == "Others") {
      setIssueError("");
      setIsInputShow(true);
      setBtnnDisable(false);
    } else {
      setIssueError("");
      setIsInputShow(false);
      setBtnnDisable(false);
    }
  };

  const handleChangeInput = (e) => {
    let { name, value } = e.target;

    if (name == "issue") {
      setIssue(value);

      var IssueError = "";
      if (!value) {
        IssueError = "This field is required";
        setIssueError(IssueError);
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        IssueError = "Please enter a valid subject field";
        setIssueError(IssueError);
        return false;
      }
      if (value.length > 50) {
        IssueError = "Max character limit is 50";
        setIssueError(IssueError);
        return false;
      }
      setIssueError(IssueError);
    }

    if (name === "issueDesc") {
      setIssueDesc(value);
      if (!value) {
        setIssueTypeDesc("This field is required");
      } else {
        setIssueTypeDesc("");
      }
    }
  };

  const showTableHtml = () => {
    if (respStatus === true) {
      const html = [];
      tabledata.map((value, i) => {
        var U_ID = value.id;
        var encodeMe = btoa(U_ID);

        const UserLink1 = "/Chatting/" + encodeMe + "/" + value.seenStatus;

        html.push(
          <tr>
            <td>{value.id}</td>
            <td>{value.issue}</td>
            <td>
              {value.status == 0 && "Pending"}
              {value.status == 1 && "Resolved"}
            </td>
            <td>{Moment(value.createAt).format("lll")}</td>
            {value.updateAt ? (
              <td>{Moment(value.updateAt).format("lll")}</td>
            ) : (
              <td></td>
            )}
            <td
              className={
                value.status == 0 && value.seenStatus == "1"
                  ? "position-relative chat-count"
                  : ""
              }
            >
              <Link className="btn btn_man" to={UserLink1}>
                {" "}
                {value.status == 0 ? "Chat" : "View"}
              </Link>
              <span className="position-absolute">
                {value.seenStatus == "1" ? "*" : ""}
              </span>
            </td>
          </tr>
        );
      });

      return html;
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container container2">
              <LeftTab />
              <h4 className="mb-3">Support</h4>
              <div className="row">
                <div className="col-md-6">
                  <div className="white_box h_100">
                    <div className=" ">
                      <div className="form-group">
                        <label className="fw-500">Subject</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          value={issueType}
                          name="issueType"
                          id="issueType"
                        >
                          <option>Select Subject</option>
                          <option value="Exchange">Exchange</option>
                          <option value="Login">Login</option>
                          <option value="Signup">Signup</option>
                          <option value="Kyc">KYC</option>
                          <option value="Account">Account</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="form-group">
                        {isInputShow == true && (
                          <>
                            <label className="fw-500">Other Subject</label>
                            <input
                              className="form-control"
                              name="issue"
                              id="issue"
                              placeholder="Enter Subject"
                              onChange={handleChangeInput}
                              value={issue}
                            />
                          </>
                        )}
                        <span style={{ color: "red" }}>{IssueError}</span>
                      </div>

                      <div className="form-group">
                        <label className="fw-500"> Description </label>

                        <textarea
                          name="issueDesc"
                          value={issueDesc}
                          onChange={handleChangeInput}
                          className="form-control form_control_textarea"
                          placeholder="Enter Issue Type"
                          id="issueDesc"
                        />
                      </div>
                      <span style={{ color: "red" }}>{IssueTypeDesc}</span>

                      <div className="form-group">
                        <div className=" col-md-6">
                          <label className="fw-500">Attachment</label>

                          <div className="uploader">
                            <input
                              className="profile_input"
                              type="file"
                              name="issueImage"
                              id="file-upload"
                              onChange={onChangeImage}
                            />
                            <label for="file-upload" id="file-drag">
                              <img
                                src={
                                  userUploadImage !== false
                                    ? userUploadImage
                                    : verifyidpic
                                }
                                className="img-fluid22 support-img"
                                alt="Identity"
                              />
                              <div id="start">
                                <span className="fasi"></span>
                                <div>Upload image</div>
                                <div id="notimage" className="hidden">
                                  Please select an image
                                </div>
                              </div>
                              <div id="response" className="hidden">
                                <div id="messages">
                                  <span style={{ color: "red" }}>
                                    {message}
                                  </span>
                                </div>
                                <progress
                                  className="progress"
                                  id="file-progress"
                                  value="0"
                                >
                                  <span>0</span>%
                                </progress>
                              </div>
                            </label>

                            {/* <div id="messages">
                              <span style={{ color: "red" }}>{message}</span>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          onClick={helpsbmt}
                          type="submit"
                          value="Submit"
                          className="btn w100px btn_man "
                          disabled={btnnDisable}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="white_box h_100 ticket-history">
                    <h6>Ticket History</h6>
                    <div className="table-responsive ticket-history-outer">
                      <div className="scrool_wallet_box">
                        <table className="table table-striped mb-0 ">
                          <thead>
                            <tr>
                              <th>Ticket Id</th>
                              <th>Subject</th>
                              <th>Status</th>
                              <th>Created At</th>
                              <th>Last Updated</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{showTableHtml()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ToastContainer limit={1} />
      </div>
    </DocumentMeta>
  );
};

export default HelpFunction;
