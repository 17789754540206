import React from "react";

export const EmailValidation = (name, value) => {
  let emailAddressError = "";
  value = value.toLowerCase();
  if (!value) {
    emailAddressError = "Email address is required";
    return emailAddressError;
  }
  var Email1 = value.match(
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/
  );

  // new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
  //     value
  // );

  if (!Email1) {
    emailAddressError = "Please enter a valid email address";
    return emailAddressError;
  }
  // if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
  //     emailAddressError = "Please enter a valid email address";
  //     return emailAddressError
  // }
//   if (value.replace(/\s+/g, "").length == 0) {
//     emailAddressError = "Please enter a valid email address";
//     return emailAddressError;
//   }
  return emailAddressError;
};
