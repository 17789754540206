import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import DocumentMeta from "react-document-meta";
import Moment from "moment";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class FundRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,

      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: "",
      showData: 1,
      totalBalance: 0,
      defaultValue: "",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    this.trasactionList();
    this.WalletAllCoinBalance();
  }
  trasactionList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/coinFundRequest", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          this.setState({
            allData: resp.data,
            trasactionList: resp.data.main_wallet,
            cryptoIdWallet: resp.data.coin_id,
            TransactionsStatus: resp.status,
            defaultValue: resp.data[0].walletSum[0].walletAmount,
            defaultWalletTable: resp.data[0].wallet, //,spotList: resp.data.Spot_wallet,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };

  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };

  showTableHtml = (value) => {
    if (this.state.TransactionsStatus === true) {
      const html = [];
      const _this = this;
      let msg = [];
      let TransactionType = "";
      let status = "",
        renderhtml = [];
      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{e.coin_amount}</td>
              <td>{e.withdrawal_fees}</td>
              <td className="rewidth_t">{e.tx_id}</td>
              <td>{e.tx_type}</td>
              <td>{e.tx_hash}</td>
              {/* <td>{e.name}</td> */}
              <td>{Moment.utc(e.created).local().format("lll")}</td>
              <td>
                {e.WithdrawlRequest === "2" ? (
                  <span>
                    Rejected{" "}
                    <Popup
                      trigger={<i className="fa fa-info-circle"></i>}
                      position="top center"
                    >
                      <div>{e.reject}</div>
                    </Popup>
                  </span>
                ) : e.WithdrawlRequest === "1" ? (
                  <span> Completed </span>
                ) : (
                  "Pending"
                )}
              </td>
            </tr>
          );
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  renderCoinList = (allData) => {
    let { mainWallet } = this.state;
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li key={index} onClick={() => this.OpenMain(item)}>
          <span className={active ? "active" : <></>}>{item.name}</span>
        </li>
      );
    });
  };

  render() {
    const meta = {
      title: `Withdrawal Transactions | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { showData, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  {/* <LeftTab /> */}
                  <div className="white_box">
                    <ul className="nav nav-tabs2 mb-3 ">
                      <li className="nav-item">
                        <Link to="/mainWalletTransactions"> Main Wallet </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/spotHistory"> Spot </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinDeposite"> Deposit Coin </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinFundRequest" className="active">
                          {" "}
                          Withdrawal{" "}
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/deposit-inr-request">
                          {" "}
                          Deposit INR Requests{" "}
                        </Link>
                      </li> */}
                    </ul>
                    {/* <div className="buyselll_box">
                        <ul className="nav buy_sell_tab mb-2 ">
                          <li className="nav-item ">
                            <Link to="/coinDeposite"> Deposit </Link>
                          </li>
                          <li className="nav-item ">
                            <Link to="/coinFundRequest" className="active">
                              {" "}
                              Withdrawal{" "}
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                    <ul className="nav nav-tabs offer_beu spot">
                      {this.renderCoinList(allData || [])}
                      {/* <li onClick={() => this.OpenSpot("Spot")}><Link className={this.state.spotTab}> Spot List </Link></li> */}
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className="top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Amount</th>
                                      {/* <th>Balance</th> */}
                                      <th>Withdrawal Fee</th>
                                      <th>Withdrawal Token ID</th>
                                      <th>Transaction Type</th>
                                      <th>Transaction Hash</th>
                                      {/* <th>Coin Type</th> */}
                                      <th>Date & Time</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  {this.showTableHtml(
                                    this.state.showData || []
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>
          </div> */}
          </div>
          <Footer />
        </div>
      </DocumentMeta>
    );
  }
}

export default FundRequest;
