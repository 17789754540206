import React from "react";
var password = "";
var confirmPassword = "";

export const PasswordValidation = (data, value) => {
  let passwordError = "";
  if (data == "Password" || data == "password") {
    password = value;
    if (!value) {
      passwordError = "This field is required";
      return passwordError;
    }
    var regex =
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

    var passw =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
    if (!value.match(passw)) {
      passwordError =
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character";
      return passwordError;
    }

    if (confirmPassword && confirmPassword.length !== 0) {
      if (password !== confirmPassword) {
        passwordError = "mismatched";
        return passwordError;
      } else {
        return passwordError;
      }
    }
    return passwordError;
  }

  if (data == "CPassword" || data == "cpassword") {
    confirmPassword = value;
    if (!value) {
      passwordError = "This field is required";
      return passwordError;
    }
    if (password !== confirmPassword) {
      passwordError = "Confirm password does not match";
      return passwordError;
    }
  }
};
