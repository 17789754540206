import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import DocumentMeta from "react-document-meta";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import {
  cryptoCoinListById,
  deleteWhiteList,
  //   userData,
  whiteListAdd,
  whiteListData,
} from "../services/WhiteListServices";

const meta = {
  title: "Whitelist | VenCrypto  Exchange ",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin",
  canonical: "https://vencrypto.com",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const WhitelistFunction = () => {
  const [BtnDisable, setBtnDisable] = useState(false);
  const [Cryptodata, setCryptodata] = useState([]);
  const [whitelistAddress, setWhitelistAddress] = useState("");
  const [coinType, setCoinType] = useState("");
  const [name, setName] = useState("");
  const [coinTypeError, setCoinTypeError] = useState("");
  const [walletError, setWalletError] = useState("");
  const [nameError, setNameError] = useState("");
  const [CryptoStatus, setCryptoStatus] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [TableStatus, setTableStatus] = useState(false);

  useEffect(() => {
    ShowListData();
    CryptoCoinData();
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name == "whitelistAddress") {
      setWhitelistAddress(value);
      if (!value) {
        setWalletError("Wallet address is required");

        return false;
      }
      if (value.includes(" ")) {
        setWalletError("Please enter a valid wallet address");
        return false;
      }

      if (value.length < 26 || value.length > 64) {
        setWalletError("Please enter a valid wallet address");
        return false;
      }
      setWalletError("");
    }

    if (name == "coinType") {
      setCoinType(value);
      if (!value || value === "Select") {
        setCoinTypeError("Please select cryptocoin name");
        return false;
      }
      setCoinTypeError("");
    }

    if (name == "name") {
      setName(value);
      if (!value) {
        setNameError("Name field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        setNameError("Please enter a valid name");
        return false;
      }
      if (value.length > 30) {
        setNameError("Name must not exceed 30 characters");
        return false;
      }
      setNameError("");
    }
  };

  const CryptoCoinData = async () => {
    let result = await cryptoCoinListById();
    var resp = result.data;
    setCryptodata(resp);
    setCryptoStatus(result.status);
  };

  const CryptoCoinDataHtml = () => {
    if (CryptoStatus === true) {
      const html = [];
      html.push(<option value="">Select</option>);
      Cryptodata.map(function (value, i) {
        html.push(<option value={value.id}>{value.short_name}</option>);
      });

      return (
        <select
          className="form-control"
          name="coinType"
          value={coinType}
          onChange={handleChange}
        >
          {html}
        </select>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (coinType == undefined || coinType == "true" || !coinType) {
      setCoinTypeError("Please select cryptocoin name");

      return false;
    }
    if (!name) {
      setNameError("Name is required");

      return false;
    }

    let hasLeadingSpace = /^\s/.test(name);
    let hasTrailingSpace = /\s$/.test(name);

    if (hasLeadingSpace) {
      setNameError("Please enter a valid name");

      return false;
    }

    if (hasTrailingSpace) {
      setNameError("Please enter a valid name");
      return false;
    }

    if (name.length > 30 || name.length <= 1) {
      setNameError("Name must not exceed 30 characters");
      setBtnDisable(false);
      return false;
    }

    if (!whitelistAddress) {
      setWalletError("Wallet address is required");

      return false;
    }

    if (whitelistAddress.length < 26 || whitelistAddress.length > 64) {
      setWalletError("Please enter a valid wallet address");
      setBtnDisable(false);
      return false;
    }

    if (coinTypeError || nameError || walletError) {
      return false;
    }
    setBtnDisable(true);

    var walletAddress = {
      whitelistAddress: whitelistAddress,
      coinType: coinType,
      name: name,
    };
    const token = localStorage.getItem("token");
    let result = await whiteListAdd(walletAddress, token);
    var resp = result.data;

    setBtnDisable(false);
    setName("");
    setWhitelistAddress("");
    setCoinType("");

    if (resp.status === true) {
      setName("");
      setWhitelistAddress("");

      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
    ShowListData();
  };

  const ShowListData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var walletAddress = {
      userId: localStorage.getItem("id"),
    };
    let result = await whiteListData(walletAddress);
    var resp1 = result.data;

    if (resp1.status === true) {
      setTableData(resp1.data);
      setTableStatus(resp1.status);
    } else {
      setTableData([]);
    }

    // return false;
    // axios
    //   .post(baseUrl + "/frontapi/whitelistData", walletAddress, config)
    //   .then((resp) => {
    //     var resp1 = resp.data;
    //     this.setState({ msg: resp.message });

    //     if (resp1.status === true) {
    //       this.setState({ TableData: resp1.data, TableStatus: resp1.status });
    //     } else {
    //       this.setState({ TableData: [] });
    //     }
    //   });
  };

  const whitelistDataHtml = () => {
    if (TableStatus === true) {
      const html = [];
      TableData.map(function (value, i) {
        html.push(
          <div className="whitelist-data">
            <div className="white-top d-flex justify-content-between">
              <div className="white-right-inner">
                <h5>{value.short_name}</h5>
              </div>
              <div className="white-left-inner">
                <h5>{value.name}</h5>
              </div>
            </div>
            <div className="white-bottom d-flex justify-content-between">
              <div className="white-left-inner">
                <h5>{value.whitelistAddress}</h5>
              </div>
              <div className="white-right-inner">
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger"
                  onClick={() => DeleteWalletAddress(value.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        );
      });

      return html;
    }
  };

  const DeleteWalletAddress = (getAddressId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete_wallet_address(getAddressId),
        },
        {
          label: "No",
          //   onClick: () => checkCall,
          onClick: () => false,
        },
      ],
    });
  };

  const Delete_wallet_address = async (api_id) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    let Payment_delete_Data = {
      address_id: api_id,
      userId: localStorage.getItem("id"),
    };

    let result = await deleteWhiteList(Payment_delete_Data);
    if (result.data.status == true) {
      toast.dismiss();
      toast.success(result.data.message);
      ShowListData();
    }
  };

  return (
    <div className="">
      <DocumentMeta {...meta}>
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container container2">
              <div className="in_page">
                <LeftTab />
                <h5>Whitelist</h5>
                <div className="wishlist-main-outer">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="wishlist-left">
                        {" "}
                        <p>
                          Whitelist is an additional security feature designed
                          to protect your funds. When you enable Whitelist, your
                          Account will be able to withdraw only to whitelisted
                          withdrawal wallet addresses.
                        </p>
                        <div className="wishlist-form">
                          <div className="form-group">
                            <lable>Cryptocoin Name</lable>
                            {CryptoCoinDataHtml()}
                            <span className="errMsg" style={{ color: "red" }}>
                              {coinTypeError}
                            </span>
                          </div>

                          <div className="form-group">
                            <lable>Name</lable>
                            <input
                              className="form-control"
                              placeholder="Enter Name"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              value={name}
                            />
                            <span className="errMsg" style={{ color: "red" }}>
                              {nameError}
                            </span>
                          </div>
                          <div className="form-group">
                            <lable>Wallet Address</lable>
                            <input
                              className="form-control"
                              placeholder="Enter Wallet Address"
                              type="text"
                              minLength="10"
                              maxLength="100"
                              name="whitelistAddress"
                              onChange={handleChange}
                              value={whitelistAddress}
                            />
                            <span className="errMsg" style={{ color: "red" }}>
                              {walletError}
                            </span>
                          </div>
                          <div>
                            <Button
                              className="btn btn_man"
                              onClick={handleSubmit}
                              disabled={BtnDisable}
                            >
                              Add{" "}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="whitelist_scrool whilist-right-main">
                        {whitelistDataHtml()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </div>
  );
};

export default WhitelistFunction;
