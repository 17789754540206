import React from "react";
import { Component } from "react";
import LoginHeader from "../Common/LoginHeader";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";

const baseUrl = myConstList.baseUrl;

export class account extends Component {
  constructor(props) {
    super(props);
    var maxNumber = 999999;
    var rand = Math.floor(Math.random() * maxNumber + 1);
    this.state = {
      copySuccess: false,
      isDepositModelOpen: false,
      isWithdrawalModelOpen: false,
      disabledInrDeposit: false,
      Withdraw_address: "",
      isTransferModelOpen: false,
      isINRDepositModelOpen: false,
      isPaymentModelOpen: "none",
      depositeInrBtn: "block",
      disableTransferButton: false,
      disabledInrWithdrawal: false,
      disabledCoinWithdrawal: false,
      walletBalanceAmount: 0,
      balanceOfAmount: 0,
      balanceOfAmountSpot: 0,
      spotBalance: 0,
      confirmInrBtn: "none",
      whitelist_Data: [],
      random: rand,
      getAmount: "",
      amount: 0,
      usdtPortfolio: 0,
      inrPortfolio: 0,
      withdraw_amount: "",
      return_url: "" + localStorage.getItem("id") + "-web",
      getCoinNameINr: false,
      getCoinName_withdrawl: false,
      isGoing: false,
      isKycModalOpen: false,
      kycStatus: "",
      adminbankdetails: [],
      estimatedCoin: "INR",
    };
    this.GetUserData();
    this.portfolioData();
    this.adminbankinfo();
  }
  closeOpenINRDepositModal = () => {
    this.setState({
      isINRDepositModelOpen: false,
      confirmInrBtn: "none",
      depositeInrBtn: "block",
      inr_amount: "",
      transactionIdError: "",
      transactionImageError: "",
      inrDepositeError: "",
    });
  };

  adminbankinfo = () => {
    axios.get(baseUrl + "/frontapi/adminbankinfo").then((resp) => {
      var resp = resp.data;
      this.setState({ adminbankdetails: resp.data[0] });
    });
  };

  portfolioData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/walletTotalBalance", {}, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({
          usdtPortfolio: resp.data[0].USDT,
          inrPortfolio: resp.data[0].INR,
        });
      });
  };

  closeKycModal = () => {
    this.setState({ isKycModalOpen: false });
  };
  closeWhitelistModal = () => {
    this.setState({ isWhitelistModalOpen: false });
  };

  inr_amountWithdrawl = (event) => {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    }
    if (W_to <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
    } else {
      this.setState({ amountErr: "" });
    }

    if (W_to.length > 7) {
      toast.dismiss();
      toast.error("Enter Reasonble Amount");
    }
  };

  inrWithdrawalSubmit = (event) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    this.setState({ disabledInrWithdrawal: true });
    setTimeout(() => this.setState({ disabledInrWithdrawal: false }), 1000);
    if (!this.state.inr_amountWithdrawl) {
      toast.dismiss();
      this.setState({ amountErr: "Enter amount" });
      return false;
    }
    if (this.state.inr_amountWithdrawl <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
      return false;
    }

    if (this.state.inr_amountWithdrawl.length > 7) {
      toast.dismiss();
      toast.error("Enter Reasonble Amount");
      return false;
    }
    const dataSubmit = {
      amount: this.state.inr_amountWithdrawl,
      withdrawFees: this.state.fees,
      type: this.state.type,
    };
    if (this.state.inr_amountWithdrawl < this.state.min) {
      toast.dismiss();
      toast.error(`You can't withdrawal less than ${this.state.min}`);
      return false;
    }
    if (this.state.inr_amountWithdrawl > this.state.max) {
      toast.dismiss();
      toast.error(`You can't withdrawal more than ${this.state.max}`);
      return false;
    }

    axios
      .post(baseUrl + "/frontapi/inr_Withdrawal", dataSubmit, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({
          inr_amountWithdrawl: "",
          getAmount: "",
          percentValue: "",
          isWithdrawalModelOpen: false,
          getCoinName_withdrawl: false,
        });
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          this.WalletAllCoinBalance();
          this.setState({ isWithdrawalModelOpen: false });
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  OpenDepositModel = (getCoinName, show_wallet_address) => {
    if (this.state.kycStatus == "N") {
      toast.error("Please complete your kyc first");
      return false;
    }
    // if (this.state.kycStatus !== "Y") {
    //   this.setState({ isKycModalOpen: true });
    //   return false;
    // }
    // if (getCoinName === "INR") {
    //   this.setState({
    //     isINRDepositModelOpen: true,
    //     wallet_address: show_wallet_address,
    //   });
    // } else {
    this.setState({
      isDepositModelOpen: true,
      wallet_address: show_wallet_address,
    });
    // }
  };

  closeOpenDepositModal = () => {
    this.setState({ isDepositModelOpen: false, copySuccess: "" });
  };

  OpenWithdrawalModel = (
    Currency_name,
    wallet_currency_amount,
    currency_id,
    fees,
    min,
    max,
    type
  ) => {
    // if (currency_id == 36) {
    //   this.state.kycStatus === "N" ||
    //   this.state.kycStatus === undefined ||
    //   this.state.kycStatus === null
    //     ? this.setState({ isWithdrawalModelOpen: false, isKycModalOpen: true })
    //     : this.setState({
    //         isDepositModelOpen: false,
    //         isDDepositModelOpen: true,
    //         BtnDisable3: true,
    //       });
    //   this.setState({ getCoinName_withdrawl: true });
    // }
    // this.state.kycStatus === "N" ||
    // this.state.kycStatus === undefined ||
    // this.state.kycStatus === null
    //   ? this.setState({ isWithdrawalModelOpen: false, isKycModalOpen: true })

    if (this.state.kycStatus == "N") {
      toast.error("Please complete your kyc first");
      return false;
    }
    this.setState({
      isDepositModelOpen: false,
      isWithdrawalModelOpen: true,
      BtnDisable3: true,
      whitelist_Data: [],
    });

    this.setState({
      Currency_name: Currency_name,
      wallet_currency_amount: wallet_currency_amount,
      currency_id: currency_id,
      fees: fees,
      min: min,
      max: max,
      type: type,
    });
    let LoginData = {
      coin_id: currency_id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/whitelistDataById", LoginData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          this.setState({
            whitelist_Data: resp.data,
            Withdraw_address: resp.data[0].whitelistAddress,
            whiteListAddressStatus: resp.status,
          });
        } else {
          this.setState({
            whitelist_Data: [],
            Withdraw_address: resp.data[0].whitelistAddress,
            whiteListAddressStatus: resp.status,
          });
        }
      });
  };

  autoAmount = (e) => {
    if (this.state.balanceOfAmount) {
      this.setState({ ["amount"]: this.state.balanceOfAmount, errMsg: "" });
    } else {
      this.setState({ ["amount"]: "", errMsg: "" });
    }
  };

  closeWithdrawalModal = () => {
    this.setState({
      isWithdrawalModelOpen: false,
      getCoinName_withdrawl: false,
      percentValue: "",
      inr_amountWithdrawl: "",
      getAmount: "",
      withdraw_amount: "",
      Withdraw_address: "",
      amountErr: "",
      walletErr: "",
    });
  };
  OpenTransferModel = (
    transferCoinName,
    transferCoinId,
    spotBalance,
    walletBalance
  ) => {
    // this.state.kycStatus === "N" ||
    // this.state.kycStatus === undefined ||
    // this.state.kycStatus === null
    //   ? this.setState({ isTransferModelOpen: false, isKycModalOpen: true })
    this.setState({
      isTransferModelOpen: true,
      transferCoinName: transferCoinName,
      transferCoinId: transferCoinId,
      walletBalanceAmount: walletBalance,
      spotBalance: spotBalance,
    });
  };

  closeTransferModal = () => {
    this.setState({
      isTransferModelOpen: false,
      balanceOfAmountSpot: 0,
      balanceOfAmount: 0,
      fromWallet: "",
      toWallet: "",
      amount: "",
    });
  };

  componentDidMount = () => {
    this.WalletAllCoinBalance();
  };

  exchangeValue = () => {
    this.setState({
      fromWallet: this.state.toWallet,
      toWallet: this.state.fromWallet,
      balanceOfAmount: this.state.balanceOfAmountSpot,
      balanceOfAmountSpot: this.state.balanceOfAmount,
      coinAmount: "",
      CoinAmount1: "",
    });
  };

  copyCodeToClipboard = () => {
    const el = this.Copy;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess: true });
  };

  GetCryptoData = () => {
    axios.get(baseUrl + "/frontapi/cryptocoin_list", {}).then((resp) => {
      var resp = resp.data;
      this.setState({
        Status1: resp.status,
        tabledata: resp.data,
        name: resp.name,
        short_name: resp.short_name,
        wallet: resp.wallet,
        spot: resp.spot,
        reserved: resp.reserved,
      });
    });
  };
  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;

        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };
  GetUserData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      this.setState({
        kycStatus: resp.data[0].kycVerification_status,
        Bonous_status: resp.data[0].Bonous_status,
      });
    });
  };

  BonousAmount = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    if (this.state.kycStatus === undefined) {
      toast.error("Please complete your kyc");
    }
    if (this.state.kycStatus === null) {
      toast.error("Please complete your kyc");
    }
    axios.post(baseUrl + "/frontapi/BonousAmount", {}, config).then((resp) => {
      var resp = resp.data;
    });
  };

  withdrawSubmit = (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const dataSubmit = {
      Wamount: this.state.withdraw_amount,
      coinList: this.state.currency_id,
      walletAddress: this.state.Withdraw_address,
      withdrawFees: this.state.fees,
      type: this.state.type,
    };
    if (!this.state.withdraw_amount) {
      toast.dismiss();
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    }
    if (!this.state.Withdraw_address) {
      this.setState({ walletErr: "Wallet address is required" });
      return false;
    } else {
      this.setState({ walletErr: "" });
    }

    if (this.state.withdraw_amount <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
      return false;
    }

    if (this.state.wallet_currency_amount < this.state.withdraw_amount) {
      toast.dismiss();
      toast.error("Insufficient Balance!");
      return false;
    }
    if (this.state.withdraw_amount < this.state.min) {
      toast.dismiss();
      toast.error(`You can't withdrawal less than ${this.state.min}`);
      return false;
    }

    if (this.state.withdraw_amount > this.state.max) {
      toast.dismiss();
      toast.error(`You can't withdrawal more than ${this.state.max}`);
      return false;
    }

    this.setState({ disabledCoinWithdrawal: true });
    setTimeout(() => this.setState({ disabledCoinWithdrawal: false }), 1000);

    axios
      .post(baseUrl + "/frontapi/Wallet_Balance", dataSubmit, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({
          withdraw_amount: "",
          Withdraw_address: "",
          percentValue: "",
          getAmount: "",
          isWithdrawalModelOpen: false,
        });
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);

          if (resp.message == "Technical error,please try again") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          this.WalletAllCoinBalance();
          this.portfolioData();
          this.setState({ percentValue: "" });
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  calculateCheckSum = (event) => {
    event.preventDefault();
    this.setState({ disabledInrDeposit: true });
    setTimeout(() => this.setState({ disabledInrDeposit: false }), 1000);
    if (
      this.state.transactionImageError ||
      this.state.inrDepositeError ||
      this.state.transactionIdError
    ) {
      return false;
    }
    if (
      !this.state.IdentityPic3 &&
      !this.state.trNumber &&
      !this.state.inr_amount
    ) {
      this.setState({
        transactionImageError: "Transaction image is required",
        inrDepositeError: "Deposit amount field is required",
        transactionIdError: "Transaction id is required",
      });
      return false;
    }
    if (!this.state.inr_amount) {
      this.setState({
        inrDepositeError: "Deposit amount field is required",
      });
      return false;
    }
    if (!this.state.IdentityPic3) {
      this.setState({
        transactionImageError: "Transaction image is required",
      });
      return false;
    }
    if (!this.state.trNumber) {
      this.setState({
        transactionIdError: "Transaction id is required",
      });
      return false;
    }

    if (this.state.inr_amount <= 0) {
      this.setState({
        inrDepositeError: "Please enter valid deposit amount",
      });
      return false;
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var formData = new FormData();
    formData.append("tx_image", this.state.IdentityPic3);
    formData.append("utrId", this.state.trNumber);
    formData.append("amount", this.state.inr_amount);

    axios
      .post(baseUrl + "/frontapi/inr_request", formData, config)
      .then((resp) => {
        var resp = resp.data;
        if (resp.status == true) {
          toast.success(resp.message);
          this.setState({
            trNumber: "",
            inr_amount: "",
            IdentityPic3: "",
            isINRDepositModelOpen: false,
          });
        }
        if (resp.status == false) {
          toast.error(resp.message);
          this.setState({
            trNumber: "",
            inr_amount: "",
            IdentityPic3: "",
            isINRDepositModelOpen: false,
          });
        }
      });
  };

  whiteListAddressbyID = (currency_id) => {
    let LoginData = {
      // coin_id: this.state.currency_id,
      coin_id: currency_id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/whitelistDataById", LoginData, config)
      .then((resp) => {
        var resp = resp.data;
        if (!resp.status) {
          this.setState(
            { otpWithdrawalModel: false, isWhitelistModalOpen: true },
            () => {
              this.setState({
                isWithdrawalModelOpen:
                  this.state.isWhitelistModalOpen == true ? false : true,
                whitelist_Data: [],
              });
            }
          );
          return false;
        }
        this.setState({
          isWithdrawalModelOpen: true,
          whitelist_Data: resp.data,
          whiteListAddressStatus: resp.status,
        });
      });
  };

  redirect = () => {
    window.location = "/whitelist";
  };

  coinListHtml = () => {
    if (this.state.Status1 === true) {
      const html = [];
      this.state.tabledata.map(function (value, i) {
        html.push(<option value={value.id}>{value.short_name}</option>);
      });

      return (
        <select
          className="form-control"
          name="coinList"
          value={this.state.coinList}
          onChange={this.handleChange1}
          onBlur={this.onBlurCoinList}
        >
          {html}
        </select>
      );
    }
  };

  showTableHtml = () => {
    if (this.state.Status === true) {
      const html = [];
      const classThis = this;
      this.state.tableData1.map(function (value, i) {
        if (value.getValue.main[0].coin_amount === null) {
          var getID = value.coin_id;
          var walletAmount = 0;
        } else {
          walletAmount = value.getValue.main[0].coin_amount;
          getID = value.coin_id;
        }
        if (value.getValue.spot[0].coin_amount === null) {
          getID = value.coin_id;
          var spotAmount = 0;
        } else {
          spotAmount = value.getValue.spot[0].coin_amount;
          getID = value.coin_id;
        }
        var myLink = value.coin_tron;
        var coinName = value.name;
        if (value.fees == undefined || value.fees.length == 0) {
          var fees = 0;
          var min = 0;
          var type = "";
        } else {
          fees = value.fees[0].commission;
          var max = value.fees[0].max_withdrawal;
          min = value.fees[0].min_withdrawal;
          type = value.fees[0].type;
        }
        var getWalletAddress = value.getValue.WalletAddress;
        if (
          classThis.state.isGoing == true
          // &&
          // value.getValue.main[0].coin_amount !== null &&
          // value.getValue.main[0].coin_amount != 0 &&
          // value.getValue.spot[0].coin_amount !== null &&
          // value.getValue.spot[0].coin_amount != 0

          // value.name !== "BTC"
        ) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}

                <a target="_blank" rel="noreferrer" href={myLink}>
                  {value.name}
                </a>
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>

              <td>{walletAmount}</td>
              <td>{spotAmount}</td>

              <td>
                <div className="four_btn">
                  {/* {value.idDeposit == 1 ? (
                    <button
                      className="btn btn_man fs14 mr-3"
                      onClick={() =>
                        classThis.OpenDepositModel(coinName, getWalletAddress)
                      }
                    >
                      Deposit
                    </button>
                  ) : (
                    ""
                  )} */}

                  {
                    <button
                      className="btn btn_man fs14 mr-3"
                      disabled={value.idDeposit == 0 ? true : false}
                      onClick={() =>
                        classThis.OpenDepositModel(coinName, getWalletAddress)
                      }
                    >
                      Deposit
                    </button>
                  }

                  {!fees ? (
                    <span>
                      {/* {value.isWithdrawal == 1 ? (
                        <button
                          className="btn btn_man fs14 mr-3"
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      ) : (
                        ""
                      )} */}

                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  ) : (
                    <span>
                      {/* {value.isWithdrawal == 1 ? (
                        <button
                          className="btn btn_man fs14 mr-3"
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      ) : ( 
                        ""
                      )} */}

                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount
                      )
                    }
                  >
                    Transfer
                  </button>
                </div>
              </td>
            </tr>
          );
          return html;
        }
        if (
          (classThis.state.isGoing == false &&
            value.getValue.main[0].coin_amount !== null &&
            value.getValue.main[0].coin_amount != 0) ||
          (value.getValue.spot[0].coin_amount !== null &&
            value.getValue.spot[0].coin_amount != 0)
          // || value.name === "BTC"
        ) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}
                <a target="_blank" rel="noreferrer" href={myLink}>
                  {value.name}
                </a>
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>
              <td>{walletAmount}</td>
              <td>{spotAmount}</td>

              <td>
                <div className="four_btn">
                  {/* {value.idDeposit == 1 ? (
                    <button
                      className="btn btn_man fs14 mr-3"
                      onClick={() =>
                        classThis.OpenDepositModel(coinName, getWalletAddress)
                      }
                    >
                      Deposit
                    </button>
                  ) : (
                    ""
                  )} */}

                  {
                    <button
                      className="btn btn_man fs14 mr-3"
                      disabled={value.idDeposit == 0 ? true : false}
                      onClick={() =>
                        classThis.OpenDepositModel(coinName, getWalletAddress)
                      }
                    >
                      Deposit
                    </button>
                  }

                  {!fees ? (
                    <span>
                      {/* {value.isWithdrawal == 1 ? (
                        <button
                          className="btn btn_man fs14 mr-3"
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      ) : (
                        ""
                      )} */}

                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  ) : (
                    <span>
                      {/* {value.isWithdrawal == 1 ? (
                        <button
                          className="btn btn_man fs14 mr-3"
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      ) : (
                        ""
                      )} */}

                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            classThis.OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount
                      )
                    }
                  >
                    Transfer
                  </button>
                </div>
              </td>
            </tr>
          );
          return html;
        }
      });
      return <tbody className="main">{html}</tbody>;
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    if (name !== "amount") {
      this.setState({ [name]: value, errMsg: "" });
    }

    if (value > 0) {
      this.setState({ amountErr: "" });
    }

    if (name === "amount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      if (!newValue || newValue == 0) {
        this.setState({
          errMsg: "Please enter lock amount",
          amount: newValue,
        });
        return false;
      }

      this.setState({ amount: newValue, errMsg: "" });

      // var number = Number(value);
      // if (number === Math.floor(number)) {
      //   if (value.length < 9) {
      //     this.setState({ [name]: value, errMsg: "" });
      //   }
      // } else {
      //   if (value.toString().split(".")[1].length < 9) {
      //     this.setState({ [name]: value, errMsg: "" });
      //   }
      // }
    }

    if (name === "fromWallet") {
      if (event.target.value === "wallet") {
        this.setState({
          balanceOfAmount: this.state.walletBalanceAmount,
          balanceOfAmountSpot: this.state.spotBalance,
          toWallet: "transactions",
        });
      }
      if (event.target.value === "transactions") {
        this.setState({
          balanceOfAmount: this.state.spotBalance,
          balanceOfAmountSpot: this.state.walletBalanceAmount,
          toWallet: "wallet",
        });
      }
    }
    if (name === "toWallet") {
      if (event.target.value === "wallet") {
        this.setState({
          balanceOfAmountSpot: this.state.walletBalanceAmount,
          balanceOfAmount: this.state.spotBalance,
          fromWallet: "transactions",
        });
      }
      if (event.target.value === "transactions") {
        this.setState({
          balanceOfAmountSpot: this.state.spotBalance,
          balanceOfAmount: this.state.walletBalanceAmount,
          fromWallet: "wallet",
        });
      }
    }

    if (name === "withdraw_amount" || name === "inr_amountWithdrawl") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      if (newValue < 0) {
        var withdrawalError = "Please enter valid amount";
        this.setState({ amountErr: withdrawalError });
        return false;
      }

      this.setState({
        inr_amountWithdrawl: newValue,
        withdraw_amount: newValue,
        amountErr: "",
      });

      if (this.state.type === "flat") {
        var calculatedValue =
          parseFloat(newValue) - parseFloat(this.state.fees);
      }
      if (this.state.type === "percentage") {
        var percentValue =
          (parseFloat(newValue) * parseFloat(this.state.fees)) / 100;
        this.setState({ percentValue: percentValue });
        calculatedValue = parseFloat(newValue) - parseFloat(percentValue);
      }
      this.setState({ getAmount: calculatedValue });
    }
  };

  handleInrChange = (event) => {
    let { name, value } = event.target;

    if (name === "inr_amount") {
      if (!value) {
        this.setState({
          inrDepositeError: "Deposit amount field is required",
        });
      } else {
        this.setState({
          inrDepositeError: "",
        });
      }
    }
    if (name === "trNumber") {
      if (!value) {
        this.setState({
          transactionIdError: "Transaction id is required",
        });
      } else {
        this.setState({
          transactionIdError: "",
        });
      }
    }
    this.setState({
      [name]: value,
      errMsg: "",
      depositeInrBtn: "block",
      confirmInrBtn: "none",
    });
  };

  handleChange1 = (event) => {
    let { name, value } = event.target;
    if (!value) {
      this.setState({ walletErr: "Wallet address is required" });
    } else {
      this.setState({ walletErr: "" });
    }
    this.setState({ [name]: value, errMsg: "" });

    // let LoginData = {
    //   fromWallet: this.state.fromWallet,
    //   coinList: this.state.currency_id,
    // };

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // axios
    //   .post(baseUrl + "/frontapi/Transfer_Balance_coin_type", LoginData, config)

    //   .then((resp) => {
    //     var resp = resp.data;
    //     if (resp.data[0].coin_amount === null) {
    //       this.setState({ coinAmount: "Avaialable balance : 0" });
    //     } else {
    //       this.setState({
    //         coinAmount:
    //           "Avaialable balance : " +
    //           resp.data[0].coin_amount +
    //           " " +
    //           resp.data[0].short_name,
    //       });
    //     }

    //     this.setState({ coinName: resp.data[0].short_name });

    //     if (resp.status === false) {
    //       toast.dismiss();
    //       toast.error(resp.message);
    //     }
    //   });
  };

  WalletBalance = (event) => {
    let LoginData = {
      fromWallet: this.state.fromWallet,
      coinList: this.state.coinList,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/deduction_from_wallet", LoginData, config)
      .then((resp) => {
        var resp = resp.data;

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  // no
  DepositBalance = (event) => {
    let LoginData = {
      txid: this.state.wallet_address,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/depositBtc", LoginData, config)
      .then((resp) => {
        var resp = resp.data;

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };
  // no
  onBlurFrom(event) {
    var W_from = event.target.value;
    if (!W_from) {
      toast.dismiss();
      toast.error("Select Wallet Type");
    }
  }
  // no
  onBlurTo(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Select Second Wallet Type");
    }
  }

  // no
  kycModel = () => {
    return this.state.isKycModalOpen == true ? (
      <div
        id="account-deposit"
        className="collapse"
        aria-labelledby="faqhead1"
        data-parent="#faq"
      >
        <div className="card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
          sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
          you probably haven't heard of them accusamus labore sustainable VHS.
        </div>
      </div>
    ) : (
      ""
    );
  };

  onBlurCoinList(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Select Coin Type");
    }
  }

  onBlurWithdraw_amount = (event) => {
    var W_to = event.target.value;
    if (!W_to) {
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    } else {
      this.setState({ amountErr: "" });
    }
    if (W_to <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
    } else {
      this.setState({ amountErr: "" });
    }

    if (W_to.length > 7) {
      toast.dismiss();
      toast.error("Enter Reasonble Amount");
    }
  };

  handleCheckChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  onBlurWithdraw_address(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Enter Wallet Address");
    }
  }
  handleTransfer = (event) => {
    event.preventDefault();
    if (!this.state.fromWallet || !this.state.toWallet) {
      toast.error("Please select trasnfer wallet type");
      return false;
    }
    if (!this.state.amount) {
      toast.error("Amount field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      fromWallet: this.state.fromWallet,
      toWallet: this.state.toWallet,
      amount: this.state.amount,
      amountType: this.state.transferCoinId, //coin id
    };
    this.setState({ disableTransferButton: true });
    setTimeout(() => this.setState({ disableTransferButton: false }), 1000);

    axios
      .post(baseUrl + "/frontapi/walletTransfer", LoginData, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({
          amount: "",
          balanceOfAmountSpot: 0,
          balanceOfAmount: 0,
          fromWallet: "",
          toWallet: "",
        });
        if (resp.status === true) {
          this.WalletAllCoinBalance();
          this.portfolioData();
          toast.dismiss();
          toast.success(resp.message);
          this.setState({ isTransferModelOpen: false });
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.message);
          return false;
        }
      });
  };

  changeInrValue = (value) => {
    if (value) {
      this.setState({ inr_amount: value, inrDepositeError: "" });
    }
  };
  onChangeImage3 = (e) => {
    e.preventDefault();
    var transactionImageError = "";
    var IdentityPic3 = e.target.files[0];
    this.setState({ IdentityPic3: e.target.files[0] });
    this.setState({ userUploadImage3: URL.createObjectURL(IdentityPic3) });
    if (IdentityPic3 === false) {
      this.setState({ userUploadImage3: false });
      transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
      this.setState({
        transactionImageError,
      });
      return false;
    }
    if (!IdentityPic3.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ userUploadImage3: false });
      transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
      this.setState({
        transactionImageError,
      });
      return false;
    }
    this.setState({
      transactionImageError,
    });
  };

  handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  render() {
    const meta = {
      title: `Crypto Wallet Balance | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    const BtcAddressQr =
      "http://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=" +
      this.state.wallet_address;
    const BTCencoded = encodeURI(BtcAddressQr);
    let { getCoinName_withdrawl } = this.state;
    const zaak_url =
      "" +
      this.state.random +
      "&returnUrl=" +
      this.state.return_url +
      "&currency=INR&amount=" +
      this.state.amount +
      "&buyerEmail=" +
      this.state.email +
      "&platform=web";
    return (
      <DocumentMeta {...meta}>
        <div className="">
          <LoginHeader />

          {/* Deposit Modal Start */}
          <Modal
            show={this.state.isDepositModelOpen}
            onHide={this.closeOpenDepositModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Deposit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mt-0 note">
                Please ensure to select Bitcoin network at sender's wallet.
              </p>

              <div className="row align-items-center">
                <div className="col-sm-9">
                  <label>Your wallet address:</label>
                  <div className="link-address position-relative">
                    <div className="form-group mb-0">
                      <input
                        className="form-control fs12"
                        type="textarea"
                        ref={(textarea) => (this.Copy = textarea)}
                        readonly=""
                        name="wallet_address"
                        value={this.state.wallet_address}
                      />
                    </div>
                    <div className="copy-btn-outer ">
                      {this.state.copySuccess ? (
                        <div style={{ color: "green" }}>Address Copied!</div>
                      ) : null}
                      <button
                        type="button"
                        className="btn btn_man position-absolute copy_button"
                        onClick={() => this.copyCodeToClipboard()}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="tc">
                    <img src={BTCencoded} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="disclaimer-main border-top w-100">
                  <div className="disclaimer-outer p-3">
                    <h6>
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Disclaimer
                    </h6>
                    <ul>
                      <li>
                        Send only using the Bitcoin network. Using any other
                        network will result in loss of funds.
                      </li>
                      <li>
                        Deposit only BTC to this deposit address. Depositing any
                        other asset will result in a loss of funds.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Deposit Modal End */}

          {/* Withdrawal Modal Start */}
          <Modal
            show={this.state.isWithdrawalModelOpen}
            onHide={this.closeWithdrawalModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Withdrawal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="wallet-balance form-group row">
                <div className="col-12 mb-3">
                  <span className="mb-0 form-control d-flex">
                    {" "}
                    Wallet Balance:{" "}
                    <b className="ml-auto">
                      {this.state.wallet_currency_amount}{" "}
                      {this.state.Currency_name}{" "}
                    </b>
                  </span>
                </div>

                {/* {this.state.min} */}

                {/* {this.state.min && ( */}
                <div className="col-6">
                  <span className="form-control d-flex">
                    Min Withdrawal: <b className="ml-auto">{this.state.min}</b>
                  </span>
                </div>
                {/* )} */}

                <div className=" col-6">
                  <span className="form-control  d-flex">
                    Withdrawal Fees:{" "}
                    <b className="ml-auto">
                      {this.state.fees
                        ? Number.isInteger(this.state.fees)
                          ? this.state.fees
                          : parseFloat(this.state.fees).toFixed(4)
                        : 0}{" "}
                      {this.state.type == "flat" ? "(Flat)" : "(%)"}{" "}
                    </b>
                  </span>
                </div>
              </div>
              {getCoinName_withdrawl ? (
                <div className="form-group">
                  <label className="mb-0">Amount</label>
                  <div className="relative">
                    <input
                      type="number"
                      className="form-control"
                      name="inr_amountWithdrawl"
                      value={this.state.inr_amountWithdrawl}
                      onChange={this.handleChange}
                      onKeyDown={this.handleValidation}
                      onBlur={this.inr_amountWithdrawl}
                    />
                    <span className="absolute_btc">
                      {" "}
                      {this.state.Currency_name}
                    </span>
                    <div className="show">
                      <span style={{ color: "red" }}>
                        {this.state.amountErr && this.state.amountErr}
                      </span>
                      {this.state.inr_amountWithdrawl > 0 &&
                      this.state.getAmount
                        ? "You will get: " +
                          parseFloat(this.state.getAmount.toFixed(3))
                        : ""}
                    </div>
                    <div className="show">
                      {this.state.percentValue
                        ? "Fees Amount: " +
                          parseFloat(this.state.percentValue.toFixed(5))
                        : ""}
                    </div>
                  </div>
                  <div className="tc mt-3">
                    <button
                      className="btn btn_man fs14"
                      onClick={this.inrWithdrawalSubmit}
                      disabled={this.state.disabledInrWithdrawal}
                      value="Send Request"
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-group">
                    <label className="mb-0">Coin</label>
                    <div className="form-control ">
                      {this.state.Currency_name}
                    </div>{" "}
                  </div>
                  <div className="form-group ">
                    <label className="mb-0">Amount</label>

                    <div className="relative">
                      <input
                        type="number"
                        // value="0"
                        className="form-control"
                        name="withdraw_amount"
                        value={this.state.withdraw_amount}
                        onChange={this.handleChange}
                        onKeyDown={this.handleValidation}
                        onBlur={this.onBlurWithdraw_amount}
                        placeholder="Enter Amount"
                      />

                      <span className="absolute_btc">
                        {" "}
                        {this.state.Currency_name}
                      </span>
                      <div className="show">
                        <span style={{ color: "red" }}>
                          {this.state.amountErr && this.state.amountErr}
                        </span>
                        {this.state.withdraw_amount > 0 && this.state.getAmount
                          ? "You will get: " +
                            parseFloat(this.state.getAmount.toFixed(3))
                          : ""}
                      </div>
                      <div className="show">
                        {this.state.percentValue
                          ? "Fees Amount: " +
                            parseFloat(this.state.percentValue.toFixed(5))
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="form-group ">
                    <div className="relative">
                      <div className="form-group ">
                        <label className="mb-0">Address</label>

                        <input
                          type={"text"}
                          className="form-control"
                          name="Withdraw_address"
                          value={this.state.Withdraw_address}
                          onChange={this.handleChange1}
                          placeholder="Enter Wallet Address"
                          disabled
                        />

                        {/* //   <button */}
                        {/* //   className="absolute_btc max-btn btn btn_man fs14"
                        //   onClick={this.autoAmount}
                        // >
                        //   Max
                        // </button> */}

                        {this.state.whitelist_Data &&
                        this.state.whitelist_Data.length > 0 ? (
                          ""
                        ) : (
                          <button
                            className="absolute_btc max-btn btn btn_man fs14"
                            onClick={(e) => {
                              window.location.href = "/whitelist";
                            }}
                          >
                            Add Address
                          </button>
                        )}

                        {/* 
                        {this.state.whitelist_Data &&
                        this.state.whitelist_Data.length > 0 ? (
                          <input
                            type={"text"}
                            className="form-control"
                            name="Withdraw_address"
                            value={this.state.Withdraw_address}
                            onChange={this.handleChange1}
                            placeholder="Enter Wallet Address"
                            disabled
                          />
                        ) : (
                        //   <button
                        //   className="absolute_btc max-btn btn btn_man fs14"
                        //   onClick={this.autoAmount}
                        // >
                        //   Max
                        // </button>

                        
                          <button
                            className="absolute_btc max-btn btn btn_man fs14"
                            onClick={(e) => {
                              window.location.href = "/whitelist";
                            }}
                          >
                            Add Address
                          </button>
                        )} */}

                        {/* <div className="relative">{this.AddressListHtml()}</div> */}
                      </div>
                      {/* <input
                        type={"text"}
                        className="form-control"
                        name="Withdraw_address"
                        value={this.state.Withdraw_address}
                        onChange={this.handleChange1}
                        placeholder="Enter Wallet Address"
                        disabled
                      /> */}
                      <span style={{ color: "red" }}>
                        {this.state.walletErr && this.state.walletErr}
                      </span>
                    </div>
                  </div>

                  <div className="tc">
                    <button
                      className="btn btn_man fs14"
                      onClick={this.withdrawSubmit}
                      disabled={this.state.disabledCoinWithdrawal}
                    >
                      Withdraw Amount
                    </button>
                  </div>
                  <div className="disclaimer-main border-top w-100 pl-0 pr-0 mt-3">
                    <div className="disclaimer-outer">
                      <h6>
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Disclaimer
                      </h6>
                      <ul>
                        <li>
                          Send only using the Bitcoin network. Using any other
                          network will result in loss of funds.
                        </li>
                        <li>
                          Deposit only BTC to this deposit address. Depositing
                          any other asset will result in a loss of funds.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/* Withdrawal Modal End */}

          {/* Transfer Modal Start */}
          <Modal
            show={this.state.isTransferModelOpen}
            onHide={this.closeTransferModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Transfer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row align-items-center mb-3">
                <div className="form-group col mb-0">
                  <label className="mb-0 d-block">Sender </label>

                  {this.state.toWallet == "wallet" ? (
                    <select
                      className="form-control"
                      name="fromWallet"
                      value={this.state.fromWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="transactions">Spot</option>
                    </select>
                  ) : this.state.toWallet == "transactions" ? (
                    <select
                      className="form-control"
                      name="fromWallet"
                      value={this.state.fromWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="wallet">Wallet</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      name="fromWallet"
                      value={this.state.fromWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="transactions">Spot</option>
                      <option value="wallet">Wallet</option>
                    </select>
                  )}
                  <span> balance : {this.state.balanceOfAmount}</span>

                  <small>{this.state.coinAmount}</small>
                </div>
                <div className="form-group col directions_35_OP pr-0 pl-0 mb-0">
                  <span onClick={this.exchangeValue}>
                    <i className="las la-exchange-alt"></i>
                  </span>
                </div>
                <div className="form-group col mb-0">
                  <label className="mb-0 d-block">Receiver</label>

                  {this.state.fromWallet == "wallet" ? (
                    <select
                      className="form-control"
                      name="toWallet"
                      value={this.state.toWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="transactions">Spot</option>
                    </select>
                  ) : this.state.fromWallet == "transactions" ? (
                    <select
                      className="form-control"
                      name="toWallet"
                      value={this.state.toWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="wallet">Wallet</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      name="toWallet"
                      value={this.state.toWallet}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="transactions">Spot</option>
                      <option value="wallet">Wallet</option>
                    </select>
                  )}
                  <span> balance : {this.state.balanceOfAmountSpot}</span>
                  <small>
                    {this.state.CoinAmount1} {this.state.coinName1}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label className="mb-0">Coin</label>
                <div className="form-control">
                  {this.state.transferCoinName}
                </div>{" "}
              </div>
              <div className="relative">
                <div className="form-group">
                  <label className="mb-0">Amount</label>
                  <input
                    // value="0"
                    step="any"
                    className="form-control"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange}
                    placeholder="Enter Amount"
                    type="number"
                    onKeyDown={this.handleValidation}
                  />
                  <button
                    className="absolute_btc max-btn btn btn_man fs14"
                    onClick={this.autoAmount}
                  >
                    Max
                  </button>
                </div>
              </div>
              <div className="tc">
                <button
                  className="btn btn_man fs14"
                  onClick={this.handleTransfer}
                  disabled={this.state.disableTransferButton}
                >
                  Transfer
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* Transfer Modal End */}

          {/* CoinList Html Part Start */}
          <div className="bghwhite">
            <div className="dashbord_manbox zero-balance-outer">
              <div className="container container2">
                <div className="dashbord_in1">
                  <div className="dashbord_in ">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-12">
                        <div className=" form-check">
                          <label className="form-check-label">
                            <input
                              name="isGoing"
                              type="checkbox"
                              checked={this.state.isGoing}
                              onChange={this.handleCheckChange}
                            />{" "}
                            Show zero balances wallet
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8 col-12">
                        <div className="portfolio-amount d-flex justify-content-end align-items-center">
                          <p className="m-0">Portfolio :</p>

                          {/* END */}
                          <div className="amount-inner d-flex">
                            {/* <div className="amount-left">
                              <p className="mb-0">
                                <b>{parseFloat(this.state.inrPortfolio)}</b> INR{" "}
                              </p>
                            </div>{" "} */}
                            <div className="amount-right ml-2">
                              <p className="m-0">
                                <b> {parseFloat(this.state.usdtPortfolio)}</b>{" "}
                                USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="amount-tabel-main-outer">
                      <div className="in_table tc border_man">
                        <div className="table-responsive">
                          <table className="table mb-0 table_man table-striped">
                            <thead>
                              <tr>
                                <th className="tl">
                                  <b>Coin</b>
                                </th>
                                <th>
                                  <b>Main - Wallet</b>
                                </th>
                                <th>
                                  <b>SPOT - Wallet</b>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            {this.showTableHtml()}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CoinList Html Part End */}

          {/* Deposit INR Modal Start */}
          <Modal
            show={this.state.isINRDepositModelOpen}
            onHide={this.closeOpenINRDepositModal}
            className="account-popup-outer"
          >
            <Modal.Header closeButton>
              <Modal.Title> Deposit INR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>I want to deposit</label>
                <input
                  type="number"
                  // value="0"
                  className="form-control"
                  name="inr_amount"
                  value={this.state.inr_amount}
                  onChange={this.handleInrChange}
                  onKeyDown={this.handleValidation}
                />
                <span style={{ color: "red" }}>
                  {this.state.inrDepositeError}
                </span>
              </div>
              <div className="form-group">
                <label>Upload Image:</label>
                <input
                  className="form-control"
                  type="file"
                  id="file-upload3"
                  name="IdentityProof3"
                  onChange={this.onChangeImage3}
                />
                <span style={{ color: "red" }}>
                  {this.state.transactionImageError}
                </span>
              </div>
              <div className="form-group">
                <label>UTR/TID:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Utr number / Transaction number"
                  name="trNumber"
                  value={this.state.trNumber}
                  onChange={this.handleInrChange}
                />
                <span style={{ color: "red" }}>
                  {this.state.transactionIdError}
                </span>
              </div>
              <div className="choose-amount d-flex justify-content-around">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("1000")}
                >
                  + 1000 INR
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("5000")}
                >
                  + 5000 INR
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("10000")}
                >
                  + 10000 INR
                </a>
              </div>
              <div className="min-max-amount">
                <div className="row">
                  <div className="col-md-8">
                    <div className="bank-detail bank-left">
                      <h5>Bank Details</h5>
                      <div className="bank-detail-outer border-right">
                        <p>
                          Bank Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.bankname
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.bankname
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Account Number :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.accountnumber
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.accountnumber
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          IFSC Code :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.ifsccode
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.ifsccode
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Holder Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.accountholder
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.accountholder
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Branch Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.branchname
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.branchname
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="bank-detail bank-right-outer">
                      <h5>Upi</h5>
                      <p className="upi-id tc">
                        {this.state.adminbankdetails
                          ? this.state.adminbankdetails.upi_id
                          : ""}{" "}
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.adminbankdetails.upi_id
                            );
                            toast.success("Copied");
                          }}
                        >
                          <i className="fa fa-copy"></i>
                        </a>
                      </p>
                      <div className="qr-img-outer">
                        {this.state.adminbankdetails.upi_image && (
                          <img
                            src={
                              baseUrl +
                              "/static/bankImage/" +
                              this.state.adminbankdetails.upi_image
                            }
                            alt="banner"
                            className="img-thumbnail"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="deposit-amount-btn">
                <button
                  className="btn btn_man fs14 m0a"
                  style={{ display: this.state.depositeInrBtn }}
                  onClick={this.calculateCheckSum}
                  disabled={this.state.disabledInrDeposit}
                >
                  Deposit Amount
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {/* Deposit INR Modal End */}

          {/* Kyc Modal Start For Kyc */}
          <Modal
            show={this.state.isKycModalOpen}
            onHide={this.closeKycModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="kyc_popop tc">
                <img src="assets/img/v_kyc.png" alt="" />
                <h2
                  style={{ color: "#fff" }}
                  ref={(subtitle) => (subtitle = subtitle)}
                >
                  Your KYC is not done. Kindly complete your KYC.{" "}
                </h2>
                <div>
                  <Link className="btn btn_man mt-4 w100px" to="/kyc">
                    Okay!
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Kyc Modal Start For Kyc */}

          {/* Kyc Modal Start For Whitelist */}
          <Modal
            show={this.state.isWhitelistModalOpen}
            onHide={this.closeWhitelistModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="kyc_popop tc">
                <img src="assets/img/v_kyc.png" />
                <h2 ref={(subtitle) => (subtitle = subtitle)}>
                  Please Add Whitelist Address For Withdrawal.{" "}
                </h2>
                <div>
                  <Link className="btn btn_man mt-4 w100px" to="/Whitelist">
                    Okay!
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Kyc Modal End For Whitelist*/}
          <ToastContainer limit={1} />
        </div>
      </DocumentMeta>
    );
  }
}
export default account;
