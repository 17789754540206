import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const TransactionsFunction = () => {
  const meta = {
    title: `Transactions | ${ProductName}  Exchange`,
    description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };
  const [mainWallet, setMainWallet] = useState(1);
  const [allData, setAllData] = useState("");
  const [showData, setShowData] = useState(1);
  const [TransactionsStatus, setTransactionsStatus] = useState("");

  useEffect(() => {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    trasactionListData();
  }, []);

  const trasactionListData = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/transaction-list", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;
        if (resp.status) {
          setAllData(resp.data);
          setTransactionsStatus(resp.status);

          OpenMain(resp.data[0]);
        }
      });
  };

  const OpenMain = (value) => {
    setMainWallet(value.coin_id);
    setShowData(value);
  };

  const showTableHtml = (value) => {
    if (TransactionsStatus === true) {
      const html = [];
      let balance = 0;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].walletAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;
      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>
                {e.tx_type == "buy_launchpad"
                  ? "Launchpad Bought"
                  : e.tx_type == "add_launchpad_coin"
                  ? "Launchpad Coin"
                  : e.tx_type == "joining_bonus"
                  ? "Joining Bonus"
                  : e.tx_type == "referral_bonus"
                  ? "Referral Bonus"
                  : e.tx_type == "buy_stake"
                  ? "Stake Bought"
                  : e.tx_type == "blocked_by_admin"
                  ? "Stake Blocked By Admin"
                  : e.tx_type == "stopped_by_admin"
                  ? "Stake Stopped By Admin"
                  : e.tx_type == "un_stake_amount"
                  ? "UnStaked Stake"
                  : e.tx_type == "staking_closed_amount"
                  ? "Staking Closed"
                  : e.trans_type === "deposit"
                  ? "Deposit"
                  : e.tx_type == "Wallet to transactions"
                  ? "Wallet to Spot"
                  : e.tx_type == "purchase"
                  ? "Deposit"
                  : e.tx_type == "withdrawal"
                  ? "Withdrawal"
                  : e.tx_type == "withdrawal_refund"
                  ? "Withdrawal Refund"
                  : e.tx_type}
              </td>
              <td>
                {e.coin_amount - (e.coin_amount * e.admin_commission) / 100}
              </td>
              <td>{WalletBAlnace}</td>
              <td className="ip_width">{e.tx_id}</td>
              <td>{value.name}</td>
              <td className="td_date">
                {Moment.utc(e.created).local().format("lll")}
              </td>

              <td>
                {e.tx_type == "withdrawal"
                  ? e.WithdrawlRequest == "0"
                    ? "Pending"
                    : e.WithdrawlRequest == "1"
                    ? "Completed"
                    : "Rejected"
                  : e.status == "completed"
                  ? "Completed"
                  : "Pending"}
              </td>
            </tr>
          );
          WalletBAlnace = WalletBAlnace - e.coin_amount;
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  const renderCoinList = (allData) => {
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li key={index} onClick={() => OpenMain(item)}>
          <span className={active ? "active" : <></>}>{item.name}</span>
        </li>
      );
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="">
          <div className="bg_light">
            <div className="new_inpage">
              <div className="container container2">
                <div className="white_box">
                  <ul className="nav nav-tabs2 mb-3 ">
                    <li className="nav-item">
                      <Link to="/mainWalletTransactions" className="active">
                        {" "}
                        Main Wallet{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/spotHistory"> Spot </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinDeposite"> Deposit Coin </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinFundRequest"> Withdrawal </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrDepositRequest"> INR Deposit Request </Link>
                    </li>
                  </ul>
                  <ul className="nav nav-tabs offer_beu spot">
                    {renderCoinList(allData || [])}
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="Balance">
                      <div className="top_balance"></div>
                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Transaction Type</th>
                                    <th>Amount</th>
                                    <th>Final Balance</th>
                                    <th className="ip_width">Transaction ID</th>
                                    <th>Currency Type </th>
                                    <th>Date & Time</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                {showTableHtml(showData || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default TransactionsFunction;
