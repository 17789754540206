import React, { useState, useRef } from "react";
import DocumentMeta from "react-document-meta";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../../Common/BaseUrl";
import { Formik } from "formik";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import axios from "axios";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  BrowserTypes,
  osVersion,
} from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { meta } from "../../Common/Meatdata";
import { ProductName } from "../../Common/ProductName";
const baseUrl = myConstList.baseUrl;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      type: "password",
      LoginStatus: "",
      loginVia: "Password",
      countryCode: "+91",
      timerText: "Get Code",
      counter: 0,
      buttonDisableVerification: true,
    };

    async function getData() {
      const response = await axios.get("https://ipapi.co/json/");
      let data = response.data;
      localStorage.setItem("ip", data.ip ? data.ip : "");
    }
    getData();
  }
  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  state = {
    email: "",
    password: "",
    isChecked: false,
  };

  componentWillMount = () => {
    meta.title = `Login | ${ProductName} Exchange`;
    meta.description = `Login into your account in ${ProductName} Exchange.`;
    this.DeviceName();
    this.getGeoInfo();

    if (localStorage.getItem("token")) {
      window.location.href = "/";
      return false;
    }

    if (localStorage.checkbox && localStorage.email !== "") {
      this.setState({
        isChecked: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        this.setState({
          countryName: data.country_name,
          cityName: data.city,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  DeviceName = () => {
    if (isMobile) {
      this.setState({ device: "Mobile" });
    }
    if (isAndroid) {
      this.setState({ device: "Android" });
    }
    if (isIOS) {
      this.setState({ device: "IOS" });
    }
    if (isDesktop) {
      this.setState({ device: "Desktop" });
    }
  };
  handleOnBlurPassword(event) {
    let passwordError = "";
    var Password = event.target.value;
    var PwdErr = "";
    // eslint-disable-next-line
    var hasNumber = /\d/;
    if (!Password) {
      passwordError = "*Password is required";
    }
    // if (Password.length < 8) {
    //   passwordError =
    //     "*password must contain at least 8 characters and number and special character.";
    //   // PwdErr = "Password should be more than 6 Chacracters"
    //   this.setState({ passwordError, PwdErr: PwdErr });
    //   return passwordError === "";
    // }
    // var regex = "^[a-zA-Z0-9_]*$";
    // if (Password.match(regex)) {
    //   passwordError =
    //     "*password must contain characters, number and special character.";
    // }
    this.setState({
      passwordError,
      Password: Password,
      PwdErr: PwdErr,
    });
    return passwordError === "";
  }
  handleChange1 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });

    // Email //
    // if (name == "mobile") {
    //   var mobile = event.target.value;
    //   var error = "";
    //   var regexNum = /^([0|\+[0-9]{1,5})?([0-9]{10})$/;
    //   if (!mobile) {
    //     error = "This field is required"
    //     this.setState({ mobileError: error })
    //     return false
    //   }
    //   if (!value.match(regexNum)) {
    //     error = "Please enter a valid mobile number"
    //     this.setState({ mobileError: error })
    //     return false
    //   }
    //   this.setState({ mobileError: error })

    // };
    if (name == "password") {
      let error = "";
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
      if (!value) {
        error = "This field is required";
        this.setState({ passwordError: error });
        return false;
      }
      // if (!value.match(regex)) {
      //   error =
      //     "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
      //   this.setState({ passwordError: error })
      //   return false
      // }
      this.setState({ passwordError: error });
    }
    if (name === "verification_code") {
      if (!value) {
        this.setState({
          verificationCodeError: "This field is required",
        });
        return false;
      } else {
        this.setState({
          verificationCodeError: "",
        });
      }
    }
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if(!this.state.Email){
  //     toast.error("Please Enter a Email Address")
  //     return false
  //   }
  //   if(!this.state.password){
  //     toast.error(`Please Enter Password`)
  //     return false
  //   }
  //   if(this.state.emailAddressError || this.state.passwordError){
  //     toast.error(`Please Enter Valid Details`)
  //     return false
  //   }

  //   let LoginData = {
  //     email: this.state.Email,
  //     Password: this.state.password,
  //   };

  //   axios.post(baseUrl + "/frontapi/login", LoginData).then((resp) => {
  //     var resp = resp.data;

  //     this.setState({
  //       PasswordErr: resp.messagep,
  //       Msg: resp.messagee,
  //       Err: resp.message,
  //       respStatus: resp.status,
  //       respErrMsg: resp.EmailErr,
  //     });
  //     if (resp.status === false) {
  //       toast.dismiss();
  //       toast.error(resp.messagecu);

  //       return;
  //     }

  //     if (resp.status === true) {
  //       localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");
  //       localStorage.setItem("email", resp.data[0] ? resp.data[0].email : "");
  //       if (resp.token !== undefined) {
  //         localStorage.setItem("token", resp.token ? resp.token : "");
  //         localStorage.setItem("email", resp.data[0] ? resp.data[0].email : "");
  //         localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");

  //         const config = {
  //           headers: {
  //             "x-access-token": localStorage.getItem("token"),
  //           },
  //         };

  //         var userIdDataIp = {
  //           ip: localStorage.ip,
  //           device: this.state.device,
  //           browser: browserName + " " + browserVersion,
  //           osName: osName + " " + osVersion,
  //           cityName: this.state.cityName,
  //           countryName: this.state.countryName,
  //         };
  //         async function getLoginInsert() {
  //           const resp = await axios.post(
  //             baseUrl + "/frontapi/login-insert",
  //             userIdDataIp,
  //             config
  //           );
  //         }
  //         getLoginInsert();
  //         toast.dismiss();
  //         toast.success("login Successfully");
  //         this.props.history.push("/exchange/BTC/INR");
  //       }
  //       else {
  //         this.setState({ LoginStatus: true });
  //       }
  //     }
  //     //this.GoogleAuthStatus();
  //     return;

  //   });
  // };

  handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  verifyCode = () => {
    if (!this.state.vcode) {
      this.setState({ message: "This field is required" });
      return;
    }
    let LoginData = {
      Password: this.state.password,
      code: this.state.vcode,
    };
    if (this.state.mobile) {
      LoginData.mobile = this.state.mobile;
    }
    if (this.state.email) {
      LoginData.email = this.state.email;
    }

    axios
      .post(baseUrl + "/frontapi/verifyLoginGoogleCode", LoginData)
      .then((resp) => {
        // this.setState({ message: resp.data.message });
        toast.dismiss();
        if (resp.data.status == true) {
          toast.dismiss();
          toast.success(resp.data.message);
          localStorage.setItem("token", resp.data.token);

          this.props.history.push("/exchange/BTC/USDT");
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.data.message);
          // this.setState({ CodeErrMsg: resp.data.message });
        }
      });
  };
  startCountdown(seconds) {
    let counter = seconds;
    const interval = setInterval(() => {
      this.setState({ counter: counter });
      this.setState({ btnDisable: true });
      this.setState({ timerText: "Resend In" });
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        this.setState({ textForCode: "Resend Code" });
        this.setState({ btnDisable: false });
        this.setState({ timerText: "" });
      }
    }, 1000);
  }
  getCode = async (mobile) => {
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (!this.state.email && !this.state.mobile) {
      this.setState({
        emailError: "This field is required",
      });
      return false;
    }
    let registerData = {
      countryCode: this.state.countryCode,
      page: "login",
    };
    if (this.state.email) {
      if (!this.state.email.match(emailReg)) {
        this.setState({
          emailError: "Please enter valid email address",
        });
        return false;
      }

      registerData.email = this.state.email;
    }
    if (this.state.mobile) {
      registerData.mobile = this.state.mobile;
    }
    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((resp) => {
        // eslint-disable-next-line
        var resp = resp.data;
        if (resp.status === false) {
          toast.error(resp.EmailMessage);
        }
        this.setState({
          EmailMessageErr: resp.EmailMessage,
        });
        if (resp.status === true) {
          toast.success(resp.EmailMessage);
          this.startCountdown(30);
          this.setState({
            buttonDisableVerification: false,
            verificationCodeError: "",
            // verification_code: resp.code,
          });
          return false;
        }
        if (resp.status === false) {
          this.setState({ buttonDisableVerification: true });
          toast.error(resp.message);
          return false;
        }
      });
  };

  handleChange = (event) => {
    var value = event.target.value;
    this.setState({
      textForCode: "",
      timerText: "Get Code",
      verification_code: "",
    });

    if (!event.target.value) {
      this.setState({
        emailError: "This field is required",
        mobileError: "",
        placeholder: "Enter Verification Code",
        mobile: "",
        email: "",
      });
      return false;
    }
    if (
      !isNaN(parseFloat(event.target.value)) &&
      !isNaN(event.target.value - 0) == true
    ) {
      var phoneno = /^([0|\+[0-9]{1,10})?([0-9]{5})$/;
      if (value.match(phoneno)) {
        this.setState({ mobileError: "", emailError: "" });
      } else {
        this.setState({
          mobileError: "Please enter valid mobile number",
          emailError: "",
        });
      }
      this.setState({
        mobile: event.target.value,
        email: "",
        placeholder: "Enter Mobile Code",
      });
    } else {
      var Email = event.target.value;
      // Email = Email.toLowerCase();
      // var emailReg =
      //   /^([a-zA-Z\d.-]+)@([a-zA-Z.-]+).([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      this.setState({
        email: event.target.value.toLowerCase(),
      });

      if (!value.match(emailReg)) {
        this.setState({
          email: event.target.value.toLowerCase(),
          mobile: "",
          emailError: "Please enter a valid email address",
          mobileError: "",
        });
        return false;
      } else {
        this.setState({
          email: event.target.value.toLowerCase(),
          mobile: "",
          placeholder: "Enter Email Code",
          emailError: "",
          mobileError: "",
        });
      }
    }
  };
  handleAuthChange = (e) => {
    this.setState({ vcode: e.target.value });
    if (!e.target.value) {
      this.setState({ message: "This field is required" });
      return false;
    } else {
      this.setState({ message: "" });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.loginVia == "OTP" &&
      !this.state.verification_code &&
      !this.state.email &&
      !this.state.mobile
    ) {
      this.setState({
        verificationCodeError: "This field is required",
        emailError: "This field is required",
      });
      return false;
    }
    if (
      this.state.loginVia == "Password" &&
      !this.state.password &&
      !this.state.email &&
      !this.state.mobile
    ) {
      this.setState({
        passwordError: "This field is required",
        emailError: "This field is required",
      });
      return false;
    }

    // if (
    //   this.state.loginVia == "Password" &&
    //   !this.state.Password &&
    //   !this.state.email &&
    //   !this.state.mobile
    // ) {
    //   this.setState({
    //     passwordError: "This field is required",
    //     emailError: "This field is required",
    //   });
    //   return false;
    // }

    if (this.state.loginVia == "OTP" && !this.state.verification_code) {
      this.setState({ verificationCodeError: "This field is required" });
      return false;
    }
    if (!this.state.email && !this.state.mobile) {
      this.setState({
        emailError: "This field is required",
      });
      return false;
    }
    if (this.state.loginVia == "Password" && !this.state.password) {
      this.setState({ passwordError: "This field is required" });
      return false;
    }
    if (
      this.state.mobileError ||
      this.state.verificationCodeError ||
      this.state.passwordError ||
      this.state.emailError
    ) {
      return false;
    }

    let values = {};
    this.state.loginVia == "Password"
      ? (values = {
          Password: this.state.password,
        })
      : (values = {
          verification_code: this.state.verification_code,
        });
    if (this.state.email) {
      values.email = this.state.email;
    }
    if (this.state.mobile) {
      values.mobile = this.state.mobile;
    }

    // return;

    axios.post(baseUrl + "/frontapi/login", values).then((resp) => {
      var resp = resp.data;
      this.setState({
        PasswordErr: resp.messagePass,
        Msg: resp.messagee,
        Err: resp.message,
        respStatus: resp.status,
        respErrMsg: resp.messageEmail,
      });
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }

      if (resp.status === true) {
        localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");
        localStorage.setItem("mobile", resp.data[0] ? resp.data[0].mobile : "");
        if (resp.token !== undefined) {
          localStorage.setItem("token", resp.token ? resp.token : "");
          localStorage.setItem(
            "mobile",
            resp.data[0] ? resp.data[0].mobile : ""
          );
          localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");

          const config = {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          };

          var userIdDataIp = {
            ip: localStorage.getItem("ip"),
            device: this.state.device,
            browser: browserName + " " + browserVersion,
            osName: osName + " " + osVersion,
            cityName: this.state.cityName,
            countryName: this.state.countryName,
          };
          async function getLoginInsert() {
            const resp = await axios.post(
              baseUrl + "/frontapi/login-insert",
              userIdDataIp,
              config
            );
          }
          getLoginInsert();
          toast.dismiss();
          toast.success("login Successfully");
          this.props.history.push("/exchange/TRX/USDT");
        } else {
          this.setState({ LoginStatus: true });
        }
      }
      return;
    });
  };

  render() {
    return (
      <DocumentMeta {...meta}>
        <div className="">
          <ToastContainer />
          <div className="register-main-outer position-relative d-flex align-items-center flex-wrap">
            <span className="main-bg fadeInLeft animated"></span>
            <div className="register-inner">
              <div className="row m-0">
                <div className="col-12 p-0">
                  <div className="register-right fadeInRight animated ">
                    <div className="heading-link-outer">
                      <div className="row align-items-center">
                        <div className="col-sm-12 text-left">
                          <div className="heading">
                            <h2 className="m-0">Login</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="register-form">
                      {this.state.LoginStatus == false ||
                      this.state.LoginStatus == "" ? (
                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <div className="form-group position-relative">
                                <label>Email or Mobile Number</label>
                                {/* <PhoneInput
                                className="country-code position-absolute"
                                countryCodeEditable={true}
                                country={"in"}
                                enableSearch={true}
                                value={this.state.countryCode}
                                disabled={this.state.codeMatchedStatus}
                                onChange={(countryCode) => this.setState({ countryCode: "+" + countryCode,
                                mobileError:"",emailError:"",verificationError:"" })}
                              /> */}
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Enter Email or Mobile Number"
                                  name="Email"
                                  value={this.state.Email}
                                  onChange={this.handleChange}
                                  // onBlur={this.handleOnBlurEmail.bind(this)}
                                  disabled={
                                    this.state.counter == 0 ? false : true
                                  }
                                />
                                {/* <input
                                  className="form-control country-input"
                                  type="text"
                                  placeholder="Enter Email"
                                  name="email"
                                  onBlur={this.handleBlurChange}
                                  onChange={this.handleChange101}
                                  value={this.state.email}
                                  disabled={
                                    this.state.codeMatchedStatus || this.state.timerText == 0 ? false : true
                                  }
                                /> */}
                                {this.state.mobileError && (
                                  <div style={{ color: "red" }}>
                                    {this.state.mobileError}
                                  </div>
                                )}
                                {this.state.emailError && (
                                  <div style={{ color: "red" }}>
                                    {this.state.emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            {this.state.loginVia == "Password" ? (
                              <div className="col-md-12 col-12">
                                <div className="form-group">
                                  <label>Password</label>
                                  <div className="eye_pass">
                                    <input
                                      className="form-control"
                                      autoComplete="off"
                                      type={this.state.type}
                                      name="password"
                                      id="password"
                                      placeholder="Enter Password"
                                      value={this.state.password}
                                      onChange={this.handleChange1}
                                      onBlur={this.handleBlur}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.passwordError}
                                    </span>

                                    <span
                                      className="password__show eye1"
                                      onClick={this.handleClick}
                                    >
                                      {this.state.type === "text" ? (
                                        <i className="las la-eye"></i>
                                      ) : (
                                        <i className="las la-low-vision"></i>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-sm-12 col-12">
                                <div className="form-group">
                                  <label>Verification Code</label>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter Verification Code"
                                      name="verification_code"
                                      id="verification_code"
                                      value={this.state.verification_code}
                                      onKeyDown={this.handleValidation}
                                      onChange={this.handleChange1}
                                      disabled={
                                        this.state.buttonDisableVerification
                                      }
                                      onBlur={this.handleBlur}
                                      // value={this.state.VerificationCode}
                                      aria-label="Input group example"
                                      aria-describedby="btnGroupAddon"
                                    />
                                    <div className="input-group-prepend">
                                      <button
                                        className="btn btn_man"
                                        id="btnGroupAddon"
                                        type="button"
                                        onClick={this.getCode}
                                        disabled={
                                          this.state.counter == 0 ? false : true
                                        }
                                      >
                                        {this.state.timerText}
                                        {"  "}
                                        {this.state.counter == 0
                                          ? this.state.textForCode
                                          : this.state.counter}
                                      </button>
                                    </div>
                                  </div>
                                  <div style={{ color: "red" }}>
                                    {this.state.verificationCodeError}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-6 col-12">
                              <div className="via-otp">
                                <a
                                  className="login-links"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    this.state.loginVia == "Password"
                                      ? this.setState({
                                          verification_code: "",
                                          loginVia: "OTP",
                                          mobileError: "",
                                          emailError: "",
                                          passwordError: "",
                                          verificationCodeError: "",
                                        })
                                      : this.setState({
                                          loginVia: "Password",
                                          mobileError: "",
                                          password: "",
                                          passwordError: "",
                                          verificationCodeError: "",
                                        });
                                  }}
                                >
                                  Via{" "}
                                  {this.state.loginVia == "Password"
                                    ? "OTP"
                                    : "Password"}
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="forgot-btn text-right">
                                <a href="/forgot" className="login-links">
                                  {" "}
                                  Forgot password ?{" "}
                                </a>

                                {/* <Link to="/forgot" className="a_color">
                                  Forgot password ?
                                </Link> */}
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="form-group log-btn mx-auto">
                                <button
                                  className="btn w100 btn_man"
                                  type="submit"
                                >
                                  Login
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="form-group text-center ">
                            Don't have an Account? Let's
                            <a href="/register" className="login-links">
                              {" "}
                              Sign Up
                              {/* Sign up */}{" "}
                            </a>
                            {/* <Link to="/register" className="">
                              Sign up
                            </Link> */}
                          </div>
                        </form>
                      ) : (
                        <div className="">
                          <h5>Two Factor Authentication</h5>
                          <div className="form-group">
                            <label className="fw-500">Enter Auth Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="vcode"
                              placeholder="Enter Code"
                              value={this.state.vcode}
                              onChange={this.handleAuthChange}
                            />
                            <p className="text-danger">{this.state.message} </p>
                          </div>
                          <div className="form-group">
                            <button
                              onClick={this.verifyCode}
                              className="btn btn_man w100px"
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

export default withRouter(Login);
