import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Nav from "../Common/Nav";
import DocumentMeta from "react-document-meta";
import $ from "jquery";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "LaunchPad Form | VenCrypto  Exchange",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin",
  canonical: "https://vencrypto.com",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const LaunchPadForm = () => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [ICOPrice, setICOPrice] = useState("");
  const [sessionSupply, setSessionSupply] = useState("");
  const [maxPurchase, setMaxPurchase] = useState("");
  const [ICOStartDate, setICOStartDate] = useState("");
  const [ICOEndDate, setICOEndDate] = useState("");
  const [chain, setChain] = useState("");
  const [BSCChain, setBSCChain] = useState("");
  const [highlight, setHighlight] = useState("");
  const [strength, setStrength] = useState("");
  const [document, setDocument] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState("");
  const [userUploadLogo, setUserUploadLogo] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [socialMediaObj, setSocialMediaObj] = useState([
    {
      handle: "",
      link: "",
    },
  ]);

  // error
  const [errorLogo, setErrorLogo] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorShortName, setErrorShortName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorWebsite, setErrorWebsite] = useState("");
  const [errorICOPrice, setErrorICOPrice] = useState("");
  const [errorSessionSupply, setErrorSessionSupply] = useState("");
  const [errorMaxPurchase, setErrorMaxPurchase] = useState("");
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorChain, setErrorChain] = useState("");
  const [errorBSCchain, setErrorBSCchain] = useState("");
  const [errorHighlight, setErrorHighlight] = useState("");
  const [errorStrength, setErrorStrength] = useState("");
  const [errorDocument, setErrorDocument] = useState("");
  const [errorSocialMedia, setErrorSocialMedia] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [socialMediaObjError, setSocialMediaObjError] = useState([
    {
      handle: "",
      link: "",
    },
  ]);

  useEffect(() => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;

      if (resp.data[0].kycVerification_status !== "Y") {
        toast.dismiss();
        toast.error("Please complete KYC first");

        setTimeout(() => {
          window.location.href = "/launchpad";
        }, 3000);
      }
    });
  }, []);

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });

    if (name === "name") {
      // const newValue = value.toUpperCase();
      setName(value);

      if (!value) {
        setErrorName("Please enter token name");
        return false;
      }
      setErrorName("");
    }

    if (name === "shortName") {
      const newValue = value.toUpperCase();
      setShortName(newValue);

      if (!newValue) {
        setErrorShortName("Please enter short name");
        return false;
      }
      setErrorShortName("");
    }

    if (name === "email") {
      setEmail(value);

      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      if (!value) {
        setErrorEmail("Please enter email");
        return false;
      }

      if (!value.match(emailReg)) {
        setErrorEmail("Please enter valid email");
        return false;
      }

      setErrorEmail("");
    }

    if (name === "mobile") {
      setNumber(value);

      if (!value) {
        setErrorNumber("Please enter mobile number");
        return false;
      }

      // const firstDigit = parseInt(value.charAt(0));

      // // Check if the first digit is 6 or greater than 6
      // if (firstDigit < 5) {
      //   setErrorNumber("Please enter a valid mobile number");
      //   return false;
      // }

      // if (value.length < 5) {
      //   setErrorNumber("Mobile number should be atleast 5 digits");
      //   return false;
      // }
      // if (value.length > 15) {
      //   setErrorNumber("Mobile number should be atleast 15 digits");
      //   return false;
      // }
      setErrorNumber("");
    }

    if (name === "website") {
      setWebsite(value);
      if (!value) {
        setErrorWebsite("Please enter website");
        return false;
      }
      setErrorWebsite("");
    }

    if (name === "sessionSupply") {
      setSessionSupply(value);
      if (!value) {
        setErrorSessionSupply("Please enter session supply");
        return false;
      }
      setErrorSessionSupply("");
    }

    if (name === "maxPurchase") {
      setMaxPurchase(value);
      if (!value) {
        setErrorMaxPurchase("Please enter max purchase amount");
        return false;
      }

      if (value <= 0) {
        setErrorMaxPurchase("Please enter valid max purchase amount");
        return false;
      }

      setErrorMaxPurchase("");
    }

    if (name === "ICOPrice") {
      setICOPrice(value);
      if (!value) {
        setErrorICOPrice("Please enter ICO price");
        return false;
      }
      setErrorICOPrice("");
    }

    if (name === "startDate") {
      setICOStartDate(value);
      if (!value) {
        setErrorStartDate("Please enter start date");
        return false;
      }
      setErrorStartDate("");
      setErrorEndDate("");
    }

    if (name === "endDate") {
      setICOEndDate(value);
      if (!value) {
        setErrorEndDate("Please enter end date");
        return false;
      }
      setErrorStartDate("");
      setErrorEndDate("");
    }

    if (name === "chain") {
      setChain(value);

      if (!value) {
        setErrorChain("Please enter chain");
        return false;
      }
      setErrorChain("");
    }

    if (name === "BSCChain") {
      setBSCChain(value);

      if (!value) {
        setErrorBSCchain("Please enter contract address");
        return false;
      }
      setErrorBSCchain("");
    }

    if (name === "highlight") {
      setHighlight(value);

      if (!value) {
        setErrorHighlight("Please enter project highlight");
        return false;
      }
      setErrorHighlight("");
    }

    if (name === "strength") {
      setStrength(value);

      if (!value) {
        setErrorStrength("Please enter project strength");
        return false;
      }
      setErrorStrength("");
    }

    if (name === "socialMedia") {
      setSocialMedia(value);

      if (!value) {
        setErrorSocialMedia("Please enter social media");
        return false;
      }

      setErrorSocialMedia("");
    }

    if (name === "document") {
      setDocument(e.target.files[0]);

      var temp_doc = e.target.files[0];

      if (temp_doc === false) {
        setErrorDocument("Please select document");
        return false;
      }

      if (!temp_doc.name.match(/\.(pdf)$/)) {
        setErrorDocument("Please select valid input pdf");
        return false;
      }

      if (temp_doc.size >= 5242880 || temp_doc.size < 10240) {
        setErrorDocument("Docment size should be 10KB to 5MB");
        return false;
      }

      setErrorDocument("");
      // setUserUploadDoc(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "logo") {
      setLogo(e.target.files[0]);

      var temp_logo = e.target.files[0];

      if (temp_logo === false) {
        setUserUploadLogo(false);
        setErrorLogo("Please select logo");
        return false;
      }

      if (!temp_logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setUserUploadLogo(false);
        setErrorLogo("Please select valid input jpg,jpeg,png");
        return false;
      }

      if (temp_logo.size >= 2048000 || temp_logo.size < 10240) {
        setErrorLogo("Logo image size should be 10KB to 2MB");
        return false;
      }

      setErrorLogo("");
      setUserUploadLogo(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "description") {
      setDescription(value);
      if (!value) {
        setErrorDescription("Please enter description");
        return false;
      }
      setErrorDescription("");
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = (e) => {
    e.preventDefault();
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (
      !name &&
      !shortName &&
      !email &&
      !number &&
      !website &&
      !ICOPrice &&
      !sessionSupply &&
      !maxPurchase &&
      !ICOStartDate &&
      !ICOEndDate &&
      !chain &&
      !BSCChain &&
      !highlight &&
      !strength &&
      !document &&
      !logo &&
      !description
    ) {
      setErrorName("Please enter token name");
      setErrorShortName("Please enter short name");
      setErrorEmail("Please enter email");
      setErrorNumber("Please enter mobile number");
      setErrorWebsite("Please enter website");
      setErrorICOPrice("Please enter ICO price");
      setErrorSessionSupply("Please enter session supply");
      setErrorMaxPurchase("Please enter max purchase amount");
      setErrorStartDate("Please enter start date");
      setErrorEndDate("Please enter end date");
      setErrorChain("Please enter chain");
      setErrorBSCchain("Please enter contract address");
      setErrorHighlight("Please enter project highlight");
      setErrorStrength("Please enter project strength");
      setErrorDocument("Please select document");
      setErrorLogo("Please select logo");
      setErrorDescription("Please enter description");
      return false;
    }

    if (!name) {
      setErrorName("Please enter token name");
      return false;
    }

    let errorName = checkSpace("token name", name);
    if (errorName.length > 0) {
      setErrorName(errorName);
      return false;
    }

    if (!shortName) {
      setErrorShortName("Please enter short name");
      return false;
    }

    errorName = checkSpace("short name", shortName);
    if (errorName.length > 0) {
      setErrorShortName(errorName);
      return false;
    }

    if (!email) {
      setErrorEmail("Please enter email");
      return false;
    }

    if (!email.match(emailReg)) {
      setErrorEmail("Please enter valid email");
      return false;
    }

    if (!number) {
      setErrorNumber("Please enter mobile number");
      return false;
    }

    // if (number.length < 5) {
    //   setErrorNumber("Mobile number should be atleast 5 digits");
    //   return false;
    // }

    // if (number.length > 15) {
    //   setErrorNumber("Mobile number should be atleast 15 digits");
    //   return false;
    // }

    // const firstDigit = parseInt(number.charAt(0));

    // if (firstDigit < 5) {
    //   setErrorNumber("Please enter valid mobile number");
    //   return false;
    // }

    if (!website) {
      setErrorWebsite("Please enter website");
      return false;
    }

    errorName = checkSpace("website", website);
    if (errorName.length > 0) {
      setErrorWebsite(errorName);
      return false;
    }

    if (!ICOPrice) {
      setErrorICOPrice("Please enter ICO price");
      return false;
    }

    if (ICOPrice <= 0) {
      setErrorICOPrice("Please enter valid ICO price");
      return false;
    }

    if (!sessionSupply) {
      setErrorSessionSupply("Please enter session supply");
      return false;
    }

    if (sessionSupply <= 0) {
      setErrorSessionSupply("Please enter valid session supply");
      return false;
    }

    errorName = checkSpace("session supply", sessionSupply);
    if (errorName.length > 0) {
      setErrorSessionSupply(errorName);
      return false;
    }

    if (!maxPurchase) {
      setErrorMaxPurchase("Please enter max purchase amount");
      return false;
    }

    if (maxPurchase <= 0) {
      setErrorMaxPurchase("Please enter valid max purchase amount");
      return false;
    }

    if (!ICOStartDate) {
      setErrorStartDate("Please enter start date");
      return false;
    }

    if (!ICOEndDate) {
      setErrorEndDate("Please enter end date");
      return false;
    }

    if (ICOStartDate == ICOEndDate) {
      setErrorEndDate("End date cannot be same as start date");
      return false;
    }

    if (!chain) {
      setErrorChain("Please enter chain");
      return false;
    }

    errorName = checkSpace("chain", chain);
    if (errorName.length > 0) {
      setErrorChain(errorName);
      return false;
    }

    if (!BSCChain) {
      setErrorBSCchain("Please enter contract address");
      return false;
    }

    errorName = checkSpace("contract address", BSCChain);
    if (errorName.length > 0) {
      setErrorBSCchain(errorName);
      return false;
    }

    if (!highlight) {
      setErrorHighlight("Please enter project highlight");
      return false;
    }
    errorName = checkSpace("project highlight", highlight);
    if (errorName.length > 0) {
      setErrorHighlight(errorName);
      return false;
    }

    if (!strength) {
      setErrorStrength("Please enter project strength");
      return false;
    }
    errorName = checkSpace("project strength", strength);
    if (errorName.length > 0) {
      setErrorStrength(errorName);
      return false;
    }

    if (!document || document.length < 0) {
      setErrorDocument("Please select document");
      return false;
    }

    if (!document.name.match(/\.(pdf)$/)) {
      setErrorDocument("Please select valid input pdf");
      return false;
    }

    if (document.size >= 2048000 || document.size < 10240) {
      setErrorDocument("Document size should be 10KB to 2MB");
      return false;
    }

    if (!logo || logo.length < 0) {
      setErrorLogo("Please select logo");
      return false;
    }

    if (!logo.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorLogo("Please select valid input jpg,jpeg,png");
      return false;
    }

    if (logo.size >= 2048000 || logo.size < 10240) {
      setErrorLogo("Logo size should be 10KB to 2MB");
      return false;
    }

    for (let i = 0; i < socialMediaObj.length; i++) {
      const element = socialMediaObj[i];
      let inventoryError = [...socialMediaObjError];
      var socialLink = element.link;
      var socialName = element.handle;

      if (!socialLink) {
        inventoryError[i].link = "Please enter social link";
        setSocialMediaObjError(inventoryError);
        return false;
      }

      if (!socialName || socialName === "Please select") {
        inventoryError[i].handle = "Please select social media";
        setSocialMediaObjError(inventoryError);
        return false;
      }
    }

    if (!description) {
      setErrorDescription("Please enter description");
      return false;
    }

    errorName = checkSpace("description", description);
    if (errorName.length > 0) {
      setErrorDescription(errorName);
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    setBtnDisable(true);

    const formData = new FormData();

    formData.append("tokenName", name);
    formData.append("shortName", shortName);
    formData.append("email", email);
    formData.append("mobile", number);
    formData.append("website", website);
    formData.append("ICOPrice", ICOPrice);
    formData.append("sessionSupply", sessionSupply);
    formData.append("maxPurchase", maxPurchase);
    formData.append("startDate", ICOStartDate);
    formData.append("endDate", ICOEndDate);
    formData.append("chain", chain);
    formData.append("BSCChain", BSCChain);
    formData.append("highlight", highlight);
    formData.append("strength", strength);
    formData.append("Document", document);
    formData.append("Logo", logo);
    formData.append("socialMedia", JSON.stringify(socialMediaObj));
    formData.append("description", description);

    axios
      .post(baseUrl + "/frontapi/launchpad", formData, config)
      .then((resp) => {
        var res = resp.data;

        if (res.status === true) {
          toast.dismiss();
          toast.success(res.message);

          setTimeout(() => {
            window.location.href = "/launchPad";
          }, 2000);
          return false;
        } else {
          toast.dismiss();
          toast.error(res.message);
          if (res.message == "Failed to authenticate token.") {
            setTimeout(() => {
              window.location.href = "/login";
            }, 2500);
            return false;
          }
          setBtnDisable(false);
        }
      });
  };

  let handleChangeSocial = (i, e) => {
    let newFormValues = [...socialMediaObj];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...socialMediaObjError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "Please enter social link";
    setSocialMediaObj(newFormValues);

    if (e.target.name === "handle") {
      if (
        !newFormValues[i].handle ||
        newFormValues[i].handle === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "";

        newFormValuesError[i][e.target.name] = "Please select social media";
        setSocialMediaObjError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setSocialMediaObjError(newFormValuesError);
      }
    }

    if (e.target.name === "link") {
      if (!newFormValues[i].link) {
        newFormValuesError[i][e.target.name] = tempErr;
        setSocialMediaObjError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setSocialMediaObjError(newFormValuesError);
      }
    }
  };

  let addFormFields = (e) => {
    e.preventDefault();
    setSocialMediaObj([...socialMediaObj, { handle: "", link: "" }]);
    setSocialMediaObjError([...socialMediaObjError, { handle: "", link: "" }]);
  };

  let removeFormFields = (i, e) => {
    e.preventDefault();
    let newFormValues = [...socialMediaObj];
    newFormValues.splice(i, 1);
    setSocialMediaObj(newFormValues);

    let newFormValuesError = [...socialMediaObjError];
    newFormValuesError.splice(i, 1);
    setSocialMediaObjError(newFormValuesError);
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        <div className="trade-volume">
          <div className="container">
            <div className="section-heading text-center">
              <h2>Launch Pad Form</h2>
            </div>
          </div>
        </div>
        <div className="listing-main">
          <div className="container">
            <div className="listing-outer">
              <div className="listing-form">
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Token Name</label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange101}
                          value={name}
                          placeholder="Enter Token Name"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorName}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Short Name</label>
                        <input
                          type="text"
                          name="shortName"
                          onChange={handleChange101}
                          value={shortName}
                          placeholder="Enter Short Name"
                          className="form-control"
                        />{" "}
                        <span style={{ color: "red" }}>{errorShortName}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange101}
                          value={email}
                          placeholder="Enter Email"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorEmail}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          name="mobile"
                          onChange={handleChange101}
                          onKeyDown={handleValidation}
                          value={number}
                          placeholder="Enter Mobile Number"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorNumber}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Website</label>
                        <input
                          type="text"
                          name="website"
                          onChange={handleChange101}
                          value={website}
                          placeholder="Enter Website"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorWebsite}</span>
                      </div>
                    </div>

                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>ICO Price(USDT)</label>
                        <input
                          type="number"
                          name="ICOPrice"
                          onChange={handleChange101}
                          onKeyDown={handleValidation}
                          value={ICOPrice}
                          placeholder="Enter ICO Price(USDT)"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorICOPrice}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Session Supply</label>
                        <input
                          type="number"
                          name="sessionSupply"
                          onChange={handleChange101}
                          onKeyDown={handleValidation}
                          value={sessionSupply}
                          placeholder="Enter Session Supply"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {errorSessionSupply}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Max Purchase Amount</label>
                        <input
                          type="number"
                          name="maxPurchase"
                          onChange={handleChange101}
                          onKeyDown={handleValidation}
                          value={maxPurchase}
                          placeholder="Enter Max Purchase Amount"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorMaxPurchase}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>ICO Start Date</label>
                        <input
                          type="Date"
                          name="startDate"
                          onChange={handleChange101}
                          value={ICOStartDate}
                          placeholder="Enter "
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorStartDate}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>ICO End Date</label>
                        <input
                          type="date"
                          name="endDate"
                          onChange={handleChange101}
                          value={ICOEndDate}
                          placeholder="Enter "
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorEndDate}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Chain</label>
                        <input
                          type="text"
                          name="chain"
                          onChange={handleChange101}
                          value={chain}
                          placeholder="Enter Chain"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorChain}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Contract Address</label>
                        <input
                          type="text"
                          name="BSCChain"
                          onChange={handleChange101}
                          value={BSCChain}
                          placeholder="Enter Contract Address"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorBSCchain}</span>
                      </div>
                    </div>

                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Project Highlight</label>
                        <input
                          type="text"
                          name="highlight"
                          onChange={handleChange101}
                          value={highlight}
                          placeholder="Enter Project Highlight"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorHighlight}</span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Project Strength</label>
                        <input
                          type="text"
                          name="strength"
                          onChange={handleChange101}
                          value={strength}
                          placeholder="Enter Project Strength"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorStrength}</span>
                      </div>
                    </div>

                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Documents(Whitepaper) </label>
                        <input
                          type="file"
                          name="document"
                          placeholder=""
                          onChange={handleChange101}
                          accept=".pdf"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{errorDocument}</span>
                      </div>
                    </div>

                    {/* <div className="form-group"> */}

                    {/* <div className="col-sm-3 col-12">
                      <label>Social Media</label>
                      <input
                        type="text"
                        name="socialMedia"
                        onChange={handleChange101}
                        value={socialMedia}
                        placeholder="Enter Social Media"
                        className="form-control"
                      />
                      <span style={{ color: "red" }}>{errorSocialMedia}</span>
                    </div>
                    <div className="col-sm-3 col-12">
                      <label>Link </label>
                      <input
                        type="text"
                        name="socialMedia"
                        onChange={handleChange101}
                        value={socialMedia}
                        placeholder="Enter Social Media"
                        className="form-control"
                      />
                      <span style={{ color: "red" }}>{errorSocialMedia}</span>
                    </div> */}

                    {/* </div> */}

                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Logo</label>
                        <input
                          type="file"
                          name="logo"
                          onChange={handleChange101}
                          accept="image/*"
                          placeholder=""
                          className="form-control"
                        />
                        {userUploadLogo && (
                          <img
                            id="file-image"
                            src={userUploadLogo != false ? userUploadLogo : ""}
                            alt="Preview"
                            className="img-fluid"
                          />
                        )}
                        <span style={{ color: "red" }}>{errorLogo}</span>
                      </div>
                    </div>

                    {socialMediaObj.map((element, index) => (
                      <>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label>Link</label>
                            <input
                              type="text"
                              name="link"
                              placeholder="Enter Link"
                              className="form-control"
                              value={element.link || ""}
                              onChange={(e) => handleChangeSocial(index, e)}
                            />
                            <p style={{ color: "red", margin: "0" }}>
                              {" "}
                              {socialMediaObjError[index].link}{" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="add-row-group d-flex align-items-center justify-content-between lauch-from-grid">
                            <div className="form-group">
                              <label>Social Media</label>
                              <select
                                className="form-control"
                                name="handle"
                                value={element.handle || ""}
                                onChange={(e) => handleChangeSocial(index, e)}
                              >
                                <option value={"Please select"}>
                                  Please select
                                </option>
                                <option value={"Facebook"}>Facebook</option>
                                <option value={"Instagram"}>Instagram</option>
                                <option value={"LinkedIn"}>LinkedIn</option>
                                <option value={"Medium"}>Medium</option>
                                <option value={"Reddit"}>Reddit</option>
                                <option value={"Telegram"}>Telegram</option>
                                <option value={"Twitter"}>Twitter</option>
                              </select>
                              <p style={{ color: "red" }}>
                                {" "}
                                {socialMediaObjError[index].handle}{" "}
                              </p>
                            </div>
                            <div className="add-row">
                              {index ? (
                                <button
                                  className="remove"
                                  // href="#!"
                                  onClick={(e) => removeFormFields(index, e)}
                                >
                                  -
                                </button>
                              ) : null}
                              <button
                                className="add-btn add"
                                // href="#!"
                                onClick={(e) => addFormFields(e)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                    <div className="col-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          placeholder="Enter Description"
                          onChange={handleChange101}
                          name="description"
                          value={description}
                          className="form-control"
                        ></textarea>

                        <span style={{ color: "red" }}>{errorDescription}</span>
                      </div>
                    </div>

                    <div className="col-12 text-right">
                      <div className="btn-group save-cancel">
                        <button
                          type="submit"
                          class="btn btn-success"
                          disabled={btnDisable}
                        >
                          Submit
                        </button>
                        <button
                          class="btn btn-danger"
                          // onClick={() => {
                          //   window.location.href = "/launchPad";
                          // }}
                        >
                          <Link to={"/launchPad"}>Cancel</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default LaunchPadForm;
