import React, { useEffect, useState } from "react";
import Footer from "./Common/Footer";
import * as myConstList from "./Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { ImageValidation } from "./Validation/ImageValidation";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";
import LoginHeader from "./Common/LoginHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const baseUrl = myConstList.baseUrl;

const ChattingFunction = (props) => {
  const meta = {
    title: `Support-Chat | ${ProductName}  Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const params = useParams();
  const verifyidpic = "./assets/img/support.jpg";
  const tichekId = params.coin;

  const [tabledata1, setTabledata1] = useState([]);
  const [ResponseData, setResponseData] = useState("");
  const [issueImageSecond, setIssueImageSecond] = useState("");
  const [subject, setSubject] = useState("");
  const [OpenSteps, setOpenSteps] = useState("Send_Message");
  const [OpenStepsAll, setOpenStepsAll] = useState("all");
  const [dateTime, setDateTime] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [query, setQuery] = useState("");
  const [queryIssue, setQueryIssue] = useState("");
  const [userUploadImage, setUserUploadImage] = useState(
    "/assets/img/support.jpg"
  );
  const [imageError, setImageError] = useState("");
  const [issueText, setIssueText] = useState("");
  const [queryImage, setQueryImage] = useState(false);

  useEffect(() => {
    if (/^-?[\d.]+(?:e-?\d+)?$/.test(tichekId) == true) {
      var encodes = btoa(tichekId);
      // this.setState(
      //   {
      //     decodes: atob(encodes),
      //   },
      //   () => {
      //     TicketList();
      //     ticketchat();
      //   }
      // );
      window.location.replace(`Chatting/${encodes}`);
    } else {
      ticketchat();
      TicketList();
    }
  }, []);

  const ticketchat = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var decodes = atob(tichekId);
    var userIdData = { tichekId: decodes };
    if (params.status == "1" || params.status == 1) {
      axios
        .post(baseUrl + "/frontapi/seenStatusChange", userIdData, config)
        .then((res) => {
          var resp = res.data;
        });
    }

    axios
      .post(baseUrl + "/frontapi/ticket-chat", userIdData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTabledata(resp.data);
        }

        if (resp.success === true) {
        }
      });
  };

  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
    });
  };

  const chatSubmit = (e) => {
    if (!query) {
      setQueryIssue("Please enter your message");
      return false;
    }

    let hasLeadingSpace = /^\s/.test(query);
    let hasTrailingSpace = /\s$/.test(query);

    if (hasLeadingSpace) {
      setQueryIssue("Please enter a valid message");
      return false;
    }

    if (hasTrailingSpace) {
      setQueryIssue("Please enter a valid message");
      return false;
    }

    // if (queryImage == undefined) {
    //   toast.error("Please Select Image");
    //   return false;
    // }

    // if (query === undefined) {
    //   toast.error("Please select Subject.");
    //   return false;
    // }
    var decodes = atob(tichekId);

    var userIdData = {
      tichekId: decodes,
      query: query,
    };

    const formData = new FormData();
    formData.append("userId", localStorage.id);
    formData.append("tichekId", decodes);
    formData.append("query", query);
    formData.append("queryImage", queryImage);
    formData.append("replyBy", 2);
    for (var pair of formData.entries()) {
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data",
      },
    };

    setBtnDisable(false);

    axios
      .post(baseUrl + "/frontapi/chat-insert", formData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          ticketchat();
          setQuery("");
          setQueryIssue("");
          setQueryImage(false);
          setUserUploadImage(false);

          toast.success(resp.message);
          return false;
        } else {
          toast.error(resp.message);
        }
      });
  };

  const onChangeImage = (e) => {
    e.preventDefault();
    setQueryImage(false);
    setUserUploadImage(false);

    var queryImage = e.target.files[0];
    setUserUploadImage(URL.createObjectURL(queryImage));

    let error = ImageValidation("Chatting", queryImage);

    if (error) {
      setBtnDisable(false);
      setImageError(error);
      return false;
    }
    setImageError("");

    if (query.length > 0) {
      setBtnDisable(true);
    }

    // if (queryImage == false) {
    //   toast.error("Please select image.");
    //   return false;
    // }
    // if (!queryImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   toast.error("Please select valid image.");
    //   return false;
    // }
    setQueryImage(queryImage);
  };

  const onChangeIssue = (event) => {
    // var query = editor.getData();
    let { name, value } = event.target;
    if (name === "query") {
      setQuery(value);
      setQueryIssue("");

      if (!value) {
        setQueryIssue("This field is required");
        setBtnDisable(false);
      } else {
        setQuery(value);
        setQueryIssue("");
        setBtnDisable(true);
      }
    }
  };

  const showTableHtml = () => {
    var stylediv = "";
    const html = [];
    let dateArray = [];
    if (tabledata.length > 0) {
      tabledata.map((value, i) => {
        if (value.queryImage != "") {
          var profilePic = baseUrl + "/static/helpIssue/" + value.queryImage;
        }
        var date = "";

        if (value.replyBy == 1) {
          stylediv = "charting-part left_side_div";
          date = value.updatedAt;
        } else {
          stylediv = "charting-part right_side_div";
          date = value.createdAt;
        }
        let data = Moment(value.createdAt)
          .format("DD-MM-YYYY HH:mm:ss")
          .split(" ")[0];
        date = Moment(date).format("DD-MM-YYYY HH:mm:ss").split(" ");
        html.push(
          <div className={stylediv}>
            <div style={{ textAlign: "center", color: "white" }}>
              {Moment(dateTime).format("DD-MM-YYYY HH:mm:ss").split(" ")[0] ==
              data ? (
                <></>
              ) : (
                !dateArray.includes(data) && data
              )}
            </div>
            <div className="chatting-inner">
              {value.queryImage != "" &&
                value.queryImage != undefined &&
                value.queryImage != "NULL" && (
                  <div>
                    <a
                      href=""
                      data-toggle="modal"
                      data-target="#supportImageModel"
                      //   onClick={() => {
                      //     setImage(profilePic);
                      //   }}
                    >
                      <img src={profilePic} alt="" />
                    </a>
                    <div></div>
                  </div>
                )}
              {value.replyBy == 1 ? (
                <div className="msg-outer user-msg">
                  <div>{value.query}</div>
                  <div className="msg-time">{date[1]}</div>
                </div>
              ) : (
                <div className="msg-outer">
                  <div dangerouslySetInnerHTML={{ __html: value.query }} />
                  <div className="msg-time">{date[1]}</div>
                </div>
              )}
            </div>
          </div>
        );
        !dateArray.includes(data) && dateArray.push(data);
      });
    }
    return html;
    // }
  };

  const TicketList = (event) => {
    var decodes = atob(tichekId);

    var userIdData = { ticketId: decodes };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/helplist-by-user-ticket", userIdData, config)
      .then((res) => {
        var respNew1 = res.data;

        GetUserData();
        if (respNew1.status === true) {
          setTabledata1(respNew1.data);
          setIssueText(respNew1.data[0].issueType);
          setIssueImageSecond(respNew1.data[0].issueImage);
          setDateTime(respNew1.data[0].createAt);
          setResponseData(respNew1.data[0].status);
          setSubject(respNew1.data[0].issue);
        }
        if (respNew1.data[0].status == 1) {
          setOpenSteps("Chat_panel");
          setOpenStepsAll(false);
        }
      });
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="new_inpage">
          <div className="container container2">
            <div className="profile_left_box bg_light">
              <h4 className="mb-2">Chatting</h4>
              <div className="subject-status-outer d-flex flex-wrap justify-content-between row">
                <h6 className="col-sm-6">Subject: {subject}</h6>
                <h6 className="status-outer col-sm-6 text-sm-right">
                  Status :{" "}
                  {ResponseData == 0 ? (
                    <i
                      className="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "red" }}
                    >
                      {ResponseData == 0 && "Pending"}
                    </i>
                  ) : (
                    <i
                      className="fa fa-check-circle-o"
                      style={{ color: "green" }}
                      aria-hidden="green"
                    >
                      Resolved
                    </i>
                  )}{" "}
                </h6>
              </div>

              <div className="row">
                {OpenSteps == "Chat_panel" || OpenStepsAll == "all" ? (
                  <>
                    <div className="col-md-6">
                      {tabledata1.length != 0 && (
                        <div className="bg_white22 chating-outer">
                          <div className="left_chat_scrool">
                            <div className="charting-part right_side_div">
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {
                                  Moment(dateTime)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                    .split(" ")[0]
                                }
                              </div>

                              <div className="chatting-inner">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: issueText,
                                  }}
                                />

                                {issueImageSecond != "" &&
                                  issueImageSecond != undefined &&
                                  issueImageSecond != "NULL" && (
                                    <div className="msg-outer image-msg user-img-msg">
                                      <a
                                        href="#e"
                                        data-toggle="modal"
                                        data-target="#supportImageModel"
                                        // onClick={() => {
                                        //   setImage(
                                        //     baseUrl +
                                        //       "/static/helpIssue/" +
                                        //       issueImageSecond
                                        //   );
                                        // }}
                                      >
                                        <img
                                          src={
                                            baseUrl +
                                            "/static/helpIssue/" +
                                            issueImageSecond
                                          }
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  )}
                                <div className="msg-time">
                                  {
                                    Moment(dateTime)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                      .split(" ")[1]
                                  }
                                </div>
                              </div>
                            </div>
                            {showTableHtml()}

                            <div className=""></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {OpenSteps == "Send_Message" || OpenStepsAll == "all" ? (
                  <>
                    <div className="col-md-6">
                      <div className="bg_white22  chating-outer">
                        <h5 className="mb-3">Send Your Message Form Here:</h5>
                        <textarea
                          name="query"
                          value={query}
                          onChange={onChangeIssue}
                          className="form-control form_control_textarea"
                          placeholder="Enter Message"
                          id="query"
                        />
                        <p style={{ color: "red" }}>{queryIssue}</p>
                        {/* try new */}
                        <div className="form-group">
                          <div className=" col-md-6">
                            <label className="fw-500">Attachment</label>

                            <div className="uploader">
                              <input
                                className="profile_input"
                                type="file"
                                id="file-upload"
                                name="queryImage"
                                accept="image/*"
                                onChange={onChangeImage}
                              />
                              <label for="file-upload" id="file-drag">
                                <img
                                  src={
                                    userUploadImage !== false
                                      ? userUploadImage
                                      : verifyidpic
                                  }
                                  className="img-fluid22 support-img"
                                  alt="Identity"
                                />
                                <div id="start">
                                  <span className="fasi"></span>
                                  <div>Upload image</div>
                                  <div id="notimage" className="hidden">
                                    Please select an image
                                  </div>
                                </div>
                                <div id="response" className="hidden">
                                  <div id="messages"></div>
                                  <progress
                                    className="progress"
                                    id="file-progress"
                                    value="0"
                                  >
                                    <span>0</span>%
                                  </progress>
                                </div>
                              </label>

                              <p style={{ color: "red" }}>{imageError}</p>
                            </div>
                          </div>
                        </div>
                        {/* end new */}
                        <div className="tr">
                          <button
                            onClick={chatSubmit}
                            className="btn btn_man  w100px"
                            disabled={!btnDisable ? "disabled" : ""}
                          >
                            Submit
                          </button>
                        </div>{" "}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  className="modal fade supportImageModel"
                  id="supportImageModel"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body p-0">
                        <img class="w100" src={Image} alt="noimg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer limit={1} />
      </div>
    </DocumentMeta>
  );
};

export default ChattingFunction;
