import React from "react";
import RegisterForm from "./RegisterElement/RegisterForm";
import Footer from "../Common/Footer";
import { ToastContainer } from "react-toastify";
import MainHeader from "../Common/MainHeader";
import RegisterFunction from "./RegisterElement/RegisterFunction";

const Register = (props) => {
  return (
    <div>
      <MainHeader />
      {/* <RegisterForm referId={this.props.match.params.id} /> */}
      <RegisterFunction referId={props.match.params.id} />
      <ToastContainer limit={1} autoClose={5000} />
    </div>
  );
};

export default Register;
