import React, { useEffect, useState, useSyncExternalStore } from "react";
import LoginHeader from "../Common/LoginHeader";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";
import { ImageValidation } from "../Validation/ImageValidation";
const baseUrl = myConstList.baseUrl;

const AccountFunction = () => {
  const meta = {
    title: `Crypto Wallet Balance | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const [isDepositModelOpen, setIsDepositModelOpen] = useState(false);
  const [isWithdrawalModelOpen, setIsWithdrawalModelOpen] = useState(false);
  const [disabledInrDeposit, setDisabledInrDeposit] = useState(false);
  const [Withdraw_address, setWithdraw_address] = useState("");
  const [isTransferModelOpen, setIsTransferModelOpen] = useState(false);
  const [isINRDepositModelOpen, setIsINRDepositModelOpen] = useState(false);
  const [depositeInrBtn, setDepositeInrBtn] = useState("block");
  const [disableTransferButton, setDisableTransferButton] = useState(false);
  const [disabledInrWithdrawal, setdisabledInrWithdrawal] = useState(false);
  const [disabledCoinWithdrawal, setDisabledCoinWithdrawal] = useState(false);
  const [getCoinName_withdrawl, setGetCoinName_withdrawl] = useState(false);
  const [isGoing, setIsGoing] = useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [walletBalanceAmount, setWalletBalanceAmount] = useState(0);
  const [balanceOfAmount, setBalanceOfAmount] = useState(0);
  const [balanceOfAmountSpot, setBalanceOfAmountSpot] = useState(0);
  const [spotBalance, setSpotBalance] = useState(0);
  const [whitelist_Data, setWhitelist_Data] = useState([]);
  const [getAmount, setGetAmount] = useState("");
  const [amount, setAmount] = useState(0);
  const [usdtPortfolio, setUsdtPortfolio] = useState(0);
  const [inrPortfolio, setInrPortfolio] = useState(0);
  const [withdraw_amount, setWithdraw_amount] = useState("");

  const [kycStatus, setKycStatus] = useState("");
  const [adminbankdetails, setAdminbankdetails] = useState([]);
  const [inr_amount, setInr_amount] = useState("");

  const [transactionIdError, setTransactionIdError] = useState("");
  const [inrDepositeError, setInrDepositeError] = useState("");
  const [transactionImageError, setTransactionImageError] = useState("");
  const [isWhitelistModalOpen, setIsWhitelistModalOpen] = useState(false);
  const [amountErr, setAmountErr] = useState("");
  const [fees, setFees] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [type, setType] = useState("");
  const [percentValue, setPercentValue] = useState("");
  const [inr_amountWithdrawl, setInr_amountWithdrawl] = useState("");
  const [wallet_address, setWallet_address] = useState("");
  const [Currency_name, setCurrency_name] = useState("");
  const [currency_id, setCurrency_id] = useState("");
  const [wallet_currency_amount, setWallet_currency_amount] = useState("");
  const [walletErr, setWalletErr] = useState("");
  const [transferCoinName, setTransferCoinName] = useState("");
  const [transferCoinId, setTransferCoinId] = useState("");
  const [fromWallet, setFromWallet] = useState("");
  const [toWallet, setToWallet] = useState("");
  const [coinAmount, setCoinAmount] = useState("");
  const [coinAmount1, setCoinAmount1] = useState("");
  const [Status, setStatus] = useState(false);
  const [tableData1, setTableData1] = useState([]);
  const [IdentityPic3, setIdentityPic3] = useState(false);
  const [trNumber, setTrNumber] = useState("");
  const [confirmInrBtn, setConfirmInrBtn] = useState("none");
  const [useruploadInrImage, setUseruploadInrImage] = useState("");
  const [inrImage, setInrImage] = useState("/assets/img/icon2.png");
  const [showCurrName, setShowCurrName] = useState("");
  const [chainType, setChainType] = useState("");

  let BnbInterval = null;
  let EthInterval = null;
  let TrxInterval = null;
  let BtcInterval = null;
  // let SPELLInterval = null;
  let SolInterval = null;
  let UsdtInterval = null;
  let ShibInterval = null;
  let ManaInterval = null;
  let MaticInterval = null;
  let DogeInterval = null;
  let BttcInterval = null;
  let XrpInterval = null;
  let EtcInterval = null;
  let DotInterval = null;
  let LinkInterval = null;
  let CHRInterval = null;
  let SANDInterval = null;
  let AXSInterval = null;
  let ADAInterval = null;
  let AAVEInterval = null;
  let CHZInterval = null;
  let C98Interval = null;
  let COMPInterval = null;
  let ATOMInterval = null;
  let VETInterval = null;
  let AVAXInterval = null;
  let VENCInterval = null;

  const BtcAddressQr =
    // "http://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl="
    // "https://qrcode.tec-it.com/API/QRCode?data="
    "https://image-charts.com/chart?chs=150x150&cht=qr&chl=" + wallet_address;
  const BTCencoded = encodeURI(BtcAddressQr);

  useEffect(() => {
    GetUserData();
    WalletAllCoinBalance();

    portfolioData();
    adminbankinfo();
    timerId();
    timerId1();
    timerId2();
    // timerId3();.
    // timerId4(); // SPELL
    timerId5();
    timerId6();
    timerId7();
    timerId8();
    timerId9();
    timerId10();
    timerId11();
    timerId12();
    timerId13();
    timerId14();
    timerId15();
    timerId16();
    timerId17(); // AVAX
    timerId18();
    timerId19();
    timerId20();
    timerId21();
    timerId22(); // CHZ
    timerId23();
    timerId24();
    timerId25();
    timerId26();
    timerId27();
  }, []);

  const timerId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-BNB", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!BnbInterval) {
        BnbInterval = setInterval(() => {
          timerId();
        }, 600000);
      }
    });
  };

  const timerId1 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ETH", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!EthInterval) {
        EthInterval = setInterval(() => {
          timerId1();
        }, 600000);
      }
    });
  };

  const timerId2 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-TRX", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }

      if (!TrxInterval) {
        TrxInterval = setInterval(() => {
          timerId2();
        }, 600000);
      }
    });
  };

  const timerId3 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-BTC", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!BtcInterval) {
        BtcInterval = setInterval(() => {
          timerId3();
        }, 600000);
      }
    });
  };

  // const timerId4 = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };
  //   axios.post(baseUrl + "/frontapi/deposit-SPELL", {}, config).then((res) => {
  //     var resp = res.data;
  //     if (resp.status === true) {
  //       WalletAllCoinBalance();
  //     }
  //     if (!SPELLInterval) {
  //       SPELLInterval = setInterval(() => {
  //         timerId4();
  //       }, 600000);
  //     }
  //   });
  // };

  const timerId5 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-SOL", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!SolInterval) {
        SolInterval = setInterval(() => {
          timerId5();
        }, 600000);
      }
    });
  };

  const timerId6 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-USDT", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!UsdtInterval) {
        UsdtInterval = setInterval(() => {
          timerId6();
        }, 600000);
      }
    });
  };

  const timerId7 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-SHIB", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!ShibInterval) {
        ShibInterval = setInterval(() => {
          timerId7();
        }, 600000);
      }
    });
  };

  const timerId8 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-MANA", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!ManaInterval) {
        ManaInterval = setInterval(() => {
          timerId8();
        }, 600000);
      }
    });
  };

  const timerId9 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-MATIC", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!MaticInterval) {
        MaticInterval = setInterval(() => {
          timerId9();
        }, 600000);
      }
    });
  };

  const timerId10 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-DOGE", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!DogeInterval) {
        DogeInterval = setInterval(() => {
          timerId10();
        }, 600000);
      }
    });
  };

  const timerId11 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-BTTC", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!BttcInterval) {
        BttcInterval = setInterval(() => {
          timerId11();
        }, 600000);
      }
    });
  };

  const timerId12 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-XRP", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!XrpInterval) {
        XrpInterval = setInterval(() => {
          timerId12();
        }, 600000);
      }
    });
  };

  const timerId13 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-CHR", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!CHRInterval) {
        CHRInterval = setInterval(() => {
          timerId13();
        }, 600000);
      }
    });
  };

  const timerId14 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ETC", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!EtcInterval) {
        EtcInterval = setInterval(() => {
          timerId14();
        }, 600000);
      }
    });
  };

  const timerId15 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-DOT", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!DotInterval) {
        DotInterval = setInterval(() => {
          timerId15();
        }, 600000);
      }
    });
  };

  const timerId16 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-LINK", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!LinkInterval) {
        LinkInterval = setInterval(() => {
          timerId16();
        }, 600000);
      }
    });
  };

  const timerId17 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-AVAX", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!AVAXInterval) {
        AVAXInterval = setInterval(() => {
          timerId17();
        }, 600000);
      }
    });
  };

  const timerId18 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-SAND", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!SANDInterval) {
        SANDInterval = setInterval(() => {
          timerId18();
        }, 600000);
      }
    });
  };

  const timerId19 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-AXS", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!AXSInterval) {
        AXSInterval = setInterval(() => {
          timerId19();
        }, 600000);
      }
    });
  };

  const timerId20 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ADA", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!ADAInterval) {
        ADAInterval = setInterval(() => {
          timerId20();
        }, 600000);
      }
    });
  };

  const timerId21 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-AAVE", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!AAVEInterval) {
        AAVEInterval = setInterval(() => {
          timerId21();
        }, 600000);
      }
    });
  };

  const timerId22 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-CHZ", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!CHZInterval) {
        CHZInterval = setInterval(() => {
          timerId22();
        }, 600000);
      }
    });
  };

  const timerId23 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-C98", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!C98Interval) {
        C98Interval = setInterval(() => {
          timerId23();
        }, 600000);
      }
    });
  };

  const timerId24 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-COMP", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!COMPInterval) {
        COMPInterval = setInterval(() => {
          timerId24();
        }, 600000);
      }
    });
  };

  const timerId25 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ATOM", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!ATOMInterval) {
        ATOMInterval = setInterval(() => {
          timerId25();
        }, 600000);
      }
    });
  };

  const timerId26 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-VET", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!VETInterval) {
        VETInterval = setInterval(() => {
          timerId26();
        }, 600000);
      }
    });
  };

  const timerId27 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-VENX", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        WalletAllCoinBalance();
      }
      if (!VENCInterval) {
        VENCInterval = setInterval(() => {
          timerId26();
        }, 600000);
      }
    });
  };

  const closeOpenINRDepositModal = () => {
    setIsINRDepositModelOpen(false);
    setUseruploadInrImage("");
    setConfirmInrBtn("none");
    setDepositeInrBtn("block");
    setTrNumber("");
    setInr_amount("");
    setDisabledInrDeposit(false);
    setTransactionIdError("");
    setTransactionImageError("");
    setInrDepositeError("");
  };

  const adminbankinfo = () => {
    axios.get(baseUrl + "/frontapi/adminbankinfo").then((res) => {
      var resp = res.data;
      setAdminbankdetails(resp.data[0]);
    });
  };

  const portfolioData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/walletTotalBalance", {}, config)
      .then((res) => {
        var resp = res.data;
        setUsdtPortfolio(resp.data[0].USDT);
        setInrPortfolio(resp.data[0].INR);
      });
  };

  const closeKycModal = () => {
    setIsKycModalOpen(false);
  };
  const closeWhitelistModal = () => {
    setIsWhitelistModalOpen(false);
  };

  const inrWithdrawalSubmit = (event) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (!inr_amountWithdrawl) {
      toast.dismiss();
      setAmountErr("Enter amount");
      return false;
    }
    if (inr_amountWithdrawl <= 0) {
      toast.dismiss();
      setAmountErr("Please enter valid amount");
      return false;
    }

    if (inr_amountWithdrawl < min) {
      toast.dismiss();
      setAmountErr(`You can't withdrawal less than ${min}`);
      return false;
    }
    if (inr_amountWithdrawl > max) {
      toast.dismiss();
      setAmountErr(`You can't withdrawal more than ${max}`);
      return false;
    }

    const dataSubmit = {
      amount: inr_amountWithdrawl,
      withdrawFees: fees,
      type: type,
      getAmount: getAmount,
      percentValue: percentValue,
    };

    setdisabledInrWithdrawal(true);

    axios
      .post(baseUrl + "/frontapi/inr_Withdrawal", dataSubmit, config)
      .then((res) => {
        var resp = res.data;

        setInr_amountWithdrawl("");
        setGetAmount("");
        setPercentValue("");
        setIsWithdrawalModelOpen(false);
        setGetCoinName_withdrawl(false);

        if (resp.status === false) {
          setdisabledInrWithdrawal(false);
          toast.dismiss();
          toast.error(resp.message);
        } else {
          WalletAllCoinBalance();
          setIsWithdrawalModelOpen(false);
          setdisabledInrWithdrawal(false);

          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  const OpenDepositModel = (getCoinName, show_wallet_address, chain_type) => {
    if (kycStatus === "N") {
      toast.error("Please complete your kyc first");
      return false;
    }

    // if (this.state.kycStatus !== "Y") {
    //   setIsKycModalOpen(true);
    //   return false;
    // }
    setShowCurrName(getCoinName);
    setChainType(chain_type);

    if (getCoinName === "INR") {
      setIsINRDepositModelOpen(true);
      setWallet_address(show_wallet_address);
    } else {
      setIsDepositModelOpen(true);
      setWallet_address(show_wallet_address);
    }
  };

  const closeOpenDepositModal = () => {
    setCopySuccess("");
    setShowCurrName("");
    setChainType("");
    setIsDepositModelOpen(false);
  };

  const OpenWithdrawalModel = (
    Currency_name,
    wallet_currency_amount,
    currency_id,
    fees,
    min,
    max,
    type
  ) => {
    if (kycStatus === "N") {
      toast.error("Please complete your kyc first");
      return false;
    }

    if (currency_id == 36) {
      kycStatus === "N" || kycStatus === undefined || kycStatus === null
        ? setIsWithdrawalModelOpen(false)
        : setIsWithdrawalModelOpen(true);

      setGetCoinName_withdrawl(true);
    }

    setIsDepositModelOpen(false);
    setIsWithdrawalModelOpen(true);
    setWhitelist_Data([]);

    setCurrency_name(Currency_name);
    setWallet_currency_amount(wallet_currency_amount);
    setCurrency_id(currency_id);
    setFees(fees);
    setMin(min);
    setMax(max);
    setType(type);

    let LoginData = {
      coin_id: currency_id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/whitelistDataById", LoginData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setWhitelist_Data(resp.data);
          setWithdraw_address(resp.data[0].whitelistAddress);
        } else {
          setWhitelist_Data([]);
          setWithdraw_address(resp.data[0].whitelistAddress);
        }
      });
  };

  const autoAmount = (e) => {
    if (balanceOfAmount) {
      setAmount(balanceOfAmount);
    } else {
      setAmount("");
    }
  };

  const closeWithdrawalModal = () => {
    setIsWithdrawalModelOpen(false);
    setGetCoinName_withdrawl(false);
    setdisabledInrWithdrawal(false);
    setPercentValue("");
    setInr_amountWithdrawl("");
    setGetAmount("");
    setWithdraw_amount("");
    setWithdraw_address("");
    setAmountErr("");
    setWalletErr("");
  };

  const OpenTransferModel = (
    transferCoinName,
    transferCoinId,
    spotBalance,
    walletBalance
  ) => {
    setIsTransferModelOpen(true);
    setTransferCoinName(transferCoinName);
    setTransferCoinId(transferCoinId);
    setWalletBalanceAmount(walletBalance);
    setSpotBalance(spotBalance);
    setAmount("");
  };

  const closeTransferModal = () => {
    setIsTransferModelOpen(false);
    setBalanceOfAmount(0);
    setBalanceOfAmountSpot(0);
    setFromWallet("");
    setToWallet("");
    setAmount("");
  };

  const exchangeValue = () => {
    setBalanceOfAmount(balanceOfAmountSpot);
    setBalanceOfAmountSpot(balanceOfAmount);
    setFromWallet(toWallet);
    setToWallet(fromWallet);
    setCoinAmount("");
    setCoinAmount1("");
  };

  const copyCodeToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = wallet_address;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopySuccess(true);
  };

  const WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((res) => {
        var resp = res.data;

        setStatus(resp.status);
        setTableData1(resp.data);
      });
  };

  const GetUserData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      setKycStatus(resp.data[0].kycVerification_status);
    });
  };

  const withdrawSubmit = (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const dataSubmit = {
      Wamount: withdraw_amount,
      coinList: currency_id,
      walletAddress: Withdraw_address,
      withdrawFees: fees,
      type: type,
      getAmount: getAmount,
      percentValue: percentValue,
    };

    if (!withdraw_amount) {
      setAmountErr("Amount field is required");
      toast.dismiss();
      return false;
    }

    if (!Withdraw_address) {
      setWalletErr("Wallet address is required");
      return false;
    } else {
      setWalletErr("");
    }

    if (withdraw_amount <= 0) {
      toast.dismiss();
      setAmountErr("Please enter valid amount");
      return false;
    }

    if (wallet_currency_amount < withdraw_amount) {
      toast.dismiss();
      toast.error("Insufficient Balance!");
      return false;
    }
    if (withdraw_amount < min) {
      toast.dismiss();
      toast.error(`You can't withdraw less than ${min}`);
      return false;
    }

    if (withdraw_amount > max) {
      toast.dismiss();
      toast.error(`You can't withdraw more than ${max}`);
      return false;
    }

    setDisabledCoinWithdrawal(true);

    setTimeout(() => setDisabledCoinWithdrawal(false), 1000);

    axios
      .post(baseUrl + "/frontapi/Wallet_Balance", dataSubmit, config)
      .then((res) => {
        var resp = res.data;

        setWithdraw_amount("");
        // setWithdraw_address("");
        setPercentValue("");
        setGetAmount("");
        setIsWhitelistModalOpen(false);

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);

          if (resp.message == "Technical error,please try again") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          setIsWithdrawalModelOpen(false);
          WalletAllCoinBalance();
          portfolioData();
          setPercentValue("");
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  const calculateCheckSum = (event) => {
    event.preventDefault();

    if (transactionImageError || inrDepositeError || transactionIdError) {
      return false;
    }
    if (!IdentityPic3 && !trNumber && !inr_amount) {
      setTransactionImageError("Transaction image is required");
      setInrDepositeError("Deposit amount field is required");
      setTransactionIdError("Transaction id is required");
      return false;
    }

    if (!inr_amount) {
      setInrDepositeError("Deposit amount field is required");
      return false;
    }

    if (inr_amount <= 0) {
      setInrDepositeError("Please enter valid deposit amount");
      return false;
    }

    if (!IdentityPic3) {
      setTransactionImageError("Transaction image is required");
      return false;
    }

    let error = ImageValidation("Inr", IdentityPic3);
    setTransactionImageError(error);

    if (error) {
      return false;
    }

    if (!trNumber) {
      setTransactionIdError("Transaction id is required");
      return false;
    }

    const pattern = /^\w+$/;

    if (!pattern.test(trNumber)) {
      setTransactionIdError("Please enter valid transaction id");
      return false;
    }

    if (trNumber.length < 8) {
      setTransactionIdError("Please enter valid transaction id");
      return false;
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var formData = new FormData();
    formData.append("tx_image", IdentityPic3);
    formData.append("utrId", trNumber);
    formData.append("amount", inr_amount);

    setDisabledInrDeposit(true);

    axios
      .post(baseUrl + "/frontapi/inr_request", formData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          closeOpenINRDepositModal();
          setTrNumber("");
          setInr_amount("");
          setIdentityPic3("");
          setIsINRDepositModelOpen(false);
        }
        if (resp.status === false) {
          setTimeout(() => setDisabledInrDeposit(false), 1000);
          toast.error(resp.message);
          setTrNumber("");
          setInr_amount("");
          setIdentityPic3("");
          setIsINRDepositModelOpen(false);
        }
      });
  };

  const showTableHtml = () => {
    if (Status === true) {
      const html = [];

      tableData1.map(function (value, i) {
        if (value.getValue.main[0].coin_amount === null) {
          var getID = value.coin_id;
          var walletAmount = 0;
        } else {
          walletAmount = value.getValue.main[0].coin_amount;
          getID = value.coin_id;
        }
        if (value.getValue.spot[0].coin_amount === null) {
          getID = value.coin_id;
          var spotAmount = 0;
        } else {
          spotAmount = value.getValue.spot[0].coin_amount;
          getID = value.coin_id;
        }
        var myLink = value.coin_tron;
        var coinName = value.name;
        var chain_type = value.chain_type;

        if (value.fees == undefined || value.fees.length == 0) {
          var fees = 0;
          var min = 0;
          var type = "";
        } else {
          fees = value.fees[0].commission;
          var max = value.fees[0].max_withdrawal;
          min = value.fees[0].min_withdrawal;
          type = value.fees[0].type;
        }
        var getWalletAddress = value.getValue.WalletAddress;
        if (isGoing == false) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}

                {/* <a target="_blank" rel="noreferrer" href={myLink}> */}
                {value.name}
                {/* </a> */}
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>

              <td>{walletAmount}</td>
              <td>{spotAmount}</td>
              <td>
                <div className="four_btn">
                  {
                    <button
                      className="btn btn_man fs14 mr-3"
                      disabled={value.idDeposit == 0 ? true : false}
                      onClick={() =>
                        OpenDepositModel(coinName, getWalletAddress, chain_type)
                      }
                    >
                      Deposit
                    </button>
                  }

                  {!fees ? (
                    <span>
                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  ) : (
                    <span>
                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount
                      )
                    }
                  >
                    Transfer
                  </button>
                </div>
              </td>
            </tr>
          );
          return html;
        }
        if (
          (isGoing == true &&
            value.getValue.main[0].coin_amount !== null &&
            value.getValue.main[0].coin_amount != 0) ||
          (value.getValue.spot[0].coin_amount !== null &&
            value.getValue.spot[0].coin_amount != 0)
        ) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}
                <a target="_blank" rel="noreferrer" href={myLink}>
                  {value.name}
                </a>
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>
              <td>{walletAmount}</td>
              <td>{spotAmount}</td>

              <td>
                <div className="four_btn">
                  {
                    <button
                      className="btn btn_man fs14 mr-3"
                      disabled={value.idDeposit == 0 ? true : false}
                      onClick={() =>
                        OpenDepositModel(coinName, getWalletAddress, chain_type)
                      }
                    >
                      Deposit
                    </button>
                  }

                  {!fees ? (
                    <span>
                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  ) : (
                    <span>
                      {
                        <button
                          className="btn btn_man fs14 mr-3"
                          disabled={value.isWithdrawal == 0 ? true : false}
                          onClick={() =>
                            OpenWithdrawalModel(
                              value.name,
                              value.getValue.main[0].coin_amount,
                              getID,
                              fees,
                              min,
                              max,
                              type
                            )
                          }
                        >
                          Withdrawal
                        </button>
                      }
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount
                      )
                    }
                  >
                    Transfer
                  </button>
                </div>
              </td>
            </tr>
          );
          return html;
        }
      });
      return <tbody className="main">{html}</tbody>;
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    // if (name !== "amount") {
    //   setAmount(value);
    // }

    if (value > 0) {
      setAmountErr("");
    }

    if (name === "amount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      if (!newValue || newValue == 0) {
        setAmount(newValue);
        return false;
      }
      setAmount(newValue);
    }

    if (name === "fromWallet") {
      setFromWallet(value);
      if (event.target.value === "wallet") {
        setBalanceOfAmount(walletBalanceAmount);
        setBalanceOfAmountSpot(spotBalance);
        setToWallet("transactions");
      }
      if (event.target.value === "transactions") {
        setBalanceOfAmount(spotBalance);
        setBalanceOfAmountSpot(walletBalanceAmount);
        setToWallet("wallet");
      }
    }
    if (name === "toWallet") {
      setToWallet(value);
      if (event.target.value === "wallet") {
        setBalanceOfAmountSpot(walletBalanceAmount);
        setBalanceOfAmount(spotBalance);
        setFromWallet("transactions");
      }
      if (event.target.value === "transactions") {
        setBalanceOfAmountSpot(spotBalance);
        setBalanceOfAmount(walletBalanceAmount);
        setFromWallet("wallet");
      }
    }

    if (name === "withdraw_amount" || name === "inr_amountWithdrawl") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      setInr_amountWithdrawl(newValue);
      setWithdraw_amount(newValue);

      setGetAmount("");
      setPercentValue("");
      if (!newValue) {
        setAmountErr("Please enter amount required");
        return false;
      }

      if (newValue <= 0) {
        var withdrawalError = "Please enter valid amount";
        setAmountErr(withdrawalError);
        return false;
      }

      if (newValue <= parseFloat(fees)) {
        setAmountErr("Amount should be greater than withdrawal fees");
        return false;
      }

      setAmountErr("");

      // const amountFees =
      //   parseFloat(newValue) * (100 / (100 + parseFloat(fees)));

      const amountFees = parseFloat(fees);

      // var percentValue = (parseFloat(newValue) * parseFloat(fees)) / 100;
      const calculatedValue = parseFloat(newValue) - parseFloat(amountFees);
      setPercentValue(amountFees);
      setGetAmount(calculatedValue);
    }
  };

  const handleInrChange = (event) => {
    let { name, value } = event.target;
    if (name === "inr_amount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      setInr_amount(newValue);
      if (!value) {
        setInrDepositeError("Deposit amount field is required");
      } else {
        setInrDepositeError("");
      }
    }
    if (name === "trNumber") {
      setTrNumber(value);
      if (!value) {
        setTransactionIdError("Transaction id is required");
      } else {
        setTransactionIdError("");
      }
    }
    setDepositeInrBtn("block");
  };

  const handleChange1 = (event) => {
    let { name, value } = event.target;

    if (name === "withdraw_amount") {
      setWithdraw_address(value);
      if (!value) {
        setWalletErr("Wallet address is required");
      } else {
        setWalletErr("");
      }
    }
  };

  const handleCheckChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setIsGoing(value);
  };

  const handleTransfer = (event) => {
    event.preventDefault();
    if (!fromWallet || !toWallet) {
      toast.error("Please select transfer wallet type");
      return false;
    }
    if (!amount) {
      toast.error("Amount field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      fromWallet: fromWallet,
      toWallet: toWallet,
      amount: amount,
      amountType: transferCoinId, //coin id
    };

    setDisableTransferButton(true);
    setTimeout(() => setDisableTransferButton(false), 1000);

    axios
      .post(baseUrl + "/frontapi/walletTransfer", LoginData, config)
      .then((res) => {
        var resp = res.data;

        setAmount("");
        setBalanceOfAmount(0);
        setBalanceOfAmountSpot(0);
        setFromWallet("");
        setToWallet("");

        if (resp.status === true) {
          WalletAllCoinBalance();
          portfolioData();
          toast.dismiss();
          toast.success(resp.message);
          setIsTransferModelOpen(false);
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.message);
          return false;
        }
      });
  };

  const changeInrValue = (value) => {
    if (value) {
      setInr_amount(value);
      setInrDepositeError("");
    }
  };
  const onChangeImage3 = (e) => {
    e.preventDefault();
    var transactionImageError = "";
    var IdentityPic3 = e.target.files[0];
    setIdentityPic3(e.target.files[0]);

    // if (IdentityPic3 === false) {
    //   transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
    //   setTransactionImageError(transactionImageError);
    //   return false;
    // }
    // if (!IdentityPic3.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
    //   setTransactionImageError(transactionImageError);

    //   return false;
    // }
    // setTransactionImageError(transactionImageError);

    let error = ImageValidation("Inr", e.target.files[0]);
    setTransactionImageError(error);

    e.target.files[0]
      ? setUseruploadInrImage(URL.createObjectURL(e.target.files[0]))
      : setUseruploadInrImage("");
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div className="">
        <LoginHeader />

        {/* Deposit Modal Start */}
        <Modal
          show={isDepositModelOpen}
          onHide={closeOpenDepositModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title> Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mt-0 note">
              Please ensure to select {chainType} network at sender's wallet.
            </p>

            <div className="row align-items-center">
              <div className="col-sm-9">
                <label>Your wallet address:</label>
                <div className="link-address position-relative">
                  <div className="form-group mb-0">
                    <input
                      className="form-control fs12"
                      type="textarea"
                      readonly=""
                      name="wallet_address"
                      value={wallet_address}
                    />
                  </div>
                  <div className="copy-btn-outer ">
                    {copySuccess ? (
                      <div style={{ color: "green" }}>Address Copied!</div>
                    ) : null}
                    <button
                      type="button"
                      className="btn btn_man position-absolute copy_button"
                      onClick={() => copyCodeToClipboard()}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="tc">
                  <img src={BTCencoded} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="disclaimer-main border-top w-100">
                <div className="disclaimer-outer p-3">
                  <h6>
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>{" "}
                    Disclaimer
                  </h6>
                  <ul>
                    <li>
                      Send only using the {chainType} network. Using any other
                      network will result in loss of funds.
                    </li>
                    <li>
                      Deposit only {showCurrName} to this deposit address.
                      Depositing any other asset will result in a loss of funds.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Deposit Modal End */}

        {/* Withdrawal Modal Start */}
        <Modal
          show={isWithdrawalModelOpen}
          onHide={closeWithdrawalModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title> Withdrawal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="wallet-balance form-group row">
              <div className="col-12 mb-3">
                <span className="mb-0 form-control d-flex">
                  {" "}
                  Wallet Balance:{" "}
                  <b className="ml-auto">
                    {wallet_currency_amount} {Currency_name}{" "}
                  </b>
                </span>
              </div>

              {/* {min} */}

              {/* {min && ( */}
              <div className="col-6">
                <span className="form-control d-flex">
                  Min Withdrawal: <b className="ml-auto">{min}</b>
                </span>
              </div>
              {/* )} */}

              <div className=" col-6">
                <span className="form-control d-flex">
                  Withdrawal Fees:{" "}
                  <b className="ml-auto">
                    {fees
                      ? Number.isInteger(fees)
                        ? fees
                        : parseFloat(fees).toFixed(4)
                      : 0}{" "}
                    {type == "flat" ? "(Flat)" : "(Flat)"}{" "}
                    {/* {type == "flat" ? "(Flat)" : "(%)"}{" "} */}
                  </b>
                </span>
              </div>
            </div>
            {getCoinName_withdrawl ? (
              <div className="form-group">
                <label className="mb-0">Amount</label>
                <div className="relative">
                  <input
                    type="number"
                    className="form-control"
                    name="inr_amountWithdrawl"
                    value={inr_amountWithdrawl}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                    onKeyDown={handleValidation}
                  />
                  <span className="absolute_btc"> {Currency_name}</span>
                  <span style={{ color: "red" }}>{amountErr && amountErr}</span>
                  <div className="show">
                    {inr_amountWithdrawl > 0 && getAmount
                      ? "You will get: " + parseFloat(getAmount.toFixed(3))
                      : ""}
                  </div>
                  <div className="show">
                    {percentValue
                      ? "Fees Amount: " + parseFloat(percentValue.toFixed(5))
                      : ""}
                  </div>
                </div>
                <div className="tc mt-3">
                  <button
                    className="btn btn_man fs14"
                    onClick={inrWithdrawalSubmit}
                    disabled={disabledInrWithdrawal}
                    value="Send Request"
                  >
                    Send Request
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="form-group">
                  <label className="mb-0">Coin</label>
                  <div className="form-control ">{Currency_name}</div>{" "}
                </div>
                <div className="form-group ">
                  <label className="mb-0">Amount</label>

                  <div className="relative">
                    <input
                      type="number"
                      // value="0"
                      className="form-control"
                      name="withdraw_amount"
                      value={withdraw_amount}
                      onChange={handleChange}
                      onKeyDown={handleValidation}
                      placeholder="Enter Amount"
                    />

                    <span className="absolute_btc"> {Currency_name}</span>
                    <div className="show">
                      <span style={{ color: "red" }}>
                        {amountErr && amountErr}
                      </span>
                      {withdraw_amount > 0 && getAmount
                        ? "You will get: " + parseFloat(getAmount).toFixed(3)
                        : ""}
                    </div>
                    <div className="show">
                      {percentValue
                        ? "Fees Amount: " + parseFloat(percentValue).toFixed(5)
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="relative">
                    <div className="form-group ">
                      <label className="mb-0">Address</label>

                      <input
                        type={"text"}
                        className="form-control"
                        name="Withdraw_address"
                        value={Withdraw_address}
                        onChange={handleChange1}
                        placeholder="Enter Wallet Address"
                        disabled
                      />

                      {/* //   <button */}
                      {/* //   className="absolute_btc max-btn btn btn_man fs14"
                    //   onClick={autoAmount}
                    // >
                    //   Max
                    // </button> */}

                      {whitelist_Data && whitelist_Data.length > 0 ? (
                        ""
                      ) : (
                        <button
                          className="absolute_btc max-btn btn btn_man fs14"
                          onClick={(e) => {
                            window.location.href = "/whitelist";
                          }}
                        >
                          Add Address
                        </button>
                      )}

                      {/* 
                    {whitelist_Data &&
                    whitelist_Data.length > 0 ? (
                      <input
                        type={"text"}
                        className="form-control"
                        name="Withdraw_address"
                        value={Withdraw_address}
                        onChange={handleChange1}
                        placeholder="Enter Wallet Address"
                        disabled
                      />
                    ) : (
                    //   <button
                    //   className="absolute_btc max-btn btn btn_man fs14"
                    //   onClick={autoAmount}
                    // >
                    //   Max
                    // </button>

                    
                      <button
                        className="absolute_btc max-btn btn btn_man fs14"
                        onClick={(e) => {
                          window.location.href = "/whitelist";
                        }}
                      >
                        Add Address
                      </button>
                    )} */}

                      {/* <div className="relative">{AddressListHtml()}</div> */}
                    </div>
                    {/* <input
                    type={"text"}
                    className="form-control"
                    name="Withdraw_address"
                    value={Withdraw_address}
                    onChange={handleChange1}
                    placeholder="Enter Wallet Address"
                    disabled
                  /> */}
                    <span style={{ color: "red" }}>
                      {walletErr && walletErr}
                    </span>
                  </div>
                </div>

                <div className="tc">
                  <button
                    className="btn btn_man fs14"
                    onClick={withdrawSubmit}
                    disabled={disabledCoinWithdrawal}
                  >
                    Withdraw Amount
                  </button>
                </div>
                <div className="disclaimer-main border-top w-100 pl-0 pr-0 mt-3">
                  <div className="disclaimer-outer">
                    <h6>
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Disclaimer
                    </h6>
                    <ul>
                      <li>
                        Send only using the {chainType} network. Using any other
                        network will result in loss of funds.
                      </li>
                      <li>
                        Deposit only {Currency_name} to this deposit address.
                        Depositing any other asset will result in a loss of
                        funds.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        {/* Withdrawal Modal End */}

        {/* Transfer Modal Start */}
        <Modal
          show={isTransferModelOpen}
          onHide={closeTransferModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title> Transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center mb-3">
              <div className="form-group col mb-0">
                <label className="mb-0 d-block">Sender </label>

                {toWallet == "wallet" ? (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="transactions">Spot</option>
                  </select>
                ) : toWallet == "transactions" ? (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="wallet">Wallet</option>
                  </select>
                ) : (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="transactions">Spot</option>
                    <option value="wallet">Wallet</option>
                  </select>
                )}
                <span> balance : {balanceOfAmount}</span>

                <small>{coinAmount}</small>
              </div>
              <div className="form-group col directions_35_OP pr-0 pl-0 mb-0">
                <span onClick={exchangeValue}>
                  <i className="las la-exchange-alt"></i>
                </span>
              </div>
              <div className="form-group col mb-0">
                <label className="mb-0 d-block">Receiver</label>

                {fromWallet == "wallet" ? (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="transactions">Spot</option>
                  </select>
                ) : fromWallet == "transactions" ? (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="wallet">Wallet</option>
                  </select>
                ) : (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="transactions">Spot</option>
                    <option value="wallet">Wallet</option>
                  </select>
                )}
                <span> balance : {balanceOfAmountSpot}</span>
                <small>
                  {coinAmount1}
                  {/* {coinName1} */}
                </small>
              </div>
            </div>
            <div className="form-group">
              <label className="mb-0">Coin</label>
              <div className="form-control">{transferCoinName}</div>{" "}
            </div>
            <div className="relative">
              <div className="form-group">
                <label className="mb-0">Amount</label>
                <input
                  // value="0"
                  step="any"
                  className="form-control"
                  name="amount"
                  value={amount}
                  onChange={handleChange}
                  placeholder="Enter Amount"
                  type="number"
                  onKeyDown={handleValidation}
                />
                <button
                  className="absolute_btc max-btn btn btn_man fs14"
                  onClick={autoAmount}
                >
                  Max
                </button>
              </div>
            </div>
            <div className="tc">
              <button
                className="btn btn_man fs14"
                onClick={handleTransfer}
                disabled={disableTransferButton}
              >
                Transfer
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Transfer Modal End */}

        {/* CoinList Html Part Start */}
        <div className="bghwhite">
          <div className="dashbord_manbox zero-balance-outer">
            <div className="container container2">
              <div className="dashbord_in1">
                <div className="dashbord_in ">
                  <div className="row align-items-center">
                    <div className="col-md-4 col-12">
                      <div className=" form-check">
                        <label className="form-check-label">
                          <input
                            name="isGoing"
                            type="checkbox"
                            checked={isGoing}
                            onChange={handleCheckChange}
                          />{" "}
                          Hide zero balances wallet
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="portfolio-amount d-flex justify-content-end align-items-center">
                        <p className="m-0">Portfolio :</p>

                        {/* END */}
                        <div className="amount-inner d-flex">
                          {/* <div className="amount-left">
                          <p className="mb-0">
                            <b>{parseFloat(inrPortfolio)}</b> INR{" "}
                          </p>
                        </div>{" "} */}
                          <div className="amount-right ml-2">
                            <p className="m-0">
                              <b> {parseFloat(usdtPortfolio)}</b> USDT
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="amount-tabel-main-outer">
                    <div className="in_table tc border_man">
                      <div className="table-responsive">
                        <table className="table mb-0 table_man table-striped">
                          <thead>
                            <tr>
                              <th className="tl">
                                <b>Coin</b>
                              </th>
                              <th>
                                <b>Main - Wallet</b>
                              </th>
                              <th>
                                <b>SPOT - Wallet</b>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          {showTableHtml()}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CoinList Html Part End */}

        {/* Deposit INR Modal Start */}
        <Modal
          show={isINRDepositModelOpen}
          onHide={closeOpenINRDepositModal}
          className="account-popup-outer"
        >
          <Modal.Header closeButton>
            <Modal.Title> Deposit INR</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>I want to deposit</label>
              <input
                type="number"
                // value="0"
                className="form-control"
                placeholder="Enter Amount"
                name="inr_amount"
                value={inr_amount}
                onChange={handleInrChange}
                onKeyDown={handleValidation}
              />
              <span style={{ color: "red" }}>{inrDepositeError}</span>
            </div>

            <div className="form-group">
              <div className="choose-amount d-flex justify-content-around">
                <button
                  // href="javascript:void(0)"
                  className="btn btn_man fs14 m0a"
                  onClick={() => changeInrValue("1000")}
                >
                  + 1000 INR
                </button>
                <button
                  // href="javascript:void(0)"
                  className="btn btn_man fs14 m0a"
                  onClick={() => changeInrValue("5000")}
                >
                  + 5000 INR
                </button>
                <button
                  // href="javascript:void(0)"
                  className="btn btn_man fs14 m0a"
                  onClick={() => changeInrValue("10000")}
                >
                  + 10000 INR
                </button>
              </div>
            </div>

            {/* <div className="form-group">
              <label>Upload Image:</label>
              <input
              className="form-control"
              type="file"
              id="file-upload3"
              name="IdentityProof3"
                onChange={onChangeImage3}
                />
              <span style={{ color: "red" }}>{transactionImageError}</span>
            </div> */}

            <div className="form-group">
              <label>Upload Image:</label>
              <div id="file-upload-form" className="uploader mt-3">
                <input
                  type="file"
                  // className="form-control"
                  id="file-upload3"
                  accept=".png, .jpg, .jpeg"
                  name="IdentityProof3"
                  onChange={onChangeImage3}
                />
                <label for="file-upload3" id="file-drag">
                  <img
                    src={
                      useruploadInrImage !== "" ? useruploadInrImage : inrImage
                    }
                    className="img-fluid22"
                    alt="Identity"
                  />
                  <div id="start">
                    <span className="fasi"></span>
                    <div>Upload Image</div>
                    <div id="notimage" className="hidden">
                      Please select an image
                    </div>
                  </div>
                  <div id="response" className="hidden">
                    <div id="messages"></div>
                    <progress className="progress" id="file-progress" value="0">
                      <span>0</span>%
                    </progress>
                  </div>
                </label>
              </div>
              <p className="mt-0">*only jpeg, jpg & png files supported</p>

              {transactionImageError && (
                <div className="text-danger">{transactionImageError}</div>
              )}
            </div>

            <div className="form-group">
              <label>UTR/TID:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Utr number / Transaction number"
                name="trNumber"
                value={trNumber}
                onChange={handleInrChange}
              />
              <span style={{ color: "red" }}>{transactionIdError}</span>
            </div>

            {/* <div className="choose-amount d-flex justify-content-around">
              <button
                // href="javascript:void(0)"
                className="btn btn_man fs14 m0a"
                onClick={() => changeInrValue("1000")}
              >
                + 1000 INR
              </button>
              <button
                // href="javascript:void(0)"
                className="btn btn_man fs14 m0a"
                onClick={() => changeInrValue("5000")}
              >
                + 5000 INR
              </button>
              <button
                // href="javascript:void(0)"
                className="btn btn_man fs14 m0a"
                onClick={() => changeInrValue("10000")}
              >
                + 10000 INR
              </button>
            </div> */}
            <div className="min-max-amount">
              <div className="row">
                <div className="col-md-8">
                  <div className="bank-detail bank-left">
                    <h5>Bank Details</h5>
                    <div className="bank-detail-outer border-right">
                      <p>
                        Bank Name :{" "}
                        {adminbankdetails ? adminbankdetails.bankname : ""}{" "}
                        <a href="javascript:void(0)">
                          <i
                            className="fa fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                adminbankdetails.bankname
                              );
                              toast.success("Copied");
                            }}
                          ></i>
                        </a>
                      </p>
                      <p>
                        Account Number :{" "}
                        {adminbankdetails ? adminbankdetails.accountnumber : ""}{" "}
                        <a href="javascript:void(0)">
                          <i
                            className="fa fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                adminbankdetails.accountnumber
                              );
                              toast.success("Copied");
                            }}
                          ></i>
                        </a>
                      </p>
                      <p>
                        IFSC Code :{" "}
                        {adminbankdetails ? adminbankdetails.ifsccode : ""}{" "}
                        <a href="javascript:void(0)">
                          <i
                            className="fa fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                adminbankdetails.ifsccode
                              );
                              toast.success("Copied");
                            }}
                          ></i>
                        </a>
                      </p>
                      <p>
                        Holder Name :{" "}
                        {adminbankdetails ? adminbankdetails.accountholder : ""}{" "}
                        <a href="javascript:void(0)">
                          <i
                            className="fa fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                adminbankdetails.accountholder
                              );
                              toast.success("Copied");
                            }}
                          ></i>
                        </a>
                      </p>
                      <p>
                        Branch Name :{" "}
                        {adminbankdetails ? adminbankdetails.branchname : ""}{" "}
                        <a href="javascript:void(0)">
                          <i
                            className="fa fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                adminbankdetails.branchname
                              );
                              toast.success("Copied");
                            }}
                          ></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="bank-detail bank-right-outer">
                    <h5>Upi</h5>
                    <p className="upi-id tc">
                      {adminbankdetails ? adminbankdetails.upi_id : ""}{" "}
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            adminbankdetails.upi_id
                          );
                          toast.success("Copied");
                        }}
                      >
                        <i className="fa fa-copy"></i>
                      </a>
                    </p>
                    <div className="qr-img-outer">
                      {adminbankdetails.upi_image && (
                        <img
                          src={
                            baseUrl +
                            "/static/bankImage/" +
                            adminbankdetails.upi_image
                          }
                          alt="banner"
                          className="img-thumbnail"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="deposit-amount-btn">
              <button
                className="btn btn_man fs14 m0a"
                style={{ display: depositeInrBtn }}
                onClick={calculateCheckSum}
                disabled={disabledInrDeposit}
              >
                Deposit Amount
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Deposit INR Modal End */}

        {/* Kyc Modal Start For Kyc */}
        <Modal
          show={isKycModalOpen}
          onHide={closeKycModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="kyc_popop tc">
              <img src="assets/img/v_kyc.png" alt="" />
              <h2
                style={{ color: "#fff" }}
                ref={(subtitle) => (subtitle = subtitle)}
              >
                Your KYC is not done. Kindly complete your KYC.{" "}
              </h2>
              <div>
                <Link className="btn btn_man mt-4 w100px" to="/kyc">
                  Okay!
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Kyc Modal Start For Kyc */}

        {/* Kyc Modal Start For Whitelist */}
        <Modal
          show={isWhitelistModalOpen}
          onHide={closeWhitelistModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="kyc_popop tc">
              <img src="assets/img/v_kyc.png" />
              <h2 ref={(subtitle) => (subtitle = subtitle)}>
                Please Add Whitelist Address For Withdrawal.{" "}
              </h2>
              <div>
                <Link className="btn btn_man mt-4 w100px" to="/Whitelist">
                  Okay!
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Kyc Modal End For Whitelist*/}
        <ToastContainer limit={1} />
      </div>
    </DocumentMeta>
  );
};

export default AccountFunction;
