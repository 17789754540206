import React from "react";
import axios from "axios";
import * as myConstList from "../Common/BaseUrl";
import { Link } from "react-router-dom";
import { ProductName } from "./ProductName";

const baseUrl = myConstList.baseUrl;
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlLink: "",
    };
    window.scrollTo(0, 0);
  }
  componentWillMount = () => {
    this.urlData();
  };

  urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((res) => {
      const resp = res.data;
      this.setState({
        urlLink: `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`,
      });
    });
  };

  reloadPage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section className="contact footer" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>About</h5>
                <ul className="">
                  <li className="">
                    <Link className="" to="/announcements">
                      Announcements
                    </Link>
                  </li>

                  <li className="">
                    <Link className="" to="/termsOfUse">
                      Terms Of Use
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="/news">
                      News
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="/buy-sell-crypto-online">
                      About Us
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="/contact-us-for-crypto-exchange">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Services</h5>
                <ul>
                  <li>
                    <Link to="/download">Download</Link>
                  </li>
                  <li>
                    <Link to="/referralProgram">Referral Program</Link>
                  </li>
                  <li>
                    <Link  to="/listing">List Your Coin</Link>
                  </li>

                  <li>
                    <Link to="/secure-crypto-exchange-platform">
                      Privacy & Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/crypto-buying-selling-online">
                      Risk Statement
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-&-conditions-of-crypto-exchange">
                      Terms & Condition
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Support</h5>
                <ul>
                  <li>
                    <Link to="/fee">Fees</Link>
                  </li>
                  <li>
                    <Link to="/security">Security</Link>
                  </li>
                  <li>
                    <Link to="/faq-for-crypto-exchange">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Product</h5>
                <ul>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>Exchange</Link>
                  </li>
                  <li>
                    <Link to="/video">Video</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Buy Crypto</h5>
                <ul>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>Bitcoin</Link>
                  </li>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>Ethereum</Link>
                  </li>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>USDT</Link>
                  </li>

                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>Tron</Link>
                  </li>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>XRP</Link>
                  </li>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>Dogecoin</Link>
                  </li>
                  <li>
                    <Link to={"/exchange/" + this.state.urlLink}>SHIB</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="footer-social text-center">
                <div className="vertical-social wow fadeInDown  animated">
                  <ul>
                    <li>
                      <a target="_blank" rel="noreferrer" href="#">
                        <i className="fa fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <Link target="_blank" href="f">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <a target="_blank" href="#" rel="noreferrer">
                        {" "}
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <Link target="_blank" href="r">
                        <i className="fa fa-reddit"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright tc">
          © {new Date().getFullYear()} {ProductName} Exchange. All Rights
          Reserved
        </div>{" "}
        <div className="version-outer">
          <p>Version 1.0</p>
        </div>
      </section>
    );
  }
}
export default Footer;
