// const mainUrl = "http://192.168.1.12:5001/frontapi",
// const mainUrl = "http://13.234.237.180:5001/frontapi",

var mainUrl = "",
  base = {
    whitelistData: "/frontapi/whitelistData",
    deleteWhitelist: "/frontapi/deleteWhiteAddress",
    whitelistAdd: "/frontapi/whitelist",
    cryptoCoinList: "/frontapi/cryptocoin_list",
    cryptoCoinListById: "/frontapi/cryptocoin_list_by_id",
    userData: "/frontapi/UserData",
    deleteWhiteList: "/frontapi/deleteWhiteAddress",
    coinPairList: "/frontapi/coin_pair_list",
  };

// if (window.location.hostname == "192.168.1.2") {
//   mainUrl = "http://192.168.1.2:5001/frontapi";
// }

// if (window.location.hostname == "localhost") {
//   mainUrl = "http://localhost:5001/frontapi";
// }
// if (window.location.hostname == "vencrypto.technoloaderitservices.com") {
//   mainUrl = "https://vencryptoapi.technoloaderitservices.com/frontapi";
// }
// if (window.location.hostname == "vencrypto.technoloaderitservices.com") {
//   mainUrl = "https://vencryptoapi.technoloaderitservices.com/frontapi";
// }

// const mainUrl = "http://192.168.1.51:5001/frontapi",

export { base, mainUrl };
