import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "../Common/ProductName";
import MainHeader from "../Common/MainHeader";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";

const Fee = () => {
  const meta = {
    title: `Fee | ${ProductName}  Exchange`,
    description: `Download User Friendly Crypto Exchange App, A Secure Crypto Exchange Platform For BTC, ETH, TRX, DOGE & Other Crypto Currencies In India. Visit ${ProductName}  Exchange Live Website.`,
    canonical: `https://${ProductName}  Exchange.in/fee`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Platform, Cryptocurrencies App, Indian Crypto Exchange, Bitcoin Exchange",
      },
    },
  };

  const [selectedSegment, setSelectedSegment] = useState("trade");
  const [commisionData, setCommisionData] = useState([]);
  const [withdrawalData, setWithdrawalData] = useState([]);

  useEffect(() => {
    getWithdrawal();
    getCommission();
  }, []);

  const getWithdrawal = () => {
    axios.get(baseUrl + "/frontapi/getWithdrawalFees", {}).then((res) => {
      var resp = res.data;
      setWithdrawalData(resp.data);
    });
  };

  const getCommission = () => {
    axios.get(baseUrl + "/frontapi/getCommissionFees", {}).then((res) => {
      var resp = res.data;
      setCommisionData(resp.data);
    });
  };

  const handleSegmentChange = (e, segment) => {
    e.preventDefault();
    setSelectedSegment(segment);
  };

  const withdrawalHtml = () => {
    const html = [];
    withdrawalData &&
      withdrawalData.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>{value.name}</td>
            <td>{value.commission}</td>
          </tr>
        );
      });
    return html;
  };

  const commissionHtml = () => {
    const html = [];
    commisionData &&
      commisionData.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>
              {value.name1}/{value.name2}
            </td>
            <td>{value.commission}</td>
            <td>{value.sell_commission}</td>
          </tr>
        );
      });
    return html;
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="bghwhite">
            <div className="new_inpage">
              <div className="fee-main">
                <div className="container">
                  <div className="notice-outer">
                    <h4>Important</h4>
                    <p>
                      From 1 July 2022, TDS (Tax Deducted at Source) of 1% (5%
                      in exceptional cases) of the trade value will be deducted
                      on each trade as per the Government's guidelines issued on
                      Virtual Digital Asset (VDA) transfer. This TDS can be
                      claimed while filing your ITR.
                    </p>
                  </div>
                </div>
              </div>
              <div class="container container2">
                <div class="left_tab fee-tab">
                  <ul>
                    <li>
                      <a
                        href="#!"
                        className={selectedSegment === "trade" ? "active" : ""}
                        onClick={(e) => handleSegmentChange(e, "trade")}
                      >
                        Trading
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        className={
                          selectedSegment === "withdrawal" ? "active" : ""
                        }
                        onClick={(e) => handleSegmentChange(e, "withdrawal")}
                      >
                        Withdrawal
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive">
                  <div className="scrool_wallet_box">
                    {selectedSegment === "trade" ? (
                      <table className=" table table-striped mb-0 ">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Currency Pair</th>
                            <th>Buy (%)</th>
                            <th>Sell (%)</th>
                          </tr>
                        </thead>
                        <tbody className="main">{commissionHtml()}</tbody>
                      </table>
                    ) : (
                      <table className=" table table-striped mb-0 ">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Coin</th>
                            <th>Withdrawal (%)</th>
                          </tr>
                        </thead>
                        <tbody className="main">{withdrawalHtml()}</tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
      <Footer />
    </div>
  );
};

export default Fee;
