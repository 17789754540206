import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import DocumentMeta from "react-document-meta";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "LaunchPad Detail | VenCrypto  Exchange",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://VenCryptoExchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const LaunchPadDetails = () => {
  const params = useParams();
  const launchpadId = params.id;
  const [currentStatus, setCurrentStatus] = useState("-");
  const [launchpadData, setLaunchpadData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timerDate, setTimerDate] = useState("");
  const [icoPrice, setIcoPrice] = useState("");
  const [website, setWebsite] = useState("");
  const [session_supply, setSession_supply] = useState(0);
  const [remaining_supply, setRemaining_supply] = useState(0);
  const [coinAmount, setCoinAmount] = useState("");
  const [buyTotal, setBuyTotal] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [logo, setLogo] = useState("");
  const [walletBal, setWalletBal] = useState(0);

  // errors
  const [coinErr, setCoinErr] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    if (localStorage.getItem("token")) {
      axios
        .post(
          baseUrl + "/frontapi/getLaunchPadByIdUser",
          { id: launchpadId },
          config
        )
        .then((res) => {
          var resp = res.data;
          setLaunchpadData(resp.data);
          setIcoPrice(resp.data[0].ico_price);
          setStartDate(resp.data[0].ice_start_date);
          setEndDate(resp.data[0].ice_end_date);
          setSession_supply(resp.data[0].session_supply);
          setRemaining_supply(resp.data[0].remaining_supply);
          setLogo(resp.data[0].logo);
          setWebsite(resp.data[0].website);

          setWalletBal(resp.walletBalanceUSDT);

          // socialMediaHtml(JSON.parse(resp.data[0].social_media));

          reverseCounter(
            resp.data[0].ice_start_date,
            resp.data[0].ice_end_date + "T23:59:59"
          );
        });
    } else {
      axios
        .post(baseUrl + "/frontapi/getLaunchPadById", { id: launchpadId })
        .then((res) => {
          var resp = res.data;
          setLaunchpadData(resp.data);
          setIcoPrice(resp.data[0].ico_price);
          setStartDate(resp.data[0].ice_start_date);
          setEndDate(resp.data[0].ice_end_date);
          setSession_supply(resp.data[0].session_supply);
          setRemaining_supply(resp.data[0].remaining_supply);
          setLogo(resp.data[0].logo);
          setWebsite(resp.data[0].website);

          setWalletBal(resp.walletBalanceUSDT);

          // socialMediaHtml(JSON.parse(resp.data[0].social_media));

          reverseCounter(
            resp.data[0].ice_start_date,
            resp.data[0].ice_end_date + "T23:59:59"
          );
        });
    }
  };

  function reverseCounter(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const updateCounter = () => {
      const currentTime = new Date().getTime();
      const remainingTime = endDate - currentTime;

      if (startDate <= currentTime && endDate >= currentTime) {
        setCurrentStatus("Ongoing");
        setBtnDisable(false);
      } else if (endDate < currentTime) {
        setCurrentStatus("Completed");
        setBtnDisable(true);
      } else {
        setCurrentStatus("Upcoming");
        setBtnDisable(true);
      }

      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
        setTimerDate("Countdown has ended!");
      } else {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setTimerDate(
          `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds remaining`
        );
      }
    };
    const countdownInterval = setInterval(updateCounter, 1000);
    updateCounter(); // Initial call to display remaining time immediately
  }

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "purchase") {
      if (value == "0" || !value) {
        setBuyTotal((0).toFixed(8));
        setCoinAmount(value);
        setCoinErr("This field is required");
        return false;
      }

      if (value < 0) {
        setBuyTotal((0).toFixed(8));
        setCoinAmount(value);
        setCoinErr("Please enter valid amount");
        return false;
      }

      let total = Number(value) * Number(icoPrice);

      setCoinErr("");
      setBuyTotal(total.toFixed(8));
      setCoinAmount(value);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (coinAmount == "0" || !coinAmount) {
      setCoinErr("This field is required");
      return false;
    }

    if (coinAmount <= 0) {
      setCoinErr("Please enter valid amount");
      return false;
    }

    if (buyTotal == "0" || !buyTotal) {
      toast.dismiss();
      toast.error("This field is required");
      return false;
    }

    if (buyTotal <= 0) {
      toast.dismiss();
      toast.error("Please enter valid total amount");
      return false;
    }

    if (timerDate == "Countdown has ended!") {
      toast.dismiss();
      toast.error("Launchpad is closed, please check others");
      return false;
    }

    setBtnDisable(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    const data = {
      id: launchpadId,
      coinName: launchpadData[0].short_name,
      coinAmount: coinAmount,
      buyTotal: buyTotal,
    };

    axios.post(baseUrl + "/frontapi/buyLaunchPad", data, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setBtnDisable(true);
        toast.dismiss();
        toast.success(resp.message);

        setTimeout(() => {
          window.location.href = "/launchPad";
        }, 3000);
        return false;
      }

      if (resp.status === false) {
        setBtnDisable(false);
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const socialMediaHtml = (data) => {
    let html = [];

    if (data.length > 0) {
      var socialDataArr = data;

      socialDataArr.map(function (value, i) {
        return html.push(
          <li>
            {value.handle} Link:{" "}
            <a href={value.handle} target="_blank" rel="noreferrer">
              {value.link}{" "}
            </a>{" "}
          </li>
        );
      });

      return html;
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor={""} />
        <div className="staking-banner launch-detail-banner manage-padding">
          <div className="container">
            <div className="lunch-detail-main ne_box p-20">
              <div className="row">
                <div className="col-lg-2 col-12">
                  <div className="launch-left">
                    <img
                      src={baseUrl + "/static/currencyImage/" + logo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-10 col-12">
                  <div className="launch-right">
                    <div className="launch-right-inner">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="heading">
                            <h3>
                              {" "}
                              {launchpadData && launchpadData.length > 0
                                ? launchpadData[0].short_name
                                : ""}{" "}
                              - USDT SESSION
                            </h3>
                          </div>
                        </div>
                        <div className="col-md-2 col-12">
                          <div className="ongoing-btn">
                            <button className="btn btn_man">
                              {currentStatus}
                            </button>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div>{timerDate}</div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="balance-amount">
                            <p>
                              YOUR CURRENT BALANCE:{" "}
                              <b>{walletBal ? walletBal : 0} USDT</b>
                            </p>
                            <p>
                              MAX PURCHASE AMOUNT:{" "}
                              <b>
                                {" "}
                                {launchpadData && launchpadData.length > 0
                                  ? launchpadData[0].max_purchase
                                  : ""}{" "}
                                {launchpadData && launchpadData.length > 0
                                  ? launchpadData[0].short_name
                                  : ""}{" "}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="middle-space"></div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="purchase-amount purchase-main">
                            <p className="m-0">
                              <b>Purchase amount</b> 1{" "}
                              {launchpadData && launchpadData.length > 0
                                ? launchpadData[0].short_name
                                : ""}{" "}
                              = {icoPrice} USDT
                            </p>
                            <form>
                              <div className="form-group position-relative">
                                <input
                                  type="number"
                                  name="purchase"
                                  value={coinAmount}
                                  onKeyDown={handleValidation}
                                  placeholder="Enter Purchase Amount"
                                  className="form-control border-0 p-0"
                                  onChange={handleChange101}
                                />
                                <span className="position-absolute">
                                  {" "}
                                  {launchpadData && launchpadData.length > 0
                                    ? launchpadData[0].short_name
                                    : ""}
                                </span>
                                <p style={{ color: "red" }}> {coinErr} </p>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="purchase-amount amount-pay">
                            <p className="m-0">
                              <b>Amount to Pay</b> (USDT)
                            </p>
                            <input
                              type="number"
                              name="total"
                              placeholder="Total"
                              value={buyTotal}
                              className="form-control border-0 p-0"
                              disabled
                              // onChange={handleChange101}
                            />
                          </div>
                        </div>

                        {/* <div className="col-md-3 col-12">
                          <div className="captcha">
                            <img
                              src="assets/img/captcha.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div> */}

                        <div className="col-md-3 col-12">
                          {localStorage.getItem("token") && (
                            <div className="login-btn">
                              <button
                                onClick={submitForm}
                                className="btn btn_man"
                                disabled={btnDisable}
                              >
                                Submit
                              </button>
                            </div>
                          )}

                          {!localStorage.getItem("token") && (
                            <div className="login-btn">
                              <a href="/login" className="btn btn_man">
                                Login to Purchase
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="launch-right-bottom">
                    <ul className="nav">
                      <li className="ne_box">
                        <p>
                          REMAINING SESSION SUPPLY
                          <b>
                            {" "}
                            {remaining_supply}{" "}
                            {launchpadData && launchpadData.length > 0
                              ? launchpadData[0].short_name
                              : ""}
                          </b>
                          {/* <b>100,000 BIGM</b> */}
                        </p>
                      </li>
                      <li className="ne_box">
                        <p>
                          Price
                          <b>${icoPrice}</b>
                          {/* <b>$1</b> */}
                        </p>
                      </li>
                      <li className="ne_box">
                        <p className="d-flex flex-wrap">
                          Start Time <span>SESSION END</span>
                        </p>
                        <p className="d-flex flex-wrap">
                          {/* <b>2023-06-23 08:30:00</b> */}
                          <b>{startDate}</b>
                          <b>{endDate}</b>
                        </p>
                      </li>
                      <li className="ne_box">
                        <p>
                          WEBSITE
                          <b>
                            <a href={website} target="_blank" rel="noreferrer">
                              {website}{" "}
                            </a>{" "}
                          </b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="requirement-main">
          <div className="container">
            <div className="section-heading d-flex flex-wrap">
              <div className="heading-left">
                <h3 className="m-0">
                  Requirements for participating in this token sale
                </h3>
              </div>
              <div className="community-outer">
                <ul className="nav">
                  <li>
                    <b>Community</b>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="fa fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="fa fa-telegram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="requirement-outer ">
              <div className="requirement-inner ne_box">
                <h5>* Public Sale Event:</h5>
                <ul>
                  <li>
                    Session Supply:
                    {session_supply}{" "}
                    {launchpadData && launchpadData.length > 0
                      ? launchpadData[0].short_name
                      : ""}
                  </li>
                  <li>USDT Market: {icoPrice * session_supply} USDT</li>
                  <li>ICO price: {icoPrice} USDT</li>
                  <li>Starting Time: {startDate}</li>
                  <li>Ending Time: {endDate}</li>
                </ul>
              </div>
              <div className="requirement-inner ne_box">
                <h5>*Project Introduction:</h5>
                <p>
                  {launchpadData && launchpadData.length > 0
                    ? launchpadData[0].description
                    : ""}
                </p>
              </div>
              {/* <div className="requirement-inner ne_box">
                <p>
                  <b>Hardcap is</b> 10,000,000{" "}
                  {launchpadData && launchpadData.length > 0
                    ? launchpadData[0].short_name
                    : ""}
                </p>
                <p>
                  <b>Softcap is</b> 1,000,000{" "}
                  {launchpadData && launchpadData.length > 0
                    ? launchpadData[0].short_name
                    : ""}
                </p>
              </div> */}
              <div className="requirement-inner ne_box">
                <h5>*Project highlight:</h5>
                <p>
                  {" "}
                  {launchpadData && launchpadData.length > 0
                    ? launchpadData[0].highlight
                    : ""}
                </p>
              </div>
              <div className="requirement-inner ne_box">
                <h5>*Project Strength:</h5>
                <p>
                  {launchpadData && launchpadData.length > 0
                    ? launchpadData[0].strength
                    : ""}
                </p>
              </div>
              <div className="requirement-inner ne_box">
                <h5>*Common Links:</h5>
                <ul>
                  <li>
                    Website Link:{" "}
                    <a href={website} target="_blank" rel="noreferrer">
                      {website}{" "}
                    </a>{" "}
                  </li>

                  {launchpadData && launchpadData.length > 0
                    ? socialMediaHtml(JSON.parse(launchpadData[0].social_media))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default LaunchPadDetails;
