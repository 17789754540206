// export const baseUrl = "http://192.168.1.51:5001";

var baseUrl = "";
var socketUrl = "";

if (window.location.hostname == "192.168.1.51") {
  // baseUrl = "http://192.168.1.2:5001";
  baseUrl = "https://api.vencrypto.com";
  // baseUrl = "https://vencryptoapi.technoloaderitservices.com";
  socketUrl = "wss://streams.vencrypto.com";
  // socketUrl = "wss://vencryptostream.technoloaderitservices.com";
}

if (window.location.hostname == "localhost") {
  // baseUrl = "http://localhost:5001";
  baseUrl = "https://api.vencrypto.com";
  // baseUrl = "https://api.vencrypto.com";
  socketUrl = "wss://vencryptostream.technoloaderitservices.com";
  // socketUrl = "wss://streams.vencrypto.com";
}
if (window.location.hostname == "vencrypto.technoloaderitservices.com") {
  baseUrl = "https://vencryptoapi.technoloaderitservices.com";
  socketUrl = "wss://vencryptostream.technoloaderitservices.com";
  // socketUrl = "wss://streams.vencrypto.com";
}
if (
  window.location.hostname == "vencrypto.com" ||
  window.location.hostname == "www.vencrypto.com"
) {
  baseUrl = "https://api.vencrypto.com";
  socketUrl = "wss://streams.vencrypto.com";
}

export var baseUrl;
export var socketUrl;

// export const baseUrl = "http://13.234.237.180:5001";
