import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../../Common/BaseUrl";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProductName } from "../../Common/ProductName";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import { MobileValidation } from "../../Validation/MobileValidation";
const baseUrl = myConstList.baseUrl;
const meta = {
  title: "Forgot | VenCrypto  Exchange ",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://VenCrypto  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const ForgotFunction = () => {
  const [handleState, setHandleState] = useState("");
  const [codeMatchedStatus, setCodeMatchedStatus] = useState(false);
  const [timerText, setTimerText] = useState("");
  const [counter, setCounter] = useState(0);
  const [textForCode, setTextForCode] = useState("Get Code");
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);

  const [VerificationCode, setVerificationCode] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [PasswordIssue, setPasswordIssue] = useState("");
  const [CPasswordIssue, setCPasswordIssue] = useState("");
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [EmailMessageErr, setEmailMessageErr] = useState("");

  const handleChange2 = (event) => {
    var value = event.target.value;

    setTextForCode("");
    setTimerText("Get Code");
    setVerificationCode("");
    setHandleState(event.target.value);

    if (!event.target.value) {
      setMobileError("This field is required");
      setEmailError("");
      setMobile("");
      setEmail("");
      return false;
    }
    if (
      !isNaN(parseFloat(event.target.value)) &&
      !isNaN(event.target.value - 0) == true
    ) {
      var phoneno = /^\d{10}$/;
      let error = MobileValidation("mobile", value, "");
      if (error) {
        setMobileError(error);
        setEmailError("");
      } else {
        setMobileError("");
        setEmailError("");
      }
      setMobile(event.target.value);
      setEmail("");
    } else {
      var Email = event.target.value;
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(Email)) {
        setMobileError("");
        setEmailError("");
      } else {
        setMobileError("");
        setEmailError("Please enter a valid email address");
      }
      setMobile("");
      setEmail(event.target.value);
    }
  };

  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (PasswordIssue || CPasswordIssue) {
      return false;
    }
    if (!Password) {
      setPasswordIssue("This field is required");
      return false;
    }
    if (!CPassword) {
      setCPasswordIssue("This field is required");
      return false;
    }

    if (!Password.match(regex)) {
      setPasswordIssue(
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character"
      );
      return false;
    }

    if (!(Password == CPassword)) {
      setCPasswordIssue("Password miss matched");
      return false;
    }
    setDisabledButton(true);

    setTimeout(() => setDisabledButton(false), 5000);
    let params = {
      Password: Password,
      CPassword: CPassword,
    };
    if (email) {
      params.email = email;
    }
    if (mobile) {
      params.mobile = mobile;
    }

    axios.post(baseUrl + "/frontapi/forgotPassword", params).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setPassword("");
        setCPassword("");

        setTimeout(
          function () {
            window.location = "/login";
          }.bind(this),
          2000
        );
      }
      if (resp.status == false) {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const handleMobileSubmit = (e) => {
    e.preventDefault();
    if (!mobile && !email && !VerificationCode) {
      setMobileError("This field is required");
      setEmailError("This field is required");

      return false;
    }
    if (!mobile && !email) {
      setMobileError("This field is required");
      return false;
    }
    if (!VerificationCode) {
      setVerificationError("This field is required");
      return false;
    }
    if (mobileError || emailError) {
      return false;
    }
    if (mobile) {
      var data = {
        mobile: mobile,
        page: "forgot",
        verification_code: VerificationCode,
      };
    }
    if (email) {
      var data = {
        email: email,
        page: "forgot",
        verification_code: VerificationCode,
      };
    }
    axios.post(baseUrl + "/frontapi/codeVerify", data).then((res) => {
      var resp = res.data;
      if (resp.status) {
        toast.success(resp.message);
        setCPassword("");
        setPassword("");
      } else {
        toast.error(resp.message);
      }
      setCodeMatchedStatus(resp.status);
    });
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setTimerText("Resend In");
      setBtnDisable(true);
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setBtnDisable(false);
        setTextForCode("Resend Code");
        setTimerText("");
      }
    }, 1000);
  };

  const handlecodechange = async (e) => {
    let { value } = e.target;

    setVerificationCode(value);

    if (!value) {
      setVerificationError("This field is required");
    } else {
      setVerificationError("");
    }
  };

  const getCode = async () => {
    if (!email && !mobile) {
      setEmailError("This field is required");

      return false;
    }
    let registerData = {
      countryCode: countryCode,
      page: "forgot",
    };
    if (email) {
      registerData.email = email;
    }
    if (mobile) {
      registerData.mobile = mobile;
    }

    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((resp) => {
        // eslint-disable-next-line
        var resp = resp.data;
        if (resp.status === false) {
          toast.error(resp.EmailMessage);
        }
        setEmailMessageErr(resp.EmailMessage);

        if (resp.status === true) {
          toast.success(resp.EmailMessage);
          startCountdown(30);
          setButtonDisableVerification(false);
          return false;
        }
        if (resp.status === false) {
          setButtonDisableVerification(true);
          toast.error(resp.message);
          return false;
        }
      });
  };

  const handleClick = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  const handleClick1 = () => {
    if (type1 === "text") {
      setType1("password");
    } else {
      setType1("text");
    }
  };

  const handleChange101 = (event) => {
    let { name, value, id } = event.target;

    setErrMsg("");
    setTextForCode("Get Code");
    setTimerText("Get Code");

    // Password //
    if (name == "password") {
      setPassword(value);
      var passwordError = PasswordValidation(name, value);
      setPasswordIssue(passwordError);
      if (passwordError == "mismatched") {
        setCPassword("Password mis-matched");
        setPasswordIssue("");
      }
      if (passwordError == "") {
        setCPassword("");
        setPasswordIssue("");
      }
    }

    if (name == "cpassword") {
      setCPassword(value);
      var CpasswordError = PasswordValidation(name, value);
      setCPasswordIssue(CpasswordError);
      if (passwordError == "") {
        setCPasswordIssue("");
      }
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div className="register-main-outer position-relative d-flex align-items-center flex-wrap">
        <span className="main-bg fadeInLeft animated"></span>
        <div className="register-inner">
          <div className="row m-0">
            <div className="col-12 p-0">
              <div className="register-right fadeInRight animated ">
                <div className="heading-link-outer">
                  <div className="heading">
                    <h2 className="m-0">Forgot Password</h2>
                  </div>
                </div>
                <div className="register-form">
                  {!codeMatchedStatus ? (
                    <form
                      onSubmit={handleMobileSubmit}
                      //   ref={(el) => (myFormRef = el)}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label>Email or Mobile Number</label>
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              placeholder="Enter Email or Mobile Number"
                              name="Email"
                              maxLength={70}
                              value={handleState}
                              onChange={handleChange2}
                              // onBlur={handleOnBlurEmail.bind(this)}
                              disabled={counter == 0 ? false : true}
                            />
                            <div style={{ color: "red" }}>
                              {emailError || mobileError}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Verification Code</label>
                            <div className="get-outer position-relative">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Verification Code"
                                name="VerificationCode"
                                onChange={handlecodechange}
                                onKeyDown={handleValidation}
                                disabled={
                                  codeMatchedStatus || buttonDisableVerification
                                }
                                // onBlur={onBlurVerificationCode.bind(
                                //   this
                                // )}
                                value={VerificationCode}
                                aria-label="Input group example"
                                aria-describedby="btnGroupAddon"
                              />

                              <div className="get-btn position-absolute">
                                <button
                                  className="btn btn_man"
                                  id="btnGroupAddon"
                                  type="button"
                                  onClick={getCode}
                                  disabled={codeMatchedStatus || btnDisable}
                                >
                                  {timerText}
                                  {"  "}
                                  {counter == 0 ? textForCode : counter}
                                </button>
                              </div>
                            </div>
                            <div style={{ color: "red" }}>
                              {verificationError}
                            </div>
                          </div>
                        </div>
                        {!codeMatchedStatus && (
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <button className="btn btn_man" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleChangePasswordSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Password</label>
                            <div className="eye_pass">
                              <input
                                className="form-control"
                                type={type}
                                name="password"
                                id="Password"
                                placeholder="Enter Password"
                                // onBlur={handleBlurChange}
                                value={Password}
                                onChange={handleChange101}
                              />
                              <span
                                className="password__show eye1"
                                onClick={handleClick}
                              >
                                {type === "text" ? (
                                  <i className="las la-eye"></i>
                                ) : (
                                  <i className="las la-low-vision"></i>
                                )}
                              </span>
                              {PasswordIssue && (
                                <div style={{ color: "red" }}>
                                  {PasswordIssue}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Confirm Password</label>

                            <div className="form-group eye_pass">
                              <input
                                className="form-control"
                                type={type1}
                                placeholder="Enter Confirm Password"
                                name="cpassword"
                                id="CPassword"
                                onChange={handleChange101}
                                value={CPassword}
                              />
                              <span
                                className="password__show eye1"
                                onClick={handleClick1}
                              >
                                {type1 === "text" ? (
                                  <i className="las la-eye"></i>
                                ) : (
                                  <i className="las la-low-vision"></i>
                                )}
                              </span>

                              {CPasswordIssue && (
                                <div style={{ color: "red" }}>
                                  {CPasswordIssue}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button className="btn w100 btn_man">Submit</button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer limit={1} pauseOnHover={false} autoClose={4000} />
      </div>
    </DocumentMeta>
  );
};

export default ForgotFunction;
