import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TradingIdea = () => {
  const [record, setRecord] = useState([]);
  const [recordStatus, setRecordStatus] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(baseUrl + "/frontapi/getAllTradings", {}).then((res) => {
      var resp = res.data;
      setRecordStatus(resp.status);
      setRecord(resp.data);
    });
  };

  const showTableHtml = () => {
    if (recordStatus === true) {
      const html = [];
      const firstThreeElements = record.slice(0, 3);

      firstThreeElements.map((value, i) => {
        return html.push(
          <div className="col-md-4">
            <div className="market-outer">
              <div className="market-inner ">
                <div className="img-outer">
                  <Link to={"/trading-idea/" + window.btoa(value.id)}>
                    <img
                      src={baseUrl + "/static/trading/" + value.image}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="content-outer">
                  <h4>{value.heading}</h4>
                  <h6>{value.subTitle}</h6>
                  <p>{value.description}</p>
                  <Link to={"/trading-idea/" + window.btoa(value.id)}>
                    read more
                  </Link>
                </div>{" "}
              </div>
            </div>
          </div>
        );
      });

      return html;
    }
  };

  return (
    <div className="market-news-outer">
      <div className="container">
        <h2 className="text-center mb-4">Trading Ideas</h2>

        <div className="row">
          {showTableHtml()}

          {record && record.length >= 4 ? (
            <div className="col-12">
              <div className="more-btn text-right">
                <Link to="/trading-ideas" className="btn btn_man">
                  More Trading Ideas{" "}
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TradingIdea;
