import React, { useEffect, useState } from "react";
// import Logo from "../../img/logo2.png";
import { Link } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
const baseUrl = myConstList.baseUrl;

const Nav = (props) => {
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [mobile, setMobile] = useState("");
  const [accHolder, setAccHolder] = useState("");
  var token = localStorage.getItem("token");

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", false);
    }

    var theme = localStorage.getItem("theme");
    if (theme == null) {
      theme = false;
    }
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }

    if (token) {
      getData();
    }
    urlData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;

      if (resp.data[0].email == null) {
        setEmail(resp.data[0].email);
        setMobile(resp.data[0].mobile_no);
        setAccHolder(resp.data[0].AccHolder);
      } else {
        setEmail(resp.data[0].email);
      }
    });
  };

  const urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((resp) => {
      var resp = resp.data;
      setUrlLink(
        `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`
      );
    });
  };

  const modifyText = (text) => {
    if (text.length <= 16) {
      return text;
    } else {
      return text.substr(0, 13) + "...";
      // return text.substr(0, 13);
    }
  };

  const showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null) {
      return (
        <ul className="">
          <li className="">
            <Link to="/login">Log in</Link>
          </li>
          <li className="">
            <Link to="/register">Sign up</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="ment_right ml-auto ">
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                {accHolder
                  ? modifyText(accHolder)
                  : email
                  ? modifyText(email)
                  : mobile
                  ? modifyText(mobile)
                  : ""}
              </span>
              <div className="dropdown-menu2">
                <Link className="a_flex" to="/profile">
                  <i class="fa fa-user-circle mr-1"></i> Profile
                </Link>
                {/* <Link className="a_flex" to="/mainWalletTransactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}
                <Link className="a_flex" to="/Kyc">
                  <i className="las la-share-square"></i> My KYC
                </Link>
                <Link className="a_flex" to="/myStacking">
                  <i className="fa fa-stack-exchange"></i> My Staking
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="las la-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="las  la-sign-in-alt"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    var theme = localStorage.getItem("theme");
    localStorage.clear();
    localStorage.setItem("theme", theme);
    window.location.href = "/login";
    sessionStorage.clear();
  };

  const handleChange = (checked) => {
    if (props.graphColor !== "") {
      props.graphColor();
    }
    let tags = document.getElementsByTagName("body");
    if (checked === true) {
      tags[0].classList.add("dark-theme");
      setChecked(false);
    } else {
      tags[0].classList.remove("dark-theme");
      setChecked(true);
    }
    localStorage.setItem("theme", checked);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand navbar-brand2" href ="/">
          <img src="/assets/img/logo2.png" alt="Logo" className="logo logo1" />
          <img src="/assets/img/logo2.png" alt="Logo" className="logo logo2" />
        </a>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarText"
        >
          <span className="icon-bar">
            <i className="fa fa-bars fa-2x"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav  line align-items-center">
            <li
              className={
                window.location.pathname == "/exchange/" + urlLink
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              {!token ? (
                <a className="nav-link " href={"/exchange/" + urlLink}>
                  Exchange
                </a>
              ) : (
                <a className="nav-link " href={"/exchange/" + urlLink}>
                  Exchange
                </a>
              )}
            </li>

            <li
              className={
                window.location.pathname == "/stacking"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              {!token ? (
                <Link className="nav-link " to="/stacking">
                  Staking
                </Link>
              ) : (
                <Link className="nav-link " to="/stacking">
                  Staking
                </Link>
              )}
            </li>

            <li
              className={
                window.location.pathname == "/launchPad"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              {!token ? (
                <Link className="nav-link " to="/launchPad">
                  LaunchPad
                </Link>
              ) : (
                <Link className="nav-link " to="/launchPad">
                  LaunchPad
                </Link>
              )}
            </li>

            <li
              className={
                window.location.pathname == "/listing"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              {!token ? (
                <Link className="nav-link " to="/listing">
                  Listings
                </Link>
              ) : (
                <Link className="nav-link " to="/listing">
                  Listings
                </Link>
              )}
            </li>

            <li className="nav-item">
              {checked == false ? (
                <img
                  src="/assets/img/moon.png"
                  width="25px"
                  alt=""
                  className="moon-img theme-img"
                  onClick={() => handleChange(checked)}
                  checked={checked}
                />
              ) : (
                <img
                  src="/assets/img/sun.png"
                  alt=""
                  width="25px"
                  className="sun-img theme-img"
                  onClick={() => handleChange(checked)}
                  checked={checked}
                />
              )}
            </li>
          </ul>
          {/* <!--End of Main menu navigation list--> */}
        </div>
        <div className="dashbord_menu">{showHeaderPart()}</div>
      </div>
    </nav>
  );
};

export default Nav;
