import React, { useState } from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./Common/BaseUrl";
import { Link } from "react-router-dom";
import { ProductName } from "./Common/ProductName";
import DocumentMeta from "react-document-meta";
const baseUrl = myConstList.baseUrl;

const ContactUs = () => {
  const meta = {
    title: `Contact Us | ${ProductName}  Exchange `,
    description:
      "Get A User Friendly Crypto Exchange App, A Local Crypto Exchange Platform In India For Bitcoin Exchange And Other Crypto Currencies Trading. Sign Up Now.",
    canonical: `https://${ProductName}.live/contact-us-for-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Exchange, Local Crypto Exchange, BTC Exchange, Bitcoin Exchange",
      },
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [mailStatus, setMailStatus] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  // error
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");

  const handleChange = (event) => {
    let { name, value } = event.target;

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameError("Name is required");
        return false;
      }
      setNameError("");
    }

    if (name === "email") {
      setEmail(value);

      if (!value) {
        setEmailError("Email is required");
        return false;
      }

      if (!value.match(emailReg)) {
        setEmailError("Please enter valid email");
        return false;
      }

      setEmailError("");
    }

    if (name === "subject") {
      setSubject(value);

      if (!value) {
        setSubjectError("Subject is required");
        return false;
      }

      setSubjectError("");
    }

    if (name === "message") {
      setMessage(value);

      if (!value) {
        setMessageError("Message is required");
        return false;
      }
      setMessageError("");
    }
  };

  const submitEmail = (e) => {
    e.preventDefault();

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (!name) {
      setNameError("Name is required");
      return false;
    }

    let hasLeadingSpace = /^\s/.test(name);
    let hasTrailingSpace = /\s$/.test(name);

    if (hasLeadingSpace) {
      setNameError("Please enter a valid name");
      return false;
    }

    if (hasTrailingSpace) {
      setNameError("Please enter a valid name");
      return false;
    }

    if (!email) {
      setEmailError("Email is required");
      return false;
    }

    if (!email.match(emailReg)) {
      setEmailError("Please enter valid email");
      return false;
    }

    if (!subject) {
      setSubjectError("Subject is required");
      return false;
    }

    let hasLeadingSpaceSubject = /^\s/.test(subject);
    let hasTrailingSpaceSubject = /\s$/.test(subject);

    if (hasLeadingSpaceSubject) {
      setSubjectError("Please enter a valid text");
      return false;
    }

    if (hasTrailingSpaceSubject) {
      setSubjectError("Please enter a valid text");
      return false;
    }

    if (!message) {
      setMessageError("Message is required");
      return false;
    }

    let hasLeadingSpaceMessage = /^\s/.test(message);
    let hasTrailingSpaceMessage = /\s$/.test(message);

    if (hasLeadingSpaceMessage) {
      setMessageError("Please enter a valid text");
      return false;
    }

    if (hasTrailingSpaceMessage) {
      setMessageError("Please enter a valid text");
      return false;
    }

    var details = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };

    setBtnDisable(true);

    axios.post(baseUrl + "/frontapi/sendContactMail", details).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.dismiss();
        resetForm();
        toast.success("Mail sent successfully");
        setTimeout(() => {
          setBtnDisable(false);
        }, 3000);
      }
    });
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="privacy_policy">
            <div className="container container2">
              <div className="wrapper mt-4">
                <div className="row no-gutters">
                  <div className="col-md-7 d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Get in touch</h3>
                      <div id="form-message-warning" className="mb-4" />
                      <form onSubmit={submitEmail}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={handleChange}
                              />
                              <div className="text-danger">{nameError}</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                                value={email}
                                onChange={handleChange}
                              />
                              <div className="text-danger">{emailError}</div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                value={subject}
                                onChange={handleChange}
                              />
                              <div className="text-danger">{subjectError}</div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols={30}
                                rows={7}
                                placeholder="Message"
                                // defaultValue={""}
                                value={message}
                                onChange={handleChange}
                              />
                              <div className="text-danger">{messageError}</div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                className="btn btn_man "
                                // defaultValue="Send Message"
                                disabled={btnDisable}
                              />
                              <div className="submitting" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex align-items-stretch">
                    <div className="info-wrap bg_contact w-100 p-lg-5 p-4">
                      <h3 className="mb-4 mt-md-4">Contact us</h3>
                      <div className="dbox w-100 d-flex align-items-start mb-2">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-map-marker" />
                        </div>
                        <div className="text pl-3 ">
                          <p className="mt-0">
                            <span>Address: </span>
                            Akshya Nagar
                            <br /> 1st Block 1st Cross, Rammurthy nagar,
                            Bangalore-560016
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center mb-3">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-phone" />
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Phone:</span> <h6 to="tel:#">+31616239474</h6>
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-paper-plane" />
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Email:</span>{" "}
                            <h6 to="mailto:support@avonx.com">
                              support@vencrypto.io
                            </h6>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} limit={1} />
      </DocumentMeta>
    </div>
  );
};

export default ContactUs;
