import React, { useEffect, useState } from "react";
import { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = myConstList.baseUrl;

export default function LoginHeader(props) {
  const [email, setEmail] = useState("");
  const [checked, setchecked] = useState(false);
  const [accHolder, setAccHolder] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [urlLink, setUrlLink] = useState("");

  const history = useHistory();
  useEffect(() => {
    var token1 = localStorage.getItem("token");
    if (token1 === null) {
      if (
        window.location.pathname == "/blog/:id" ||
        window.location.pathname == "/blogs"
      ) {
        return;
      }

      return history.push("/login");
    }
  });

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", false);
    }
    var theme = localStorage.getItem("theme");
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }
    urlData();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      if (resp.data[0].is_deleted == "1") {
        localStorage.clear();
      }
      if (resp.data[0].email == null) {
        setEmail(resp.data[0].email);

        setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
      } else {
        setEmail(resp.data[0].email);
        setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
      }
    });
  };

  const urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((res) => {
      var resp = res.data;
      setUrlLink(
        `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`
      );
    });
  };

  function modifyText(text) {
    if (text.length <= 16) {
      return text;
    } else {
      return text.substr(0, 13) + "...";
      // return text.substr(0, 13);
    }
  }

  const showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null) {
      history.push("/login");
    } else {
      return (
        <ul>
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                {accHolder
                  ? modifyText(accHolder)
                  : email
                  ? modifyText(email)
                  : mobile_no
                  ? modifyText(mobile_no)
                  : ""}
                {/* {accHolder ? accHolder : email ? email : mobile_no} */}
              </span>
              <div className="dropdown-menu2">
                <Link className="a_flex" to="/profile">
                  <i class="fa fa-user-circle mr-1"></i> Profile
                </Link>
                <Link className="a_flex" to="/Kyc">
                  <i className="las la-share-square"></i> My KYC
                </Link>
                <Link className="a_flex" to="/myStacking">
                  <i className="fa fa-stack-exchange"></i> My Staking
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="las la-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="las  la-sign-in-alt"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    toast.success("Logout Successfully");
    let interval = setInterval(() => {
      var theme = localStorage.getItem("theme");
      localStorage.clear();
      localStorage.setItem("theme", theme);
      window.location.href = "/login";
      // history.push("/login");
      clearInterval(interval);
    }, 1000);
  };
  const handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      setchecked(false);
    } else {
      tags[0].classList.remove("dark-theme");
      setchecked(true);
    }
    localStorage.setItem("theme", checked);
  };
  var token2 = localStorage.getItem("token");
  return (
    <div className="responsive_in ">
      <nav className="navbar navbar-expand-lg nav-scroll2">
        <div className="container-fluid ">
          <a className="navbar-brand" href ="/index">
            <img
              src="assets/img/logo2.png"
              alt="header-Logo"
              className="logo logo1"
            />
            <img
              src="/assets/img/logo2.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line align-items-center">
              <li
                className={
                  window.location.pathname == "/exchange/" + urlLink
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                {!token2 ? (
                  <a className="nav-link " href={"/exchange/" + urlLink}>
                    Exchange
                  </a>
                ) : (
                  <a className="nav-link " href={"/exchange/" + urlLink}>
                    Exchange
                  </a>
                )}
              </li>
              {/* <li className="nav-item">
                {!token2 ? (
                  <Link className="nav-link " to="/stacking">
                    Staking
                  </Link>
                ) : (
                  <Link className="nav-link " to="/stacking">
                    Staking
                  </Link>
                )}
              </li> */}
              <li
                className={
                  window.location.pathname == "/account"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/account">
                  Account
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/mainWalletTransactions" ||
                  window.location.pathname == "/spotHistory" ||
                  window.location.pathname == "/coinDeposite" ||
                  window.location.pathname == "/coinFundRequest"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/mainWalletTransactions">
                  Transactions
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/spotBuyHistory" ||
                  window.location.pathname == "/spotSellHistory"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/spotBuyHistory">
                  Trade
                </Link>
              </li>

              <li className="nav-item">
                {checked == false ? (
                  <img
                    src="/assets/img/moon.png"
                    alt="moon"
                    width="25px"
                    className="moon-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                ) : (
                  <img
                    src="/assets/img/sun.png"
                    alt="sun"
                    width="25px"
                    className="sun-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                )}
              </li>
            </ul>
          </div>
          <div className="dashbord_menu">
            <ul className="">{showHeaderPart()}</ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
