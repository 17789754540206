import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
import Nav from "../Common/Nav";
const baseUrl = myConstList.baseUrl;

const BlogDetails = () => {
  const params = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const getData = () => {
    axios
      .post(baseUrl + "/frontapi/getAllBlogById", { id: blogId })
      .then((res) => {
        var resp = res.data;
        setTitle(resp.data[0].title);
        setDescription(resp.data[0].description);
        setImage(resp.data[0].image);
        dateHtml(resp.data[0].createdAt);
      });
  };

  const blogId = window.atob(params.id);

  const meta = {
    title: `Blog | ${ProductName}  Exchange`,
    description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const dateHtml = (createdAt) => {
    // Parse the input date using moment
    const inputDate = Moment(createdAt);

    // Format the date in the desired output format (DD/MM/YYYY)
    const desiredFormat = "DD/MM/YYYY";
    setCreatedAt(inputDate.format(desiredFormat));
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <Nav graphColor={""} />
        <div className="blog-detail more-blog-main">
          <div className="container">
            <div className="blog-main-outer">
              <div className="img-outer">
                <img
                  src={baseUrl + "/static/blogImage/" + image}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="content-outer">
                <h2>
                  {title}
                  <small className="blog-date">{createdAt}</small>
                </h2>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </DocumentMeta>
  );
};

export default BlogDetails;
