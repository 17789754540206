import React, { Component } from "react";
import axios from "axios";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../../Common/BaseUrl";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";

import "react-toastify/dist/ReactToastify.css";
import { EmailValidation } from "../../Validation/EmailValidation";
import { MobileValidation } from "../../Validation/MobileValidation";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import { meta } from "../../Common/Meatdata";
import { ProductName } from "../../Common/ProductName";

const baseUrl = myConstList.baseUrl;

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Email: true,
      Mobile: false,
      CPassword: "",
      Password: "",
      EmailTabClass: "",
      MobileTabClass: "",
      showMessage: false,
      type: "password",
      showMessage1: false,
      type1: "password",
      textForCode: "Get Code",
      userType: "USER",
      counter: 0,
      ReferId: "",
      timerText: "",
      check1: "0",
      countryCode: "+91",
      PasswordIssue: "",
      codeMatchedStatus: false,
      buttonDisable: false,
      buttonDisableEmail: false,
      buttonDisableVerification: true,
      buttonDisablePass: false,
      buttonDisableCPass: false,
      buttonDisableCheck: false,
      btnDisabled: false,
    };
  }
  onBlurVerificationCode = (event) => {
    var Vcode = event.target.value;
    var verificationError = "";
    if (!Vcode) {
      verificationError = "This field is required";
    }
    this.setState({ verificationError });
  };

  referCode = () => {
    let registerData = {
      email: this.state.email,
    };
    axios
      .post(baseUrl + "/frontapi/generateReferalCode", registerData)
      .then((resp) => {
        var resp = resp.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  startCountdown(seconds) {
    let counter = seconds;
    var interval = setInterval(() => {
      this.setState({ counter: counter });
      this.setState({ btnDisable: true });
      this.setState({ timerText: "Resend In" });
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        this.setState({ textForCode: "Resend Code" });
        this.setState({ btnDisable: false });
        this.setState({ btnDisabled: false });
        this.setState({ timerText: "" });
        return false;
      }
      this.setState({ btnDisabled: true });
    }, 1000);
  }

  getCode = async (event) => {
    event.preventDefault();

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (this.state.countryCode !== "+91") {
      if (!this.state.email) {
        this.setState({ emailError: "Email address is required" });
        return false;
      }

      if (!this.state.email.match(emailReg)) {
        this.setState({
          emailError: "Please enter a valid email address",
        });
        return false;
      }

      if (this.state.emailError) {
        return false;
      }
    }
    if (this.state.countryCode == "+91") {
      if (!this.state.mobile) {
        this.setState({ mobileError: "Mobile number is required" });
        return false;
      }

      const firstDigit = parseInt(this.state.mobile.charAt(0));

      // Check if the first digit is 6 or greater than 6
      if (firstDigit < 5) {
        this.setState({ mobileError: "Please enter a valid mobile number" });
        return false;
      }

      if (this.state.mobileError) {
        return false;
      }
    }

    let registerData = {
      countryCode: this.state.countryCode,
      page: "register",
    };
    if (this.state.countryCode == "+91") {
      registerData.mobile = this.state.mobile;
    }
    if (this.state.countryCode !== "+91") {
      registerData.email = this.state.email;
    }

    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((resp) => {
        // eslint-disable-next-line
        var resp = resp.data;
        if (resp.status === false) {
          toast.error(resp.EmailMessage);
        }
        this.setState({
          EmailMessageErr: resp.EmailMessage,
        });
        if (resp.status === true) {
          // if (this.state.countryCode == "+91") {
          // this.setState({ VerificationCode: resp.code });
          this.setState({ verificationError: "" });
          // }
          this.startCountdown(30);
          toast.dismiss();
          toast.success(resp.EmailMessage);
          this.setState({ buttonDisableVerification: false });
          return false;
        }
        if (resp.status === false) {
          this.setState({ buttonDisableVerification: true });
          toast.error(resp.message);
          return false;
        }
      });
  };

  handleChange1 = (event) => {
    var messageCheck1 = "";
    if (event.target.checked == false) {
      messageCheck1 = "Please select terms & conditions";
      this.setState({
        check1: "0",
        messageCheck1: messageCheck1,
        buttonDisableCheck: false,
      });
    }
    if (event.target.checked == true) {
      this.setState({
        buttonDisableCheck: true,
        check1: "1",
        messageCheck1: messageCheck1,
      });
    }
  };

  selectCountry(val) {
    this.setState({ country: val });
  }

  OpenEmail = () => {
    this.setState({
      Email: true,
      Mobile: false,
      EmailTabClass: "active",
      MobileTabClass: "",
    });
  };
  OpenMobile = () => {
    this.setState({
      Email: false,
      Mobile: true,
      EmailTabClass: "",
      MobileTabClass: "active",
    });
  };

  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  handleClick1 = () =>
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));

  static displayName = "RememberMe";

  state = {
    email: "",
    password: "",
    isChecked: false,
  };
  componentWillMount() {
    meta.title = `Signup | ${ProductName} Exchange`;
    meta.description = `Signup in ${ProductName} Exchange to enjoy its features`;
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      window.location.href = "/";
      return false;
    }

    if (this.props.referId) {
      this.setState({ ReferId: this.props.referId });
    }

    if (localStorage.checkbox && localStorage.email !== "") {
      this.setState({
        isChecked: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
  }
  handlechange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });

    if (name === "VerificationCode") {
      if (!value) {
        this.setState({ verificationError: "This field is required" });
        return;
      } else {
        this.setState({ verificationError: "" });
      }
    }
  };

  handlechangeCheck = (event) => {
    var check1 = event.target.checked;
    var messageCheck1 = "";
    if (!check1) {
      messageCheck1 = "Terms checked required";
    }
    this.setState({
      check1: check1,
      messageCheck1: messageCheck1,
    });
  };

  handleChange101 = (event) => {
    // let binance = "wss://stream.binance.com:9443/stream?streams=btcusdt@ticker_1h"
    let { name, value, id } = event.target;

    this.setState({ [id]: value, errMsg: "" });
    if (name == "email") {
      const emailvalidation = EmailValidation(name, value);
      this.setState({ emailError: emailvalidation });
      this.setState({ textForCode: "Get Code", VerificationCode: "" });
    }
    if (name == "mobile") {
      const mobilevalidation = MobileValidation(name, value, "Register");
      this.setState({ mobileError: mobilevalidation });
      this.setState({ textForCode: "Get Code", VerificationCode: "" });
    }
    if (name == "verification_code") {
      this.setState({
        verificationError: "This field is required",
      });
    }
    if (name == "password") {
      var passwordError = PasswordValidation(name, value);
      this.setState({
        PasswordIssue: passwordError,
      });
      if (passwordError == "mismatched") {
        this.setState({
          CPasswordIssue: "Confirm password does not match",
          PasswordIssue: "",
        });
      }
      if (passwordError == "") {
        this.setState({
          CPasswordIssue: "",
          PasswordIssue: "",
        });
      }
    }

    if (name == "cpassword") {
      var CpasswordError = PasswordValidation(name, value);
      this.setState({
        CPasswordIssue: CpasswordError,
      });
      if (passwordError == "") {
        this.setState({
          CPasswordIssue: "",
        });
      }
    }
  };

  cancelCourse = () => {
    this.setState({
      username: "",
      email: "",
      VerificationCode: "",
      Password: "",
      CPassword: "",
      ReferId: "",
      country: "",
    });
  };

  handleMobileSubmit = (e) => {
    e.preventDefault();
    let data = {
      countryCode: this.state.countryCode,
      verification_code: this.state.VerificationCode,
    };
    if (this.state.countryCode == "+91") {
      const mobilevalidation = MobileValidation(
        "mobile",
        this.state.mobile,
        "Register"
      );

      if (!this.state.mobile) {
        this.setState({ mobileError: "Mobile number is required" });
        return false;
      }

      var regexNum = /^([0|\+[0-9]{1,10})?([0-9]{5})$/;
      if (!this.state.mobile.match(regexNum)) {
        this.setState({ mobileError: "Please enter a valid mobile number." });
        return false;
      }

      if (this.state.mobile.length !== 10) {
        this.setState({ mobileError: "Please enter a valid mobile number." });
        return false;
      }

      this.setState({ mobileError: mobilevalidation });
      data.mobile = this.state.mobile;
    } else {
      const emailvalidation = EmailValidation("email", this.state.email);
      this.setState({ emailError: emailvalidation });
      data.email = this.state.email;
    }

    if (!this.state.VerificationCode) {
      this.setState({ verificationError: "This field is required" });
      return false;
    }

    axios.post(baseUrl + "/frontapi/codeVerify", data).then((resp) => {
      var resp = resp.data;
      if (resp.status) {
        this.setState({
          btnDisabled: resp.status,
        });
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }

      this.setState({
        codeMatchedStatus: resp.status,
        btnDisabled: resp.status,
      });
    });
  };

  handleBlurChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "email" && !value) {
      this.setState({
        EmailIssue: "This field is required",
      });
    }
    if (name == "password" && !value) {
      this.setState({
        PasswordIssue: "This field is required",
      });
    }
    if (name == "cpassword" && !value) {
      this.setState({
        CPasswordIssue: "This field is required",
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let registerData = {
      countryCode: this.state.countryCode,
      VerificationCode: this.state.VerificationCode,
      Password: this.state.Password,
      CPassword: this.state.CPassword,
      ReferId: this.state.ReferId,
      terms: this.state.check1,
    };
    if (this.state.mobile) {
      registerData.mobile = this.state.mobile;
    }
    if (this.state.email) {
      registerData.email = this.state.email.toLowerCase();
    }
    var passwordError = PasswordValidation("Password", this.state.Password);
    this.setState({
      PasswordIssue: passwordError,
    });

    if (passwordError == "mismatched") {
      this.setState({
        CPasswordIssue: "Confirm password does not match",
        PasswordIssue: "",
      });

      if (passwordError == "") {
        this.setState({
          CPasswordIssue: "",
          PasswordIssue: "",
        });
      }
    }
    var CpasswordError = PasswordValidation("CPassword", this.state.CPassword);
    this.setState({
      CPasswordIssue: CpasswordError,
    });
    if (passwordError || CpasswordError) {
      return false;
    }

    if (this.state.check1 == "0" || this.state.check1 == undefined) {
      this.setState({ messageCheck1: "Please select terms & conditions" });
      return false;
    }
    axios.post(baseUrl + "/frontapi/register", registerData).then((resp) => {
      var resp = resp.data;
      localStorage.setItem("registerMessage", this.state.messageR);
      if (resp.status === true) {
        this.setState({
          StatusMessage: resp.message,
          EmailErr: resp.EmailErr,
          Emailmessage: resp.Emailmessage,
          respStatus: resp.status,
          ExistEmail: resp.EmailMessage1,
          userType: "USER",
          mobile: "",
          email: "",
        });
        this.referCode();
        this.cancelCourse();
        toast.success(
          "Congratulations, your account has been registered successfully"
        );
        setTimeout(
          function () {
            window.location = "/login";
          }.bind(this),
          2000
        );
        return false;
      }
      if (resp.status === false) {
        toast.error(resp.message);
        return false;
      }
    });
  };

  handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  render() {
    return (
      <DocumentMeta {...meta}>
        <div className="">
          <div className="register-main-outer signup-main-outer position-relative d-flex align-items-center flex-wrap">
            <span className="main-bg fadeInRight animated"></span>
            <div className="register-inner">
              <div className="row m-0 ">
                <div className="col-12 p-0">
                  <div className="register-right fadeInLeft animated ">
                    <div className="heading-link-outer">
                      <div className="heading">
                        <h2 className="m-0">Sign Up</h2>
                      </div>
                    </div>

                    <div className="register-form signup-form">
                      <form
                        onSubmit={this.handleMobileSubmit}
                        ref={(el) => (this.myFormRef = el)}
                      >
                        {this.state.countryCode == "+91" ? (
                          <label
                          // style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            Mobile Number
                          </label>
                        ) : (
                          <label
                          // style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            Email Address
                          </label>
                        )}

                        <div className="form-group position-relative">
                          <PhoneInput
                            className="country-code position-absolute"
                            countryCodeEditable={false}
                            country={"in"}
                            enableSearch={true}
                            disableCountryCode={true}
                            disableDropdown={
                              this.state.codeMatchedStatus ||
                              this.state.counter == 0
                                ? false
                                : true
                            }
                            value={this.state.countryCode}
                            disabled={this.state.codeMatchedStatus}
                            onChange={(countryCode) =>
                              this.setState({
                                countryCode: "+" + countryCode,
                                mobileError: "",
                                emailError: "",
                                verificationError: "",
                                mobile: "",
                                email: "",
                              })
                            }
                          />
                          {this.state.countryCode == "+91" ? (
                            <>
                              <input
                                className="form-control country-input"
                                type="number"
                                placeholder="Enter Mobile Number"
                                name="mobile"
                                id="mobile"
                                onKeyDown={this.handleValidation}
                                maxLength={20}
                                onBlur={this.handleBlurChange}
                                onChange={this.handleChange101}
                                value={this.state.mobile}
                                disabled={
                                  this.state.codeMatchedStatus
                                    ? this.state.codeMatchedStatus
                                    : this.state.counter == 0
                                    ? false
                                    : true
                                }
                              />
                            </>
                          ) : (
                            <>
                              <input
                                className="form-control country-input"
                                type="text"
                                placeholder="Enter Email Address"
                                name="email"
                                id="email"
                                maxLength={70}
                                onBlur={this.handleBlurChange}
                                onChange={this.handleChange101}
                                value={this.state.email}
                                disabled={
                                  // this.state.codeMatchedStatus ||
                                  this.state.btnDisabled
                                    ? // this.state.counter == 0
                                      true
                                    : false
                                }
                              />
                            </>
                          )}
                          <div style={{ color: "red" }}>
                            {this.state.countryCode == "+91"
                              ? this.state.mobileError
                              : this.state.emailError}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Verification Code</label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Verification Code"
                              name="VerificationCode"
                              onChange={this.handlechange}
                              onKeyDown={this.handleValidation}
                              disabled={
                                this.state.codeMatchedStatus ||
                                this.state.buttonDisableVerification
                              }
                              onBlur={this.onBlurVerificationCode.bind(this)}
                              value={this.state.VerificationCode}
                              aria-label="Input group example"
                              aria-describedby="btnGroupAddon"
                            />

                            {!this.state.codeMatchedStatus && (
                              <>
                                <div className="input-group-prepend">
                                  <button
                                    className="btn btn_man"
                                    id="btnGroupAddon"
                                    onClick={this.getCode}
                                    disabled={
                                      this.state.codeMatchedStatus ||
                                      this.state.btnDisable
                                    }
                                  >
                                    {this.state.timerText}
                                    {"  "}
                                    {this.state.counter == 0
                                      ? this.state.textForCode
                                      : this.state.counter}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.verificationError}
                          </span>
                        </div>
                        {!this.state.codeMatchedStatus && (
                          <div className="col-12">
                            <div className="form-group mx-auto submit-btn">
                              <button className="btn w100 btn_man">
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                      </form>
                      {this.state.codeMatchedStatus && (
                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-sm-6 col-12">
                              <div className="form-group">
                                <label>Password</label>
                                <div className="eye_pass">
                                  <input
                                    className="form-control"
                                    type={this.state.type}
                                    name="password"
                                    id="Password"
                                    placeholder="Enter Password"
                                    onBlur={this.handleBlurChange}
                                    maxLength={30}
                                    value={this.state.Password}
                                    onChange={this.handleChange101}
                                  />
                                  <span
                                    className="password__show eye1"
                                    onClick={this.handleClick}
                                  >
                                    {this.state.type === "text" ? (
                                      <i className="las la-eye"></i>
                                    ) : (
                                      <i className="las la-low-vision"></i>
                                    )}
                                  </span>
                                  {this.state.PasswordIssue && (
                                    <div style={{ color: "red" }}>
                                      {this.state.PasswordIssue}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-12">
                              <div className="form-group">
                                <label>Confirm Password</label>
                                <div className="form-group eye_pass">
                                  <input
                                    className="form-control"
                                    type={this.state.type1}
                                    placeholder="Enter Confirm Password"
                                    onBlur={this.handleBlurChange}
                                    maxLength={30}
                                    name="cpassword"
                                    id="CPassword"
                                    onChange={this.handleChange101}
                                    value={this.state.CPassword}
                                  />
                                  <span
                                    className="password__show eye1"
                                    onClick={this.handleClick1}
                                  >
                                    {this.state.type1 === "text" ? (
                                      <i className="las la-eye"></i>
                                    ) : (
                                      <i className="las la-low-vision"></i>
                                    )}
                                  </span>

                                  {this.state.CPasswordIssue && (
                                    <div style={{ color: "red" }}>
                                      {this.state.CPasswordIssue}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Referrer Code(Optional) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Referrer Code (Optional)"
                              name="ReferId"
                              onChange={this.handlechange}
                              value={this.state.ReferId}
                            />
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox text-left">
                              <input
                                className="custom-control-input"
                                id="rememberPasswordCheck"
                                type="checkbox"
                                name="check1"
                                onChange={this.handleChange1}
                                value={this.state.check1}
                              />
                              <label
                                className="custom-control-label rember"
                                htmlFor="rememberPasswordCheck"
                              >
                                I agree to the{" "}
                                {/* <Link
                                  to="/terms-&-conditions-of-crypto-exchange"
                                  className="login-links"
                                > */}
                                <a
                                  href="/terms-&-conditions-of-crypto-exchange"
                                  className="login-links"
                                  target="_blank"
                                >
                                  {" "}
                                  <u>Terms & Conditions</u>
                                  {/* </Link> */}
                                </a>
                                <span className="regStart">*</span>{" "}
                              </label>
                            </div>
                            <span style={{ color: "red" }}>
                              {this.state.messageCheck1}
                            </span>
                          </div>
                          <div className="form-group">
                            <button className="btn w100 btn_man">
                              SIGN UP
                            </button>
                          </div>
                        </form>
                      )}
                      <div class="text-center ">
                        Already have an account?
                        <Link to="/login" className="login-links">
                          {" "}
                          LOGIN
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}
export default Register;
