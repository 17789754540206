import React, { useEffect, useState } from "react";
import axios from "axios";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../../Common/BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import { EmailValidation } from "../../Validation/EmailValidation";
import { MobileValidation } from "../../Validation/MobileValidation";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import { meta } from "../../Common/Meatdata";
import { ProductName } from "../../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const RegisterFunction = (props) => {
  const [email, setEmail] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [CPassword, setCPassword] = useState("");
  const [Password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [counter, setCounter] = useState(0);
  const [ReferId, setReferId] = useState("");
  const [timerText, setTimerText] = useState("");
  const [check1, setCheck1] = useState("0");
  const [countryCode, setCountryCode] = useState("+91");
  const [PasswordIssue, setPasswordIssue] = useState("");
  const [CPasswordIssue, setCPasswordIssue] = useState("");
  const [codeMatchedStatus, setCodeMatchedStatus] = useState(false);
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);
  const [messageCheck1, setMessageCheck1] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/";
      return false;
    }

    meta.title = `Signup | ${ProductName} Exchange`;
    meta.description = `Signup in ${ProductName} Exchange to enjoy its features`;

    if (props && props.referId !== undefined) {
      setReferId(props.referId);
    }

    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
  }, []);

  const referCode = () => {
    let registerData = {
      email: email,
    };
    axios
      .post(baseUrl + "/frontapi/generateReferalCode", registerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setTimerText("Resend In");
      setBtnDisabled(true);
      counter--;
      if (counter < 0) {
        clearInterval(interval);

        setTextForCode("Resend Code");
        setBtnDisabled(false);
        setTimerText("");
      }
    }, 1000);
  };

  const handleCountryCode = (countryCode) => {
    setCountryCode("+" + countryCode);
    setEmailError("");
    setMobileError("");
    setVerificationError("");
    setMobile("");
    setEmail("");
  };

  const getCode = async (event) => {
    event.preventDefault();

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (countryCode !== "+91") {
      if (!email) {
        setEmailError("Email address is required");

        return false;
      }

      if (!email.match(emailReg)) {
        setEmailError("Please enter a valid email address");

        return false;
      }

      if (emailError) {
        return false;
      }
    }
    if (countryCode == "+91") {
      if (!mobile) {
        setMobileError("Mobile number is required");
        return false;
      }

      const firstDigit = parseInt(mobile.charAt(0));

      // Check if the first digit is 6 or greater than 6
      if (firstDigit < 5) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }

      if (mobileError) {
        return false;
      }
    }

    let registerData = {
      countryCode: countryCode,
      page: "register",
    };
    if (countryCode == "+91") {
      registerData.mobile = mobile;
    }
    if (countryCode !== "+91") {
      registerData.email = email;
    }

    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((resp) => {
        // eslint-disable-next-line
        var resp = resp.data;
        if (resp.status === false) {
          toast.error(resp.EmailMessage);
        }

        if (resp.status === true) {
          setVerificationError("");

          startCountdown(30);
          toast.dismiss();
          toast.success(resp.EmailMessage);
          setButtonDisableVerification(false);
          return false;
        }
        if (resp.status === false) {
          setButtonDisableVerification(true);
          toast.error(resp.message);
          return false;
        }
      });
  };

  const handleChange1 = (event) => {
    var messageCheck1 = "";
    if (event.target.checked == false) {
      messageCheck1 = "Please select terms & conditions";
      setCheck1("0");
      setMessageCheck1(messageCheck1);
    }
    if (event.target.checked == true) {
      setCheck1("1");
      setMessageCheck1(messageCheck1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let registerData = {
      countryCode: countryCode,
      VerificationCode: VerificationCode,
      Password: Password,
      CPassword: CPassword,
      ReferId: ReferId,
      terms: check1,
      email: "",
      mobile: "",
    };
    if (mobile) {
      registerData.mobile = mobile;
    }
    if (email && email.length > 0) {
      registerData.email = email.toLowerCase();
    }
    var passwordError = PasswordValidation("Password", Password);
    setPasswordIssue(passwordError);

    if (passwordError == "mismatched") {
      setCPasswordIssue("Confirm password does not match");
      setPasswordIssue("");

      if (passwordError == "") {
        setCPasswordIssue("");
        setPasswordIssue("");
      }
    }
    var CpasswordError = PasswordValidation("CPassword", CPassword);
    setCPasswordIssue(CpasswordError);

    if (passwordError || CpasswordError) {
      return false;
    }

    if (check1 == "0" || check1 == undefined) {
      setMessageCheck1("Please select terms & conditions");

      return false;
    }
    axios.post(baseUrl + "/frontapi/register", registerData).then((resp) => {
      var resp = resp.data;
      if (resp.status === true) {
        setMobile("");
        setEmail("");

        referCode();
        cancelCourse();
        toast.success(
          "Congratulations, your account has been registered successfully"
        );
        setTimeout(() => {
          window.location = "/login";
        }, 2000);
        return false;
      }
      if (resp.status === false) {
        toast.error(resp.message);
        return false;
      }
    });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handlechange = (event) => {
    let { name, value } = event.target;

    if (name === "VerificationCode") {
      setVerificationCode(value);
      if (!value) {
        setVerificationError("This field is required");
        return;
      } else {
        setVerificationError("");
      }
    }
    if (name === "ReferId") {
      setReferId(value);
    }
  };

  const handlechangeCheck = (event) => {
    var check1 = event.target.checked;
    var messageCheck1 = "";
    if (!check1) {
      messageCheck1 = "Terms checked required";
    }
    setCheck1(check1);
    setMessageCheck1(messageCheck1);
  };

  const handleClick = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  const handleClick1 = () => {
    if (type1 === "text") {
      setType1("password");
    } else {
      setType1("text");
    }
  };

  const handleChange101 = (event) => {
    let { name, value } = event.target;

    if (name == "email") {
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setTextForCode("Get Code");
      setVerificationCode("");
      setEmailError(emailvalidation);
    }
    if (name == "mobile") {
      setMobile(value);
      const mobilevalidation = MobileValidation(name, value, "Register");
      setTextForCode("Get Code");
      setVerificationCode("");
      setMobileError(mobilevalidation);
    }
    // if (name == "verification_code") {
    //   setVerificationError("This field is required");
    // }
    if (name == "password") {
      setPassword(value);
      var passwordError = PasswordValidation(name, value);
      setPasswordIssue(passwordError);

      if (passwordError === "mismatched") {
        setPasswordIssue("");
        setCPasswordIssue("Confirm password does not match");
      }
      if (passwordError === "") {
        setPasswordIssue("");
        setCPasswordIssue("");
      }
    }

    if (name == "cpassword") {
      setCPassword(value);
      var CpasswordError = PasswordValidation(name, value);
      setCPasswordIssue(CpasswordError);

      if (passwordError == "") {
        setCPasswordIssue("");
      }
    }
  };

  const cancelCourse = () => {
    setVerificationCode("");
    setEmail("");
    setPassword("");
    setCPassword("");
    setReferId("");
  };

  const handleMobileSubmit = (e) => {
    e.preventDefault();
    let data = {
      countryCode: countryCode,
      verification_code: VerificationCode,
    };
    if (countryCode == "+91") {
      const mobilevalidation = MobileValidation("mobile", mobile, "Register");

      if (!mobile) {
        setMobileError("Mobile number is required");
        return false;
      }

      var regexNum = /^([0|\+[0-9]{1,10})?([0-9]{5})$/;
      if (!mobile.match(regexNum)) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }

      if (mobile.length !== 10) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }
      setMobileError(mobilevalidation);
      data.mobile = mobile;
    } else {
      const emailvalidation = EmailValidation("email", email);
      setEmailError(emailvalidation);
      data.email = email;
    }

    if (!VerificationCode) {
      setVerificationError("This field is required");
      return false;
    }

    axios.post(baseUrl + "/frontapi/codeVerify", data).then((res) => {
      var resp = res.data;
      if (resp.status) {
        setBtnDisabled(resp.status);

        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }

      setCodeMatchedStatus(resp.status);
      setBtnDisabled(resp.status);
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div className="">
        <div className="register-main-outer signup-main-outer position-relative d-flex align-items-center flex-wrap">
          <span className="main-bg fadeInRight animated"></span>
          <div className="register-inner">
            <div className="row m-0 ">
              <div className="col-12 p-0">
                <div className="register-right fadeInLeft animated ">
                  <div className="heading-link-outer">
                    <div className="heading">
                      <h2 className="m-0">Sign Up</h2>
                    </div>
                  </div>

                  <div className="register-form signup-form">
                    <form onSubmit={handleMobileSubmit}>
                      {countryCode == "+91" ? (
                        <label
                        // style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Mobile Number
                        </label>
                      ) : (
                        <label
                        // style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Email Address
                        </label>
                      )}

                      <div className="form-group position-relative">
                        <PhoneInput
                          className="country-code position-absolute"
                          countryCodeEditable={false}
                          country={"in"}
                          enableSearch={true}
                          disableCountryCode={true}
                          disableDropdown={
                            codeMatchedStatus || counter == 0 ? false : true
                          }
                          value={countryCode}
                          disabled={codeMatchedStatus}
                          onChange={handleCountryCode}
                        />
                        {countryCode == "+91" ? (
                          <>
                            <input
                              className="form-control country-input"
                              type="number"
                              placeholder="Enter Mobile Number"
                              name="mobile"
                              id="mobile"
                              onKeyDown={handleValidation}
                              maxLength={20}
                              onChange={handleChange101}
                              value={mobile}
                              disabled={
                                codeMatchedStatus
                                  ? codeMatchedStatus
                                  : counter == 0
                                  ? false
                                  : true
                              }
                            />
                          </>
                        ) : (
                          <>
                            <input
                              className="form-control country-input"
                              type="text"
                              placeholder="Enter Email Address"
                              name="email"
                              id="email"
                              maxLength={70}
                              onChange={handleChange101}
                              value={email}
                              disabled={
                                // codeMatchedStatus ||
                                btnDisabled
                                  ? // counter == 0
                                    true
                                  : false
                              }
                            />
                          </>
                        )}
                        <div style={{ color: "red" }}>
                          {countryCode == "+91" ? mobileError : emailError}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Verification Code</label>
                        <div className="get-outer position-relative">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Verification Code"
                            name="VerificationCode"
                            onChange={handlechange}
                            onKeyDown={handleValidation}
                            disabled={
                              codeMatchedStatus || buttonDisableVerification
                            }
                            value={VerificationCode}
                            aria-label="Input group example"
                            aria-describedby="btnGroupAddon"
                          />

                          {!codeMatchedStatus && (
                            <>
                              <div className="get-btn position-absolute">
                                <button
                                  className="btn btn_man"
                                  id="btnGroupAddon"
                                  onClick={getCode}
                                  disabled={codeMatchedStatus || btnDisabled}
                                >
                                  {timerText}
                                  {"  "}
                                  {counter == 0 ? textForCode : counter}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        <span style={{ color: "red" }}>
                          {verificationError}
                        </span>
                      </div>
                      {!codeMatchedStatus && (
                        <div className="form-group mx-auto submit-btn">
                          <button className="btn w100 btn_man">Submit</button>
                        </div>
                      )}
                    </form>
                    {codeMatchedStatus && (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Password</label>
                              <div className="eye_pass">
                                <input
                                  className="form-control"
                                  type={type}
                                  name="password"
                                  id="Password"
                                  placeholder="Enter Password"
                                  maxLength={30}
                                  value={Password}
                                  onChange={handleChange101}
                                />
                                <span
                                  className="password__show eye1"
                                  onClick={handleClick}
                                >
                                  {type === "text" ? (
                                    <i className="las la-eye"></i>
                                  ) : (
                                    <i className="las la-low-vision"></i>
                                  )}
                                </span>
                                {PasswordIssue && (
                                  <div style={{ color: "red" }}>
                                    {PasswordIssue}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <div className="form-group eye_pass">
                                <input
                                  className="form-control"
                                  type={type1}
                                  placeholder="Enter Confirm Password"
                                  maxLength={30}
                                  name="cpassword"
                                  id="CPassword"
                                  onChange={handleChange101}
                                  value={CPassword}
                                />
                                <span
                                  className="password__show eye1"
                                  onClick={handleClick1}
                                >
                                  {type1 === "text" ? (
                                    <i className="las la-eye"></i>
                                  ) : (
                                    <i className="las la-low-vision"></i>
                                  )}
                                </span>

                                {CPasswordIssue && (
                                  <div style={{ color: "red" }}>
                                    {CPasswordIssue}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Referral Code(Optional) </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Referral Code (Optional)"
                            name="ReferId"
                            onChange={handlechange}
                            value={ReferId}
                          />
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-checkbox text-left">
                            <input
                              className="custom-control-input"
                              id="rememberPasswordCheck"
                              type="checkbox"
                              name="check1"
                              onChange={handleChange1}
                              value={check1}
                            />
                            <label
                              className="custom-control-label rember"
                              htmlFor="rememberPasswordCheck"
                            >
                              I agree to the{" "}
                              {/* <Link
                              to="/terms-&-conditions-of-crypto-exchange"
                              className="login-links"
                            > */}
                              <a
                                href="/terms-&-conditions-of-crypto-exchange"
                                className="login-links"
                                target="_blank"
                              >
                                {" "}
                                <u>Terms & Conditions</u>
                                {/* </Link> */}
                              </a>
                              <span className="regStart">*</span>{" "}
                            </label>
                          </div>
                          <span style={{ color: "red" }}>{messageCheck1}</span>
                        </div>
                        <div className="form-group">
                          <button className="btn w100 btn_man">SIGN UP</button>
                        </div>
                      </form>
                    )}
                    <div class="text-center ">
                      Already have an account?
                      <Link to="/login" className="login-links">
                        {" "}
                        LOGIN
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default RegisterFunction;
