import React from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "./BaseUrl";
import axios from "axios";

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.urlPath,
      balance: 0,
      virtualFund: 0,
      Rebalance: 0,
      nickname: "Guest",
      Firstname: "",
      email: "",
      checked: false,
      urlLink: "",
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillMount() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }

    var theme = localStorage.getItem("theme");
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      this.handleChange(theme);
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    if (token) {
      this.getUser();
    }
    this.urlData();
  }

  getUser = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;

      if (resp.data[0].is_deleted == "1") {
        localStorage.clear();
      }

      if (resp.data[0].nickname == "null" || resp.data[0].nickname == null) {
        this.setState({
          nickname: "Guest",
          email: resp.data[0].email,
          AccHolder: resp.data[0].AccHolder,
        });
      } else {
        this.setState({
          nickname: resp.data[0].nickname,
          email: resp.data[0].email,
          AccHolder: resp.data[0].AccHolder,
        });
      }
    });
  };

  urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((resp) => {
      var resp = resp.data;
      this.setState({
        urlLink: `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`,
      });
    });
  };

  logout = () => {
    localStorage.clear();
    // this.props.history.push("/login");
    window.location.href = "/login";
    // window.location.href = "/login";
  };

  handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      this.setState({ checked: false });
    } else {
      tags[0].classList.remove("dark-theme");
      this.setState({ checked: true });
    }
    localStorage.setItem("theme", checked);
  };

  showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null && window.location.pathname == "/forgot") {
      return (
        <ul className="">
          <li className="">
            <Link to="/login">Log in</Link>
          </li>
          <li className="">
            <Link to="/register">Sign up</Link>
          </li>
        </ul>
      );
    }
    if (token === null && window.location.pathname !== "/forgot") {
      return (
        <ul className="">
          {/* <li className="">
            <Link to="/login-to-buy-sell-crypto">Log in</Link>
          </li>
          <li className="">
            <Link to="/register">Sign up</Link>
          </li> */}
        </ul>
      );
    } else {
      return (
        <ul className="ment_right ml-auto ">
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                {this.state.AccHolder ? this.state.AccHolder : this.state.email}
              </span>
              <div className="dropdown-menu2">
                <Link className="a_flex" to="/profile">
                  <i class="fa fa-user-circle mr-1"></i> Profile
                </Link>
                {/* <Link className="a_flex" to="/mainWalletTransactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}
                <Link className="a_flex" to="/Kyc">
                  <i className="las la-share-square"></i> My KYC
                </Link>
                <Link className="a_flex" to="/myStacking">
                  <i className="fa fa-stack-exchange"></i> My Staking
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="las la-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={this.logout}>
                  <i className="las  la-sign-in-alt"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };
  // logout = () => {
  //   var theme = localStorage.getItem("theme");
  //   localStorage.clear();
  //   localStorage.setItem("theme", theme);
  //   sessionStorage.clear();
  //   window.location.reload();
  // };
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  render() {
    var token2 = localStorage.getItem("token");
    return (
      <>
        <nav className="navbar navbar-expand-lg in_header">
          <div className="container">
            <a className="navbar-brand navbar-brand2" href="/">
              <img
                src="/assets/img/logo2.png"
                alt="Logo"
                className="logo logo1"
              />
              <img
                src="/assets/img/logo2.png"
                alt="Logo"
                className="logo logo2"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              <span className="icon-bar">
                <i className="fa fa-bars fa-2x"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav  line align-items-center">
                <li
                  className={
                    window.location.pathname ==
                    "/exchange/" + this.state.urlLink
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  {!token2 ? (
                    <a
                      className="nav-link "
                      href={"/exchange/" + this.state.urlLink}
                    >
                      Exchange
                    </a>
                  ) : (
                    <a
                      className="nav-link "
                      href={"/exchange/" + this.state.urlLink}
                    >
                      Exchange
                    </a>
                  )}
                </li>

                <li className="nav-item">
                  {this.state.checked == false ? (
                    <img
                      src="/assets/img/moon.png"
                      width="25px"
                      alt="moon"
                      className="moon-img theme-img"
                      onClick={() => this.handleChange(this.state.checked)}
                      checked={this.state.checked}
                    />
                  ) : (
                    <img
                      src="/assets/img/sun.png"
                      width="25px"
                      alt="sun"
                      className="sun-img theme-img"
                      onClick={() => this.handleChange(this.state.checked)}
                      checked={this.state.checked}
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="dashbord_menu">{this.showHeaderPart()}</div>
          </div>
        </nav>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
          >
            <img src="assets/img/v_kyc.png" />
            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
              Verified Your KYC
            </h2>
            <button onClick={this.closeModal}>close</button>
            <div>I am a modal</div>
          </Modal>
        </div>
      </>
    );
  }
}

export default withRouter(MainHeader);
