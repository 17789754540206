import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "../Common/MainHeader";
import $ from "jquery";
import Nav from "../Common/Nav";
const baseUrl = myConstList.baseUrl;

const ApplyListing = () => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  const [issuePrice, setIssuePrice] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [explorer, setExplorer] = useState("");
  const [website, setWebsite] = useState("");
  const [document, setDocument] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  //error

  const [nameErr, setNameErr] = useState("");
  const [shortNameErr, setShortNameErr] = useState("");
  const [logoErr, setLogoErr] = useState("");
  const [issuePriceErr, setIssuePriceErr] = useState("");
  const [issueDateErr, setIssueDateErr] = useState("");
  const [maxSupplyErr, setMaxSupplyErr] = useState("");
  const [circulatingSupplyErr, setCirculatingSupplyErr] = useState("");
  const [explorerErr, setExplorerErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [documentErr, setDocumentErr] = useState("");
  const [introductionErr, setIntroductionErr] = useState("");
  const [userUploadLogo, setUserUploadLogo] = useState("");

  useEffect(() => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;

      if (resp.data[0].kycVerification_status !== "Y") {
        toast.dismiss();
        toast.error("Please complete KYC first");

        setTimeout(() => {
          window.location.href = "/launchpad";
        }, 3000);
      }
    });
  }, []);

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });

    if (name === "name") {
      // const newValue = value.toUpperCase();
      setName(value);

      if (!value) {
        setNameErr("Please enter token name");
        return false;
      }
      setNameErr("");
    }

    if (name === "shortName") {
      const newValue = value.toUpperCase();
      setShortName(newValue);

      if (!newValue) {
        setShortNameErr("Please enter short name");
        return false;
      }
      setShortNameErr("");
    }

    if (name === "maxSupply") {
      setMaxSupply(value);
      if (!value) {
        setMaxSupplyErr("Please enter max supply");
        return false;
      }
      setMaxSupplyErr("");
    }

    if (name === "circulatingSupply") {
      setCirculatingSupply(value);
      if (!value) {
        setCirculatingSupplyErr("Please enter circulating supply");
        return false;
      }
      setCirculatingSupplyErr("");
    }

    if (name === "issuePrice") {
      setIssuePrice(value);
      if (!value) {
        setIssuePriceErr("Please enter issue price");
        return false;
      }
      setIssuePriceErr("");
    }

    if (name === "issueDate") {
      setIssueDate(value);
      if (!value) {
        setIssueDateErr("Please enter issue date");
        return false;
      }
      setIssueDateErr("");
    }

    if (name === "document") {
      setDocument(e.target.files[0]);

      var temp_doc = e.target.files[0];

      if (temp_doc === false) {
        setDocumentErr("Please select document");
        return false;
      }

      if (!temp_doc.name.match(/\.(pdf)$/)) {
        setDocumentErr("Please select valid input pdf");
        return false;
      }

      if (temp_doc.size >= 5242880 || temp_doc.size < 10240) {
        setDocumentErr("Docment size should be 10KB to 5MB");
        return false;
      }

      setDocumentErr("");
      // setUserUploadDoc(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "logo") {
      setLogo(e.target.files[0]);

      var temp_logo = e.target.files[0];

      if (temp_logo === false) {
        setUserUploadLogo(false);
        setLogoErr("Please select logo");
        return false;
      }

      if (!temp_logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setUserUploadLogo(false);
        setLogoErr("Please select valid input jpg,jpeg,png");
        return false;
      }

      if (temp_logo.size >= 2048000 || temp_logo.size < 10240) {
        setLogoErr("Logo image size should be 10KB to 2MB");
        return false;
      }

      setLogoErr("");
      setUserUploadLogo(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "explorer") {
      setExplorer(value);
      if (!value) {
        setExplorerErr("Please enter explorer link");
        return false;
      }
      setExplorerErr("");
    }

    if (name === "website") {
      setWebsite(value);
      if (!value) {
        setWebsiteErr("Please enter website link");
        return false;
      }
      setWebsiteErr("");
    }

    if (name === "introduction") {
      setIntroduction(value);
      if (!value) {
        setIntroductionErr("Please enter introduction");
        return false;
      }
      setIntroductionErr("");
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = (e) => {
    e.preventDefault();

    if (
      !name &&
      !shortName &&
      !issuePrice &&
      !issueDate &&
      !maxSupply &&
      !circulatingSupply &&
      !explorer &&
      !website &&
      !document &&
      !logo &&
      !introduction
    ) {
      setNameErr("Please enter token name");
      setShortNameErr("Please enter short name");
      setIssuePriceErr("Please enter issue price");
      setIssueDateErr("Please enter issue date");
      setMaxSupplyErr("Please enter max supply");
      setCirculatingSupplyErr("Please enter circulating supply");
      setWebsiteErr("Please enter website link");
      setExplorerErr("Please enter explorer link");
      setDocumentErr("Please select document");
      setLogoErr("Please select logo");
      setIntroductionErr("Please enter introduction");
      return false;
    }

    if (!name) {
      setNameErr("Please enter token name");
      return false;
    }

    let errorName = checkSpace("token name", name);
    if (errorName.length > 0) {
      setNameErr(errorName);
      return false;
    }

    if (!shortName) {
      setShortNameErr("Please enter short name");
      return false;
    }

    errorName = checkSpace("short name", shortName);
    if (errorName.length > 0) {
      setShortNameErr(errorName);
      return false;
    }

    if (!issuePrice) {
      setIssuePriceErr("Please enter issue price");
      return false;
    }

    if (issuePrice <= 0) {
      setIssuePriceErr("Please enter valid issue price");
      return false;
    }

    if (!issueDate) {
      setIssueDateErr("Please enter issue date");
      return false;
    }

    if (!maxSupply) {
      setMaxSupplyErr("Please enter max supply");
      return false;
    }

    if (maxSupply <= 0) {
      setMaxSupplyErr("Please enter valid max supply");
      return false;
    }

    if (!circulatingSupply) {
      setMaxSupplyErr("Please enter circulating supply");
      return false;
    }

    if (circulatingSupply <= 0) {
      setMaxSupplyErr("Please enter valid circulating supply");
      return false;
    }

    if (!explorer) {
      setExplorerErr("Please enter explorer link");
      return false;
    }

    errorName = checkSpace("explorer link", explorer);
    if (errorName.length > 0) {
      setExplorerErr(errorName);
      return false;
    }

    if (!website) {
      setWebsiteErr("Please enter website link");
      return false;
    }

    errorName = checkSpace("website", website);
    if (errorName.length > 0) {
      setWebsiteErr(errorName);
      return false;
    }

    if (!document) {
      setDocumentErr("Please select document");
      return false;
    }

    if (!document.name.match(/\.(pdf)$/)) {
      setDocumentErr("Please select valid input pdf");
      return false;
    }

    if (document.size >= 5242880 || document.size < 10240) {
      setDocumentErr("Document size should be 10KB to 5MB");
      return false;
    }

    if (!logo) {
      setLogoErr("Please select logo");
      return false;
    }

    if (!logo.name.match(/\.(jpg|jpeg|png)$/)) {
      setLogoErr("Please select valid input jpg,jpeg,png");
      return false;
    }

    if (logo.size >= 2048000 || logo.size < 10240) {
      setLogoErr("Logo size should be 10KB to 2MB");
      return false;
    }

    if (!introduction) {
      setIntroductionErr("Please enter introduction");
      return false;
    }

    errorName = checkSpace("introduction", introduction);
    if (errorName.length > 0) {
      setIntroductionErr(errorName);
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    setBtnDisable(true);

    const formData = new FormData();
    formData.append("Logo", logo);
    formData.append("Document", document);

    formData.append("tokenName", name);
    formData.append("shortName", shortName);
    formData.append("issuePrice", issuePrice);
    formData.append("issueDate", issueDate);
    formData.append("maxSupply", maxSupply);
    formData.append("circulatingSupply", circulatingSupply);
    formData.append("explorer", explorer);
    formData.append("website", website);
    formData.append("introduction", introduction);

    axios
      .post(baseUrl + "/frontapi/listing", formData, config)
      .then((resp) => {
        var res = resp.data;

        if (res.status === true) {
          toast.dismiss();
          toast.success(res.message);

          setTimeout(() => {
            window.location.href = "/listing";
          }, 2000);
          return false;
        } else {
          toast.dismiss();
          toast.error(res.message);
          if (res.message == "Failed to authenticate token.") {
            setTimeout(() => {
              window.location.href = "/login";
            }, 2500);
            return false;
          }
          setBtnDisable(false);
        }
      })
      .finally(() => setBtnDisable(false));
  };

  return (
    <div>
      <Nav graphColor="" />
      {/* <MainHeader /> */}
      <div className="trade-volume">
        <div className="container">
          <div className="section-heading text-center">
            <h2>Add Coin/Token</h2>
          </div>
        </div>
      </div>
      <div className="listing-main">
        <div className="container">
          <div className="listing-outer">
            <div className="listing-form">
              <form>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Coin/Token Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Coin/Token Name"
                        className="form-control"
                        onChange={handleChange101}
                        value={name}
                      />
                      <span style={{ color: "red" }}>{nameErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Short Name</label>
                      <input
                        type="text"
                        name="shortName"
                        onChange={handleChange101}
                        value={shortName}
                        placeholder="Enter Short Name"
                        className="form-control"
                      />
                      <span style={{ color: "red" }}>{shortNameErr}</span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Issue Price</label>
                      <input
                        type="number"
                        name="issuePrice"
                        placeholder="Issue Price"
                        className="form-control"
                        onChange={handleChange101}
                        value={issuePrice}
                        onKeyDown={handleValidation}
                      />
                      <span style={{ color: "red" }}>{issuePriceErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Issue Date</label>
                      <input
                        type="date"
                        name="issueDate"
                        placeholder="Issue Date"
                        className="form-control"
                        onChange={handleChange101}
                        value={issueDate}
                      />
                      <span style={{ color: "red" }}>{issueDateErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Max Supply</label>
                      <input
                        type="number"
                        name="maxSupply"
                        placeholder="Max Supply"
                        className="form-control"
                        onChange={handleChange101}
                        value={maxSupply}
                        onKeyDown={handleValidation}
                      />
                      <span style={{ color: "red" }}>{maxSupplyErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Circulating Supply</label>
                      <input
                        type="number"
                        name="circulatingSupply"
                        placeholder="Circulating Supply"
                        className="form-control"
                        onChange={handleChange101}
                        value={circulatingSupply}
                        onKeyDown={handleValidation}
                      />
                      <span style={{ color: "red" }}>
                        {circulatingSupplyErr}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Explorer Link</label>
                      <input
                        type="text"
                        name="explorer"
                        placeholder="Explorer Link"
                        className="form-control"
                        onChange={handleChange101}
                        value={explorer}
                      />
                      <span style={{ color: "red" }}>{explorerErr}</span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Website Link</label>
                      <input
                        type="text"
                        name="website"
                        placeholder="Website Link"
                        className="form-control"
                        onChange={handleChange101}
                        value={website}
                      />
                      <span style={{ color: "red" }}>{websiteErr}</span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Logo </label>
                      <input
                        type="file"
                        name="logo"
                        onChange={handleChange101}
                        accept="image/*"
                        placeholder=""
                        className="form-control"
                      />
                      {userUploadLogo && (
                        <img
                          id="file-image"
                          src={userUploadLogo != false ? userUploadLogo : ""}
                          alt="Preview"
                          className="img-fluid"
                        />
                      )}
                      <span style={{ color: "red" }}>{logoErr}</span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>White PP Document</label>
                      <input
                        type="file"
                        name="document"
                        placeholder=""
                        onChange={handleChange101}
                        accept=".pdf"
                        className="form-control"
                      />
                      <span style={{ color: "red" }}>{documentErr}</span>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label>Introduction</label>
                      <textarea
                        name="introduction"
                        placeholder="Introduction"
                        className="form-control"
                        onChange={handleChange101}
                        value={introduction}
                      ></textarea>
                      <span style={{ color: "red" }}>{introductionErr}</span>
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <div className="btn-group save-cancel">
                      <button
                        type="submit"
                        class="btn btn-success"
                        onClick={submit}
                        disabled={btnDisable}
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        class="btn btn-danger"
                        onClick={() => {
                          window.location.href = "/listing";
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ApplyListing;
