import React, { useState, useEffect } from "react";
import axios from "axios";
import { ImageValidation } from "../Validation/ImageValidation";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { aadhaarImageApi, aadhaarNumberApi } from "./Apis";
import { ThirdPartyApi } from "./ThirdPartyApis";
import { ConsoleView } from "react-device-detect";
import Rejectreason from "./Rejectreason";
const baseUrl = myConstList.baseUrl;

const Aadhaar = (props) => {
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [image2, setImage2] = useState("/assets/img/icon2.png");
  const [userInfo, setUserInfo] = useState([]);
  const [userInfoLength, setUserInfoLength] = useState(0);
  const [aadhaarFront, setAadhaarFront] = useState("");
  const [useruploadAadhaarBack, setUseruploadAadhaarBack] = useState("");
  const [useruploadAadhaarFront, setUseruploadAadhaarFront] = useState("");
  const [aadhaarBack, setAadhaarBack] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [aadhaarNumberError, setAadhaarNumberError] = useState("");
  const [aadhaarFrontError, setAadhaarFrontError] = useState("");
  const [aadhaarBackError, setAadhaarBackError] = useState("");
  const [aadhaarNumberStatus, setAadhaarNumberStatus] = useState(0);
  const [rejectionReason, setRejectionReason] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setUserInfo(props.userinfo);
      setUserInfoLength(props.userinfo.length);
      setAadhaarNumberStatus(props.userinfo[0].doc_type_status);
      setRejectionReason(props.userinfo[0].d1_rejectResion);
      props.userinfo[0].document_number &&
        setAadhaarNumber(props.userinfo[0].document_number);
      props.userinfo[0].i_image &&
        setImage(baseUrl + "/static/upload/" + props.userinfo[0].i_image);
      props.userinfo[0].i_image2 &&
        setImage2(baseUrl + "/static/upload/" + props.userinfo[0].i_image2);
    }
  }, [props]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "aadhaarNumber") {
      setAadhaarNumber(e.target.value);

      if (!value) {
        setAadhaarNumberError("This field is required");
        return false;
      }

      if (value.charAt(0) == 0) {
        setAadhaarNumberError("Please enter a valid aadhaar number");
        return false;
      }

      if (value.length < 12 || value.length > 12) {
        setAadhaarNumberError("Aadhaar number must be 12 digits");
        return false;
      }

      setAadhaarNumberError("");
      setAadhaarNumber(e.target.value);
    }
  };

  const handleImageChange = (e) => {
    let name = e.target.name;
    let value = e.target.files[0];
    if (name == "front") {
      let error = ImageValidation("Aadhaar front", value);

      // if (!req.files.file[0].mimetype == "image/jpeg") {
      //   res.send({
      //     status: false,
      //     message: "Please select valid image jpg,jpeg,png,gif",
      //     data: {},
      //   });
      //   return false;
      // }

      // if (req.files.file[0].size <= 10000) {
      //   res.send({
      //     status: false,
      //     message: "PAN card image size should not be less than 10KB",
      //     data: {},
      //   });
      //   return false;
      // }

      // if (req.files.file[0].size >= 2000000) {
      //   res.send({
      //     status: false,
      //     message: "PAN card image size should not be more than 2MB",
      //     data: {},
      //   });
      //   return false;
      // }

      setAadhaarFrontError(error);
      setAadhaarFront(e.target.files[0]);
      e.target.files[0]
        ? setUseruploadAadhaarFront(URL.createObjectURL(e.target.files[0]))
        : setUseruploadAadhaarFront("");
    }
    if (name == "back") {
      let error = ImageValidation("Aadhaar back", value);
      setAadhaarBackError(error);
      setAadhaarBack(e.target.files[0]);
      e.target.files[0]
        ? setUseruploadAadhaarBack(URL.createObjectURL(e.target.files[0]))
        : setUseruploadAadhaarBack("");
    }
  };

  const handleSubmit = async () => {
    if (!aadhaarNumber) {
      setAadhaarNumberError("This field is required");
      return false;
    }

    if (!aadhaarNumber) {
      setAadhaarNumberError("This field is required");
      return false;
    }

    if (aadhaarNumber.charAt(0) == 0) {
      setAadhaarNumberError("Please enter a valid aadhaar number");
      return false;
    }

    if (aadhaarNumber.length < 12 || aadhaarNumber.length > 12) {
      setAadhaarNumberError("Aadhaar number must be 12 digits");
      return false;
    }

    if (aadhaarNumber && aadhaarNumberError) {
      setAadhaarNumberError("");
    }
    if (!aadhaarNumber && aadhaarNumberError) {
      return false;
    }

    const allDigitsNotZero = !/^0*$/.test(aadhaarNumber);

    if (!allDigitsNotZero) {
      setAadhaarNumberError("All digits cannot be zero");
      return false;
    }

    setBtnDisable(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let DocNumber = {
      number: aadhaarNumber,
      check: "aadhaar",
    };
    let result = await aadhaarNumberApi(DocNumber, config);
    setTimeout(() => {
      setBtnDisable(false);
    }, 500);
    if (result.status) {
      setAadhaarNumberError("");
      toast.success(result.message);
      props.resendResult(true);
      window.location.reload();
    } else {
      toast.error(result.message);
      props.resendResult(true);
    }
  };
  const uploadImage = async (image) => {
    if (aadhaarNumberStatus == "0") {
      setAadhaarNumberError("Please save the aadhaar number first");
      return false;
    }

    if (!aadhaarFront && !aadhaarBack) {
      setAadhaarFrontError("This field is required");
      setAadhaarBackError("This field is required");
      return false;
    }
    if (!aadhaarFront) {
      setAadhaarFrontError("This field is required");
      return false;
    }

    if (!aadhaarBack) {
      setAadhaarBackError("This field is required");
      return false;
    }

    if (aadhaarBackError || aadhaarFrontError) {
      return false;
    }
    setBtnDisable(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const formData = new FormData();
    formData.append("IdentityProof", aadhaarFront);
    formData.append("IdentityProof2", aadhaarBack);

    let result = await aadhaarImageApi(formData, config);
    setTimeout(() => {
      setBtnDisable(false);
    }, 50);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
      window.location.reload();
      return false;
    } else {
      toast.error(result.message);
      props.resendResult(true);
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="aadhaar-outer" id="proof">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">Aadhaar Card Verification</h6>
              <b className="mt-0">Requirements</b>
              <ul className="dit_ul mb-3">
                <li>
                  The photo/scan must be clear, in color and uncompressed.
                </li>
                <li>The photo/scan must be not edited and not cropped.</li>
                <li>
                  The document must be valid at least 3 months after the
                  verification submission date.
                </li>
                <li>
                  The full name and date of birth you provided in the "Personal
                  information" section{" "}
                </li>
                <li>should exactly match the ones in your document.</li>
                <li>Min size 10 Kb / Max size 2 Mb.</li>
              </ul>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>Aadhaar Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Aadhaar Number"
                    name="aadhaarNumber"
                    min="10"
                    value={aadhaarNumber}
                    onKeyPress={handleValidation}
                    onChange={handleChange}
                    disabled={aadhaarNumberStatus !== "0"}
                  />
                  {aadhaarNumberError && (
                    <div className="text-danger">{aadhaarNumberError}</div>
                  )}
                  {aadhaarNumberStatus !== "1" && (
                    <div className="save-btn">
                      {" "}
                      <button
                        className="btn btn_man mt-3"
                        onClick={handleSubmit}
                        disabled={btnDisable || aadhaarNumberStatus !== "0"}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="fs12">
                <div className="row">
                  <div className="form-group col-md-4">
                    <div id="file-upload-form" className="uploader">
                      <input
                        type="file"
                        id="file-upload"
                        name="front"
                        accept="image/*"
                        onChange={handleImageChange}
                        // onBlur={this.onBlurIdentityImage}
                        disabled={
                          userInfoLength > 0 &&
                          userInfo[0].identity_status !== "0" &&
                          userInfo[0].identity_status !== "3"
                            ? true
                            : false
                        }
                      />
                      <label for="file-upload" id="file-drag">
                        <img
                          src={
                            useruploadAadhaarFront !== ""
                              ? useruploadAadhaarFront
                              : image
                          }
                          className="img-fluid22"
                          alt="Identity"
                        />
                        <div id="start">
                          <span className="fasi"></span>
                          <div>Front image</div>
                          <div id="notimage" className="hidden">
                            Please select an image
                          </div>
                        </div>
                        <div id="response" className="hidden">
                          <div id="messages"></div>
                          <progress
                            className="progress"
                            id="file-progress"
                            value="0"
                          >
                            <span>0</span>%
                          </progress>
                        </div>
                      </label>
                    </div>
                    <p className="mt-0">
                      *only jpeg, jpg &amp; png files supported
                    </p>
                    {aadhaarFrontError && (
                      <div className="text-danger">{aadhaarFrontError}</div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <div id="file-upload-form" className="uploader">
                      <input
                        type="file"
                        id="file-upload-2"
                        name="back"
                        accept="image/*"
                        onChange={handleImageChange}
                        disabled={
                          userInfoLength > 0 &&
                          userInfo[0].identity_status !== "0" &&
                          userInfo[0].identity_status !== "3"
                            ? true
                            : false
                        }
                      />
                      <label for="file-upload-2" id="file-drag">
                        <img
                          src={
                            useruploadAadhaarBack !== ""
                              ? useruploadAadhaarBack
                              : image2
                          }
                          className="img-fluid22"
                          alt="Identity"
                        />
                        <div id="start">
                          <span className="fasi"></span>
                          <div>Back image</div>
                          <div id="notimage" className="hidden">
                            Please select an image
                          </div>
                        </div>
                        <div id="response" className="hidden">
                          <div id="messages"></div>
                          <progress
                            className="progress"
                            id="file-progress"
                            value="0"
                          >
                            <span>0</span>%
                          </progress>
                        </div>
                      </label>
                    </div>
                    <p className="mt-0">
                      *only jpeg, jpg &amp; png files supported
                    </p>
                    {aadhaarBackError && (
                      <div className="text-danger">{aadhaarBackError}</div>
                    )}
                  </div>
                </div>

                <spans style={{ fontSize: "15px" }}>
                  <Rejectreason
                    reason={rejectionReason}
                    status={userInfoLength > 0 && userInfo[0].identity_status}
                  />
                </spans>
                {userInfoLength > 0 &&
                  (userInfo[0].identity_status == "3" ||
                    userInfo[0].identity_status == "0") && (
                    <button
                      className="btn btn_man fs12 w100px"
                      onClick={uploadImage}
                      disabled={
                        btnDisable ||
                        (userInfo[0].identity_status !== "0" &&
                          userInfo[0].identity_status2 !== "0" &&
                          userInfo[0].identity_status !== "3" &&
                          userInfo[0].identity_status2 !== "3")
                      }
                    >
                      Submit
                    </button>
                  )}

                {/* {this.imageStatushtml()} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aadhaar;
