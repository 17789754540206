import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../../Common/BaseUrl";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { meta } from "../../Common/Meatdata";
import { ProductName } from "../../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const LoginFunction = () => {
  const [urlLink, setUrlLink] = useState("");
  const [type, setType] = useState("password");
  const [LoginStatus, setLoginStatus] = useState(false);
  const [loginVia, setLoginVia] = useState("Password");
  const [handleState, setHandleState] = useState("");
  //   const [countryCode, setCountryCode] = useState("+91");
  const countryCode = "+91";
  const [timerText, setTimerText] = useState("Get Code");
  const [counter, setCounter] = useState(0);
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [device, setDevice] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [message, setMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [verification_code, setVerification_code] = useState("");
  const [vcode, setVcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [emailError, setEmailError] = useState("");

  async function getData() {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    localStorage.setItem("ip", data.ip ? data.ip : "");
  }

  useEffect(() => {
    meta.title = `Login | ${ProductName} Exchange`;
    meta.description = `Login into your account in ${ProductName} Exchange.`;
    DeviceName();
    getGeoInfo();

    if (localStorage.getItem("token")) {
      window.location.href = "/";
      return false;
    }

    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
    getData();
    urlData();
  }, []);

  const urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((res) => {
      var resp = res.data;

      setUrlLink(
        `/exchange/${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`
      );
    });
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
    }
    if (isAndroid) {
      setDevice("Android");
    }
    if (isIOS) {
      setDevice("IOS");
    }
    if (isDesktop) {
      setDevice("Desktop");
    }
  };

  const handleClick = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  const handleChange1 = (event) => {
    let { name, value } = event.target;

    if (name === "password") {
      setPassword(value);
      let error = "";
      if (!value) {
        error = "This field is required";
        setPasswordError(error);
        return false;
      }

      setPasswordError(error);
    }
    if (name === "verification_code") {
      setVerification_code(value);
      if (!value) {
        setVerificationCodeError("This field is required");

        return false;
      } else {
        setVerificationCodeError("");
      }
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const verifyCode = () => {
    if (!vcode) {
      setMessage("This field is required");
      return;
    }
    let LoginData = {
      Password: password,
      code: vcode,
    };
    if (mobile) {
      LoginData.mobile = mobile;
    }
    if (email) {
      LoginData.email = email;
    }

    axios
      .post(baseUrl + "/frontapi/verifyLoginGoogleCode", LoginData)
      .then((resp) => {
        toast.dismiss();
        if (resp.data.status === true) {
          toast.dismiss();
          toast.success(resp.data.message);

          localStorage.setItem("token", resp.data.token);
          // window.location.href = urlLink;
          window.location.href = "/";
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.data.message);
        }
      });
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setTimerText("Resend In");
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setTimerText("");
      }
    }, 1000);
  };

  const viaOTPClick = () => {
    if (loginVia === "Password") {
      setVerification_code("");
      setLoginVia("OTP");
      setMobileError("");
      setEmailError("");
      setPasswordError("");
      setVerificationCodeError("");
    } else {
      setLoginVia("Password");
      setMobileError("");
      setPasswordError("");
      setVerificationCodeError("");
    }
  };

  const getCode = () => {
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (!email && !mobile) {
      setEmailError("This field is required");
      return false;
    }

    if (emailError || mobileError) {
      // setEmailError("This field is required");
      return false;
    }

    let registerData = {
      countryCode: countryCode,
      page: "login",
    };
    if (email) {
      if (!email.match(emailReg)) {
        setEmailError("Please enter valid email address");
        return false;
      }

      registerData.email = email;
    }
    if (mobile) {
      registerData.mobile = mobile;
    }
    axios.post(baseUrl + "/frontapi/getcode", registerData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.error(resp.EmailMessage);
      }

      if (resp.status === true) {
        toast.success(resp.EmailMessage);
        startCountdown(30);

        setButtonDisableVerification(false);
        setVerificationCodeError("");
        // setVerification_code(resp.code)
        return false;
      }
      if (resp.status === false) {
        setButtonDisableVerification(true);
        toast.error(resp.message);
        return false;
      }
    });
  };

  const handleChange = (event) => {
    var value = event.target.value;

    setTextForCode("");
    setTimerText("Get Code");
    setVerification_code("");

    if (!value) {
      setEmailError("This field is required");
      setMobileError("");
      setEmail("");
      setHandleState("");
      setMobile("");
      return false;
    }
    setMobile(value);
    setHandleState(value);
    if (!isNaN(parseFloat(value)) && !isNaN(value - 0) == true) {
      //   setMobile(value);
      setEmail("");

      var phoneno = /^([0|\+[0-9]{1,10})?([0-9]{5})$/;
      if (value.match(phoneno)) {
        setMobileError("");
        setEmailError("");
      } else {
        setHandleState(value);
        setMobileError("Please enter valid mobile number");
        setEmailError("");
      }
      setMobile(value);
      setHandleState(value);
      setMobile(event.target.value);
    } else {
      setMobile("");
      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      setEmail(event.target.value.toLowerCase());

      if (!value.match(emailReg)) {
        setEmail(event.target.value.toLowerCase());
        setHandleState(event.target.value.toLowerCase());
        setMobile("");
        setEmailError("Please enter a valid email address");
        setMobileError("");

        return false;
      } else {
        setEmail(event.target.value.toLowerCase());
        setHandleState(event.target.value.toLowerCase());
        setMobile("");
        setEmailError("");
        setMobileError("");
      }
    }
  };

  const handleAuthChange = (e) => {
    setVcode(e.target.value);
    if (!e.target.value) {
      setMessage("This field is required");
      return false;
    } else {
      setMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginVia === "OTP" && !verification_code && !email && !mobile) {
      setVerificationCodeError("This field is required");
      setEmailError("This field is required");
      return false;
    }
    if (loginVia === "Password" && !password && !email && !mobile) {
      setPasswordError("This field is required");
      setEmailError("This field is required");
      return false;
    }

    if (loginVia === "OTP" && !verification_code) {
      setVerificationCodeError("This field is required");
      return false;
    }
    if (!email && !mobile) {
      setEmailError("This field is required");
      return false;
    }
    if (loginVia === "Password" && !password) {
      setPasswordError("This field is required");
      return false;
    }
    if (mobileError || verificationCodeError || passwordError || emailError) {
      return false;
    }

    let values = {};
    loginVia === "Password"
      ? (values = {
          Password: password,
        })
      : (values = {
          verification_code: verification_code,
        });
    if (email.length > 0) {
      values.email = email;
    }
    if (mobile.length > 0) {
      values.mobile = mobile;
    }

    axios.post(baseUrl + "/frontapi/login", values).then((res) => {
      let resp = res.data;

      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }

      if (resp.status === true) {
        localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");
        localStorage.setItem("mobile", resp.data[0] ? resp.data[0].mobile : "");
        if (resp.token !== undefined) {
          localStorage.setItem("token", resp.token ? resp.token : "");
          localStorage.setItem(
            "mobile",
            resp.data[0] ? resp.data[0].mobile : ""
          );
          localStorage.setItem("id", resp.data[0] ? resp.data[0].id : "");

          const config = {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          };

          var userIdDataIp = {
            ip: localStorage.getItem("ip"),
            device: device,
            browser: browserName + " " + browserVersion,
            osName: osName + " " + osVersion,
            cityName: cityName,
            countryName: countryName,
          };
          async function getLoginInsert() {
            const resp = await axios.post(
              baseUrl + "/frontapi/login-insert",
              userIdDataIp,
              config
            );
          }

          getLoginInsert();
          toast.dismiss();
          toast.success("Login Successfully");
          // window.location.href = urlLink;
          window.location.href = "/";
        } else {
          setLoginStatus(true);
        }
      }
      return;
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div className="">
        <ToastContainer />
        <div className="register-main-outer position-relative d-flex align-items-center flex-wrap">
          <span className="main-bg fadeInLeft animated"></span>
          <div className="register-inner">
            <div className="row m-0">
              <div className="col-12 p-0">
                <div className="register-right fadeInRight animated ">
                  <div className="heading-link-outer">
                    <div className="row align-items-center">
                      <div className="col-sm-12 text-left">
                        <div className="heading">
                          <h2 className="m-0">Login</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="register-form">
                    {LoginStatus === false || LoginStatus === "" ? (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group position-relative">
                              <label>Email or Mobile Number</label>

                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                placeholder="Enter Email or Mobile Number"
                                name="Email"
                                value={handleState}
                                onChange={handleChange}
                                disabled={counter === 0 ? false : true}
                              />

                              {mobileError && (
                                <div style={{ color: "red" }}>
                                  {mobileError}
                                </div>
                              )}
                              {emailError && (
                                <div style={{ color: "red" }}>{emailError}</div>
                              )}
                            </div>
                          </div>
                          {loginVia === "Password" ? (
                            <div className="col-md-12 col-12">
                              <div className="form-group">
                                <label>Password</label>
                                <div className="eye_pass">
                                  <input
                                    className="form-control"
                                    autoComplete="off"
                                    type={type}
                                    name="password"
                                    id="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={handleChange1}
                                    // onBlur={handleBlur}
                                  />
                                  <span style={{ color: "red" }}>
                                    {passwordError}
                                  </span>

                                  <span
                                    className="password__show eye1"
                                    onClick={handleClick}
                                  >
                                    {type === "text" ? (
                                      <i className="las la-eye"></i>
                                    ) : (
                                      <i className="las la-low-vision"></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12 col-12">
                              <div className="form-group">
                                <label>Verification Code</label>
                                <div className="get-outer position-relative">
                                  <input
                                    type="number"
                                    className="form-control otp-verification"
                                    placeholder="Enter Verification Code"
                                    name="verification_code"
                                    id="verification_code"
                                    value={verification_code}
                                    onKeyDown={handleValidation}
                                    onChange={handleChange1}
                                    disabled={buttonDisableVerification}
                                    // onBlur={handleBlur}
                                    aria-label="Input group example"
                                    aria-describedby="btnGroupAddon"
                                  />
                                  <div className="get-btn position-absolute">
                                    <button
                                      className="btn btn_man"
                                      id="btnGroupAddon"
                                      type="button"
                                      onClick={getCode}
                                      disabled={counter === 0 ? false : true}
                                    >
                                      {timerText}
                                      {"  "}
                                      {counter === 0 ? textForCode : counter}
                                    </button>
                                  </div>
                                </div>
                                <div style={{ color: "red" }}>
                                  {verificationCodeError}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-6 col-12">
                            <div className="via-otp">
                              <a
                                className="login-links"
                                href="javascript:void(0)"
                                onClick={viaOTPClick}
                              >
                                Via{" "}
                                {loginVia == "Password" ? "OTP" : "Password"}
                              </a>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="forgot-btn text-right">
                              <a href="/forgot" className="login-links">
                                {" "}
                                Forgot password ?{" "}
                              </a>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="form-group log-btn mx-auto">
                              <button
                                className="btn w100 btn_man"
                                type="submit"
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group text-center ">
                          Don't have an Account? Let's
                          <a href="/register" className="login-links">
                            {" "}
                            Sign Up
                          </a>
                        </div>
                      </form>
                    ) : (
                      <div className="">
                        <h5>Two Factor Authentication</h5>
                        <div className="form-group">
                          <label className="fw-500">Enter Auth Code</label>
                          <input
                            type="text"
                            className="form-control"
                            name="vcode"
                            placeholder="Enter Code"
                            value={vcode}
                            onChange={handleAuthChange}
                          />
                          <p className="text-danger">{message} </p>
                        </div>
                        <div className="form-group">
                          <button
                            onClick={verifyCode}
                            className="btn btn_man w100px"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default LoginFunction;
