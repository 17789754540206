import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import Nav from "../Common/Nav";
import Footer from "../Common/Footer";
import axios from "axios";
import { ProductName } from "../Common/ProductName";
import { baseUrl } from "../Common/BaseUrl";

const BlogMore = () => {
  const meta = {
    title: `Blogs | ${ProductName}  Exchange`,
    description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [record, setRecord] = useState([]);
  const [recordStatus, setRecordStatus] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(baseUrl + "/frontapi/getAllBlogs", {}).then((res) => {
      var resp = res.data;
      setRecordStatus(resp.status);
      setRecord(resp.data);
    });
  };

  const showTableHtml = () => {
    if (recordStatus === true) {
      const html = [];
      record.map((value, i) => {
        return html.push(
          <div className="col-md-4 col-sm-6 col-12 more-space">
            <div className="market-outer">
              <div className="market-inner ">
                <div className="img-outer">
                  <a href={"/blog/" + window.btoa(value.id)}>
                    <img
                      src={baseUrl + "/static/blogImage/" + value.image}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="content-outer">
                  <h6>{value.title}</h6>
                  <p>{value.description}</p>
                  <a href={"/blog/" + window.btoa(value.id)}>read more</a>
                </div>
              </div>
            </div>
          </div>
        );
      });

      return html;
    }
  };

  return (
    <DocumentMeta {...meta}>
      <Nav graphColor={""} />
      <div className="market-news-outer more-blog-main">
        <div className="container">
          <h2 className="text-center mb-4">Blogs</h2>

          <div className="row">{showTableHtml()}</div>
        </div>
      </div>

      <Footer />
    </DocumentMeta>
  );
};

export default BlogMore;
