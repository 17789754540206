import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class SpotSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,
      spotTab: "",
      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: "",
      showData: 1,
      totalBalance: 0,
      defaultValue: "",
      button: "Buy",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    // this.spotList();
    this.spotList();
    this.WalletAllCoinBalance();
  }

  componentDidMount() {
    // this.OpenMain("BTC");
  }

  //   trasactionList = () => {
  //     let headerData = {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //     };
  //     axios
  //       .post(baseUrl + "/frontapi/transaction-list1", {}, headerData)
  //       .then((resp1) => {
  //         var resp = resp1.data;

  //         if (resp.status) {
  //           this.setState({
  //             allData: resp.data,
  //             trasactionList: resp.data.main_wallet,
  //             cryptoIdWallet: resp.data.coin_id,
  //             TransactionsStatus: resp.status,
  //             defaultValue: resp.data[0].walletSum[0].walletAmount,
  //             defaultWalletTable: resp.data[0].wallet, //,spotList: resp.data.Spot_wallet,
  //           });
  //           this.OpenMain(resp.data[0]);
  //         }
  //       });
  //   };

  spotList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/MyDeposite", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          this.setState({
            allData: resp.data,
            trasactionList: resp.data.main_wallet,
            cryptoIdWallet: resp.data.coin_id,
            TransactionsStatus: resp.status,
            defaultValue: resp.data[0].walletSum[0].walletAmount,
            defaultWalletTable: resp.data[0].wallet, //,spotList: resp.data.Spot_wallet,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });

    if (this.state.showData === 1) {
      //  this.setState({totalBalance : resp.data[0].getValue.main[0].coin_amount});
    }
  };

  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };

  showTableHtml = (value) => {
    if (this.state.TransactionsStatus === true) {
      const html = [];
      let balance = 0;
      let index;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].transAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;

      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }

          if (e.tx_type == "buy_exchange") {
            var Buy = "Buy";
          }
          if (e.tx_type == "sell_exchange") {
            var Buy = "Sell";
          }
          if (e.tx_type == "sell_exchange" && e.remark == "adminFees") {
            var Buy = "Sell (fees)";
          }
          if (e.tx_type == "buy_exchange" && e.remark == "adminFees") {
            var Buy = "Buy (fees)";
          }
          html.push(
            <tr>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {i + 1}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.coin_amount}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {" "}
                {e.tx_type !== "Wallet to transactions"
                  ? e.tx_type == "buy_exchange" || e.tx_type == "sell_exchange"
                    ? Buy
                    : e.tx_type
                  : "Wallet to spot"}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {WalletBAlnace.toFixed(7)}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.coin_pair}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {Moment(e.created).format("lll")}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.status == "completed" && "Completed"}
              </td>
            </tr>
          );
          WalletBAlnace = WalletBAlnace - e.coin_amount;
        });
      return <tbody className="">{html}</tbody>;
    }

    // if (this.state.TransactionsStatus === true || this.state.button == "Sell") {
    //   const html = [];
    //   const _this = this;
    //   let msg = [];
    //   let TransactionType = "";
    //   let status = "",
    //     renderhtml = [];
    //   value &&
    //     value.wallet &&
    //     value.wallet.forEach((e, i) => {
    //       html.push(
    //         <tr>
    //           <td>{i + 1}</td>
    //           <td>{e.sell_spend_amount}</td>
    //           {/* <td>{e.tx_id }</td>*/}
    //           <td>{e.coin_pair}</td>
    //           <td> {e.per_price}</td>
    //           <td>Sell</td>
    //           {/* <td>  { e.trans_type === "deposit"? "Deposit"  :e.tx_type}</td> */}
    //           <td>{Moment(e.created).format("lll")}</td>
    //           <td>{e.per_price * e.sell_spend_amount}</td>
    //         </tr>
    //       );
    //     });
    //   return <tbody className="">{html}</tbody>;
    // }
  };

  ButtonChange = () => {
    this.setState({ button: "Buy" });
  };

  ButtonChange1 = () => {
    this.setState({ button: "Sell" });
  };

  renderCoinList = (allData) => {
    let { mainWallet } = this.state;
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li onClick={() => this.OpenMain(item)}>
          <span className={active ? "active" : <></>} key={index}>
            {item.name}
          </span>
        </li>
      );
    });
  };
  render() {
    const meta = {
      title: `Spot Transactions | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { showData, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  {/* <LeftTab /> */}
                  {/* </div> */}
                  {/* <div className="col-lg-9 padding_d"> */}
                  <div className="white_box">
                    <ul className="nav nav-tabs2 mb-3 ">
                      <li className="nav-item">
                        <Link to="/mainWalletTransactions"> Main Wallet </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/spotHistory" className="active">
                          {" "}
                          Spot{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinDeposite"> Deposit Coin </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/coinFundRequest"> Withdrawal </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/deposit-inr-request">
                          {" "}
                          Deposit INR Requests{" "}
                        </Link>
                      </li> */}
                    </ul>

                    <ul className="nav nav-tabs offer_beu spot">
                      {this.renderCoinList(allData || [])}
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className=" top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Amount</th>
                                      <th>Transaction Type</th>
                                      <th>Total Balance</th>
                                      <th>Pair</th>
                                      <th>Date & Time</th>
                                      {/* <th>Created</th> */}
                                      <th>Status</th>
                                    </tr>
                                  </thead>

                                  {this.showTableHtml(
                                    this.state.showData || []
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane " id="Transactions">
                        <div className="tab-content">
                          <div className="tab-pane active " id="Ledger"></div>
                          <div className="tab-pane" id="Transactions2">
                            <div className="wallet_box">
                              <div className="table-responsive">
                                <table className="table ">
                                  <thead>
                                    <tr>
                                      <th>Created</th>
                                      <th>Deposit</th>
                                      <th>Withdraw</th>
                                      <th className="tr">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* {this.showTableHtml()} */}
                                    <tr>
                                      <td>Nov 20, 2020</td>
                                      <td>200</td>
                                      <td>Description </td>
                                      <td className="tr">0</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>
          </div> */}
          </div>

          <Footer />
        </div>
      </DocumentMeta>
    );
  }
}

export default SpotSell;
