import React from "react";
import Header from "./HomepageElements/Header";
import Table from "./HomepageElements/Table";
import Testimonial from "./HomepageElements/Testimonial";
import Appss from "./HomepageElements/Appss";
import MarketNews from "./HomepageElements/MarketNews";
import Footer from "../Common/Footer";
import TradingIdea from "./HomepageElements/TradingIdea";
import $ from "jquery";
import { useEffect } from "react";
const Index = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".multiple-items").slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    });
  }, []);

  return (
    <div>
      <Header />
      <Table />
      <Testimonial />
      <TradingIdea />
      <Appss />
      <MarketNews />
      <Footer />
    </div>
  );
};

export default Index;
