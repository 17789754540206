import React, { useEffect, useState } from "react";
import { EmailValidation } from "../Validation/EmailValidation";
import { MobileValidation } from "../Validation/MobileValidation";
import axios from "axios";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { MobileApi } from "./Apis";
import Bank from "./Bank";
const baseUrl = myConstList.baseUrl;
const Personal = (props) => {
  const [userdata, setUserData] = useState([]);
  const [length, setLength] = useState(0);
  const [email, setEmail] = useState("");
  const [verification_code, setVerification_code] = useState("");
  const [counter, setCounter] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [personalStatus, setPersonalStatus] = useState(0);
  const [textForCode, setTextForCode] = useState("Get Code");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeVerifyStatus, setCodeVerifyStatus] = useState(false);
  const [codeBtnDisable, setCodeBtnDisable] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setLength(props.userinfo.length);
      setUserData(props.userinfo);
      setEmail(props.userinfo[0].email);
      setMobile(props.userinfo[0].mobile_no);
      setPersonalStatus(props.userinfo[0].pi_status);
    }
  }, [props]);

  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data.data;
      if (resp.length > 0) {
      }
    });
  };

  const resendUser = (e) => {
    GetUserData();
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name == "email") {
      setTextForCode("Get Code");
      setVerification_code("");
      value = value.toLowerCase();
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
    }
    if (name == "verification_code") {
      setVerification_code(value);
      if (!value) {
        setCodeError("This field is required");
      } else {
        setCodeError("");
      }
    }
    if (name == "mobile") {
      setMobile(value);
      const error = MobileValidation(name, value);
      setMobileError(error);
    }
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setCodeBtnDisable(true);
      setTimerText("Resend In");
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setCodeBtnDisable(false);
        setTimerText("");
      }
    }, 1000);
  };
  const getCode = async () => {
    if (emailError) {
      return false;
    }
    const emailvalidation = EmailValidation("email", email);
    if (emailvalidation) {
      setEmailError(emailvalidation);
      return false;
    }

    let data = {
      email: email,
      page: "kyc",
    };
    await axios.post(baseUrl + "/frontapi/getcode", data).then((resp) => {
      let response = resp.data;
      setBtnDisable(false);
      if (response.status) {
        setCodeError("");
        // setVerification_code(response.code);
        startCountdown(30);
        toast.success(response.EmailMessage);
      } else {
        toast.error(response.message);
      }
    });
  };
  const handleSubmit = async (e) => {
    if (!email && !verification_code) {
      setEmailError("This field is required");
      setCodeError("This field is required");
      return false;
    }
    if (!email) {
      setEmailError("This field is required");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }
    if (emailError || codeError) {
      return false;
    }

    let data = {
      page: "kyc",
      email: email,
      mobile: mobile,
      verification_code: verification_code,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setTimeout(() => {}, 50);
    await axios
      .post(baseUrl + "/frontapi/codeVerify", data, config)
      .then((resp) => {
        let response = resp.data;
        if (response.status) {
          toast.success(response.message);
          window.location.reload();
          props.resendResult(true);
          setCodeVerifyStatus(true);
        } else {
          toast.error(response.message);
        }
      });
  };
  const handleMobileSubmit = async () => {
    if (!mobile) {
      setMobileError("Mobile number is required");
      return false;
    }
    if (mobileError) {
      return false;
    }

    const firstDigit = parseInt(mobile.charAt(0));

    // Check if the first digit is 6 or greater than 6
    // if (firstDigit < 5) {
    //   setMobileError("Please enter valid mobile number");
    //   return false;
    // }

    let data = {
      mobile: mobile,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await MobileApi(data, config);
    if (result.status) {
      toast.success(result.message);
      window.location.reload();

      props.resendResult(true);
    } else {
      toast.error(result.message);
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="personal-outer">
        <div className="row align-items-top">
          <div className="col-md-3 col-12">
            {props.userinfo.length > 0 &&
            props.userinfo[0].kycVerification_status === "Y" ? (
              <div className="white_box heading-main">
                <h5
                  // className={
                  //   props.userinfo.length > 0 &&
                  //   props.userinfo[0].kycVerification_status === "Y"
                  //     ? "position-relative complete"
                  //     : "position-relative "
                  // }
                  className="position-relative"
                >
                  {length > 0 && userdata[0].kycVerification_status === "Y" ? (
                    <i class="fa fa-check-circle" aria-hidden="true">
                      Verification Completed
                    </i>
                  ) : (
                    ""
                  )}
                  {/* <i class="fa fa-check-circle" aria-hidden="true">Verification Completed</i> */}
                </h5>
              </div>
            ) : (
              ""
            )}

            <div className="white_box heading-main m-0">
              <h6
                className={
                  props.status.length > 0 && props.status[0].personal == "2"
                    ? "position-relative complete"
                    : "position-relative "
                }
              >
                {length > 0 &&
                  userdata[0].country_code == "+91" &&
                  "Email Verification"}

                {length > 0 &&
                  userdata[0].country_code != "+91" &&
                  "Mobile Number Verification"}
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].bank == "1"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                Bank Verification{" "}
                {length > 0 &&
                  userdata[0].country_code != "+91" &&
                  "(Optional)"}
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].upi == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                UPI Verification (Optional)
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].nominee == "1"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                Nominee Verification (Optional)
              </h6>

              {length > 0 && userdata[0].country_code == "+91" ? (
                <>
                  <h6
                    className={
                      props.status.length > 0 && props.status[0].aadhaar == "2"
                        ? "position-relative complete"
                        : props.status.length > 0 &&
                          props.status[0].aadhaar == "3"
                        ? "position-relative rejected"
                        : "position-relative"
                    }
                  >
                    Aadhaar Card Verification
                  </h6>
                  <h6
                    className={
                      props.status.length > 0 && props.status[0].pan == "2"
                        ? "position-relative complete"
                        : props.status.length > 0 && props.status[0].pan == "3"
                        ? "position-relative rejected"
                        : "position-relative"
                    }
                  >
                    PAN Card Verification
                  </h6>
                </>
              ) : (
                <h6
                  className={
                    props.status.length > 0 && props.status[0].passport == "2"
                      ? "position-relative complete"
                      : props.status.length > 0 &&
                        props.status[0].passport == "3"
                      ? "position-relative rejected"
                      : "position-relative"
                  }
                >
                  Nation ID Verification
                </h6>
              )}
              <h6
                className={
                  props.status.length > 0 && props.status[0].selfie == "2"
                    ? "position-relative complete"
                    : props.status.length > 0 && props.status[0].selfie == "3"
                    ? "position-relative rejected"
                    : "position-relative"
                }
              >
                Face Verification
              </h6>
            </div>
          </div>

          {/* for email address */}

          <div className="col-md-9 col-12">
            <div className="white_box">
              {length > 0 && userdata[0].country_code == "+91" && (
                <>
                  <h6 className="position-relative">"Email Verification"</h6>

                  <p>
                    Fill in your personal information the way it is stated in
                    the proof of identity document you submit
                  </p>

                  <div className="fs12">
                    <form>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Email Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email Address"
                            minLength="2"
                            maxlength="70"
                            value={email}
                            disabled={
                              length > 0 && userdata[0].email
                                ? true
                                : false || codeBtnDisable
                            }
                            onChange={handleChange}
                          />
                          {emailError && (
                            <div className="text-danger">{emailError}</div>
                          )}
                          {length > 0 && !userdata[0].email && (
                            <div className="submit-btn">
                              <button
                                className="btn btn_man fs12 mt-3"
                                type="button"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          )}
                        </div>
                        {length > 0 && !userdata[0].email && (
                          <div className="form-group col-md-6">
                            <label>Verification Code</label>
                            <div className="input-group-prepend">
                              <input
                                type="number"
                                className="form-control"
                                name="verification_code"
                                placeholder="Enter Verification Code"
                                minLength="2"
                                maxlength="20"
                                disabled={btnDisable}
                                onKeyDown={handleValidation}
                                value={verification_code}
                                onChange={handleChange}
                              />
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn_man"
                                  id="btnGroupAddon"
                                  type="button"
                                  onClick={getCode}
                                  disabled={codeBtnDisable}
                                >
                                  {timerText}
                                  {"  "}
                                  {counter == 0 ? textForCode : counter}
                                </button>
                              </div>
                            </div>
                            {codeError && (
                              <div className="text-danger">{codeError}</div>
                            )}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}

              {/* for mobile number */}
              {length > 0 && userdata[0].country_code != "+91" && (
                <>
                  <h6 className="position-relative">
                    "Mobile Number Verification"
                  </h6>

                  <p>
                    Fill in your personal information the way it is stated in
                    the proof of identity document you submit
                  </p>

                  <div className="fs12">
                    <div className="row">
                      <div className="form-group col-md-6 m-0">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          name="mobile"
                          placeholder="Enter Mobile Number"
                          minLength="2"
                          maxlength="20"
                          onKeyDown={handleValidation}
                          value={mobile}
                          disabled={
                            length > 0 && userdata[0].mobile_no ? true : false
                          }
                          onChange={handleChange}
                        />
                        {mobileError && (
                          <div className="text-danger">{mobileError}</div>
                        )}
                      </div>
                      {length > 0 && !userdata[0].mobile_no && (
                        <div className="submit-btn">
                          <button
                            className="btn btn_man fs12"
                            type="button"
                            onClick={handleMobileSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {personalStatus !== "0" && (
              <Bank userinfo={userdata} resendResult={resendUser} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Personal;
