import React from "react";
import ForgotForm from "./LoginElement/ForgotForm";
import Nav from "../Common/Nav";
import MainHeader from "../Common/MainHeader";
import ForgotFunction from "./LoginElement/ForgotFunction";

const Forgot = () => {
  return (
    <div>
      <MainHeader />
      {/* <ForgotForm /> */}
      <ForgotFunction />
    </div>
  );
};

export default Forgot;
