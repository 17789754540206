import React, { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./components/Common/BaseUrl";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./components/Homepage/Index";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";
// import AccountINR from "./components/Account/AccountINR";
// import AccountNew from "./components/Account/AccountNew";
import Security from "./components/SettingsFile/Security";
import Kyc from "./components/Kyc/index";
// import Verification from "./components/SettingsFile/Verification";
import Forgot from "./components/Login/Forgot";
// import GoogleAuth from "./components/SettingsFile/GoogleAuth";
import ResetPassword from "./components/Login/LoginElement/ResetPassword";
import ApiKeys from "./components/SettingsFile/ApiKeys";
import help from "./components/help";
// import Exchange from "./components/ExchangeFull/Exchange";
import ExchangeSock from "./components/ExchangeFull/ExchangeSocket";
import chatting from "./components/chatting";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RiskStatement from "./components/RiskStatement";
import TermsOfConditions from "./components/TermsOfConditions";
import AboutUs from "./components/AboutUs";
import faq from "./components/faq";
import { history } from "./helpers/history";
import NotFound from "./components/NotFound";
import transactions from "./components/SettingsFile/transactions";
import spot from "./components/SettingsFile/spot";
import Auth from "./components/Login/Auth";
import EmailAuth from "./components/Login/EmailAuth";
import CoinWithdrawalUsers from "./components/SettingsFile/CoinWithdrawalUser";
import MobileChart from "./components/SettingsFile/MobileChart";
import SpotSell from "./components/SettingsFile/SpotSell";
import DepositInrRequest from "./components/SettingsFile/DepositInrRequest";
import DepositeHistory from "./components/SettingsFile/DepositeHistory";
import CoinDepositeUser from "./components/SettingsFile/CoinDepositeUser";
import Swap from "./components/SettingsFile/Swap";
import Profile from "./components/SettingsFile/Profile";
import whiteList from "./components/WhiteList/index";
// import Refer from "./components/SettingsFile/Refer";
import ReferFunction from "./components/SettingsFile/ReferFunction";
import Stacking from "./components/SettingsFile/Stacking";
import MyStacking from "./components/SettingsFile/MyStacking";
import LaunchPad from "./components/SettingsFile/LaunchPad";
import Listing from "./components/SettingsFile/Listing";
import ApplyListing from "./components/SettingsFile/ApplyListing";
import LaunchPadForm from "./components/SettingsFile/LaunchPadForm";
import LaunchPadDetails from "./components/SettingsFile/LaunchPadDetails";
import BlogDetails from "./components/SettingsFile/BlogDetails";
import BlogMore from "./components/SettingsFile/BlogMore";
import CommingSoon from "./components/CommingSoon";
import ApproveVerification from "./components/Account/ApproveVerification";
import RejectVerification from "./components/Account/RejectVerification";
import HelpFunction from "./components/HelpFunction";
import ChattingFunction from "./components/ChattingFunction";
import SpotFunction from "./components/SettingsFile/SpotFunction";
import SpotSellFunction from "./components/SettingsFile/SpotSellFunction";
import TransactionsFunction from "./components/SettingsFile/TransactionsFunction";
import SpotHistoryFunction from "./components/SettingsFile/SpotHistoryFunction";
import CoinDepositFunction from "./components/SettingsFile/CoinDepositFunction";
import CoinWithdrawalFunction from "./components/SettingsFile/CoinWithdrawalFunction";
import AccountFunction from "./components/Account/AccountFunction";
import InrDepositRequest from "./components/SettingsFile/InrDepositRequest";
import ExchangeVerify from "./components/ExchangeFull/ExchangeVerify";
import Fee from "./components/SettingsFile/Fee";
import Video from "./components/SettingsFile/Video";
import Download from "./components/SettingsFile/Download";
import TermsOfUse from "./components/SettingsFile/TermsOfUse";
import ReferralProgram from "./components/SettingsFile/ReferralProgram";
import Announcements from "./components/SettingsFile/Announcements";
import News from "./components/SettingsFile/News";
import TradingDetails from "./components/SettingsFile/TradingDetails";
import TradingMore from "./components/SettingsFile/TradingMore";

const App = () => {
  if (localStorage.token) {
    const token = localStorage.token;
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.clear();
      window.location.href = "/";
    }
  }

  const checkBlock = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      if (resp.data.status === true) {
        let is_deleted = resp.data.data[0].is_deleted;

        if (is_deleted == 1 || is_deleted == 2) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    checkBlock();
    // var token = localStorage.getItem("token");
    // if (token) {
    //   if (
    //     window.location.pathname == "/register-for-bitcoin-exchange" ||
    //     window.location.pathname == "/login" ||
    //     window.location.pathname == "/forgot"
    //   ) {
    //     window.location.href = "/";
    //   }
    // }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={["/", "/index"]} component={Index} />
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path={["/register", "/register/:id"]}
          component={Register}
        />
        <Route exact path="/" component={Index} />
        {/* <Route exact path="/account" component={Account} /> */}
        <Route exact path="/account" component={AccountFunction} />
        <Route
          exact
          path="/approveVerification/:id"
          component={ApproveVerification}
        />
        <Route
          exact
          path="/rejectVerification/:id"
          component={RejectVerification}
        />
        {/* <Route path="/accountinr" component={AccountINR} />
        <Route path="/accountnew" component={AccountNew} /> */}
        <Route exact path="/whitelist" component={whiteList} />
        <Route exact path="/security" component={Security} />
        <Route exact path="/kyc" component={Kyc} />
        {/* <Route exact path="/verification" component={Verification} /> */}
        <Route exact path="/forgot" component={Forgot} />
        {/* <Route exact path="/Google_auth" component={GoogleAuth} /> */}
        <Route exact path="/reset/:userId/:token" component={ResetPassword} />
        <Route exact path="/ApiKeys" component={ApiKeys} />
        {/* <Route exact path="/help" component={help} /> */}
        <Route exact path="/help" component={HelpFunction} />
        <Route exact path="/faq-for-crypto-exchange" component={faq} />
        {/* <Route exact path="/Chatting/:coin/:status" component={chatting} /> */}
        <Route
          exact
          path="/Chatting/:coin/:status"
          component={ChattingFunction}
        />
        <Route
          exact
          path="/contact-us-for-crypto-exchange"
          component={ContactUs}
        />
        <Route
          exact
          path="/secure-crypto-exchange-platform"
          component={PrivacyPolicy}
        />
        <Route
          exact
          path="/crypto-buying-selling-online"
          component={RiskStatement}
        />
        <Route
          exact
          path="/terms-&-conditions-of-crypto-exchange"
          component={TermsOfConditions}
        />
        <Route exact path="/buy-sell-crypto-online" component={AboutUs} />
        <Route exact path="/exchange/" component={ExchangeVerify} />
        <Route
          exact
          path="/exchange/:firstCoin/:secondCoin"
          component={ExchangeSock}
        />
        {/* <Route exact path="/mainWalletTransactions" component={transactions} /> */}
        <Route
          exact
          path="/mainWalletTransactions"
          component={TransactionsFunction}
        />
        {/* <Route exact path="/spotBuyHistory" component={spot} /> */}
        <Route exact path="/spotBuyHistory" component={SpotFunction} />
        {/* <Route exact path="/deposit-inr-request" component={DepositInrRequest} /> */}
        <Route exact path="/GoogleAuthLogin" component={Auth} />
        <Route exact path="/Swap" component={Swap} />
        <Route exact path="/emailAuth" component={EmailAuth} />
        <Route
          exact
          path="/MobileChart/:firstCoin/:secondCoin"
          component={MobileChart}
        />
        {/* <Route exact path="/spotSellHistory" component={SpotSell} /> */}
        <Route exact path="/spotSellHistory" component={SpotSellFunction} />
        {/* <Route exact path="/spotHistory" component={DepositeHistory} /> */}
        <Route exact path="/spotHistory" component={SpotHistoryFunction} />
        {/* <Route exact path="/coinDeposite" component={CoinDepositeUser} /> */}
        <Route exact path="/coinDeposite" component={CoinDepositFunction} />
        {/* <Route exact path="/coinFundRequest" component={CoinWithdrawalUsers} /> */}
        <Route
          exact
          path="/coinFundRequest"
          component={CoinWithdrawalFunction}
        />
        <Route exact path="/inrDepositRequest" component={InrDepositRequest} />
        {/* <Route exact path="/refer" component={Refer} /> */}
        <Route exact path="/refer" component={ReferFunction} />
        .
        <Route exact path={"/profile"} component={Profile} />
        <Route exact path="/stacking" component={Stacking} />
        <Route exact path="/myStacking" component={MyStacking} />
        <Route exact path="/launchPad" component={LaunchPad} />
        {/* <Route exact path="/launchPad" component={CommingSoon} /> */}
        <Route exact path="/launchPadForm" component={LaunchPadForm} />
        <Route
          exact
          path="/launchPadDetails/:id"
          component={LaunchPadDetails}
        />
        {/* listing */}
        {/* <Route exact path="/listing" component={CommingSoon} /> */}
        <Route exact path="/listing" component={Listing} />
        <Route exact path="/applyListing" component={ApplyListing} />
        {/* Blog */}
        <Route exact path="/blog/:id" component={BlogDetails} />
        <Route exact path="/blogs" component={BlogMore} />
        {/* Blog End */}
        {/* trading idea */}
        <Route exact path="/trading-idea/:id" component={TradingDetails} />
        <Route exact path="/trading-ideas" component={TradingMore} />
        {/* trading idea End */}
        <Route exact path="/fee" component={Fee} />
        <Route exact path="/video" component={Video} />
        <Route exact path="/referralProgram" component={ReferralProgram} />
        <Route exact path="/termsOfUse" component={TermsOfUse} />
        <Route exact path="/download" component={Download} />
        <Route exact path="/announcements" component={Announcements} />
        <Route exact path="/news" component={News} />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
