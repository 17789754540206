import React from "react";
import icon1 from "../../../img/icon1.png";
import icon1_h from "../../../img/icon1-h.png";
import icon2 from "../../../img/icon2.png";
import icon2_h from "../../../img/icon2-h.png";
import icon3 from "../../../img/icon3.png";
import icon3_h from "../../../img/icon3-h.png";

class Testimonial extends React.Component {
  render() {
    return (
      <div className="how-main-outer">
        <div className="container">

          <div className="how-outer">
            <div className="row align-items-center">
              <div className="col-12 col-lg-4">
                <div className="section-heading ">
                  <h6>VenCrypto</h6>
                  <h2>Simple Steps to Start Crypto Trading With VenCrypto</h2>
                  <a href="/exchange/TRX/USDT" class="btn btn_man mt-md-4" >Start Trading</a>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="row">
                  <div className="col-md-6 col-12  mt-md-4">
                    <div className="how-inner ">
                      <div className="img-outer">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="content-outer">

                        <h3>Sign-Up/Registration</h3>
                        <p>Create your account on our crypto exchange platform to start crypto trading.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <div className="how-inner ">
                      <div className="img-outer">
                        <i className="fa fa-university"></i>
                      </div>
                      <div className="content-outer">

                        <h3>Add & Verify Your Bank</h3>
                        <p>In this step, you will be completing your KYC process to add your bank details.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-4  mt-md-4">
                    <div className="how-inner ">
                      <div className="img-outer">
                        <i className="fa fa-usd"></i>
                      </div>
                      <div className="content-outer">

                        <h3>Add Fund in Your Wallet</h3>
                        <p>Now add funds to your wallet to take one more step towards starting crypto trading.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="how-inner ">
                      <div className="img-outer">
                        <i className="fa fa-signal"></i>
                      </div>
                      <div className="content-outer">

                        <h3>Enjoy Crypto Trading</h3>
                        <p>Now buy, sell or transfer the cryptocurrencies as per your choice and current market trends.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
