import React from "react";
import { ToastContainer } from "react-toastify";
import WhiteList from "./Whitelist";
import WhitelistFunction from "./WhitelistFunction";
import LoginHeader from "../Common/LoginHeader";

function index() {
  return (
    <>
      <LoginHeader id={"allow"} />
      {/* <WhiteList /> */}
      <WhitelistFunction />
      <ToastContainer limit={1} />
    </>
  );
}

export default index;
