import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Nav from "../Common/Nav";
import DocumentMeta from "react-document-meta";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "Listings | VenCrypto  Exchange ",
  description:
    "VenCrypto  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://VenCrypto  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Listing = () => {
  const [tableStatus, setTableStatus] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [limitStatus, setLimitStatus] = useState(false);
  const [limitData, setLimitData] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.get(baseUrl + "/frontapi/allListings", {}, config).then((res) => {
      var resp = res.data;

      // Get the first 5
      const firstFive = resp.data.slice(0, 5);

      setTableData(resp.data);
      setTableStatus(resp.status);

      setLimitData(firstFive);
      setLimitStatus(resp.status);
    });
  };

  // const listHtml = () => {
  //   if (tableStatus === true) {
  //     const html = [];
  //     tableData.map(function (value, i) {
  //       const pair = value.pair;

  //       html.push(
  //         <tr>
  //           <td>{i + 1}</td>
  //           <td>{pair}</td>
  //           <td>{value.currentPrice ? value.currentPrice : 0}</td>
  //           <td>
  //             {!value.changePricePercent ? (
  //               <b className="text-danger">0%</b>
  //             ) : (
  //               ""
  //             )}
  //             {value.changePricePercent > 0 ? (
  //               <b className="text-success">{value.changePricePercent}%</b>
  //             ) : (
  //               ""
  //             )}
  //             {value.changePricePercent < 0 ? (
  //               <b className="text-danger">{value.changePricePercent}%</b>
  //             ) : (
  //               ""
  //             )}
  //           </td>
  //           <td>{value.oneDayVolume ? value.oneDayVolume : 0}</td>
  //         </tr>
  //       );
  //     });
  //     return <tbody className="main">{html}</tbody>;
  //   }
  // };

  const boxHtml = () => {
    if (limitStatus === true) {
      const html = [];
      limitData.map(function (value, i) {
        const pair = value.pair;
        const parts = pair.split("/"); // Split the string into an array using "/"
        const currency = parts[1];

        html.push(
          <div className="volume-outer">
            <div className="crypto-pairs">
              <h5 className="d-flex align-items-center justify-content-between m-0">
                {pair}{" "}
                {!value.changePricePercent ? (
                  <small className="text-danger">0%</small>
                ) : (
                  ""
                )}
                {value.changePricePercent > 0 ? (
                  <small className="text-success">
                    {value.changePricePercent}%
                  </small>
                ) : (
                  ""
                )}
                {value.changePricePercent < 0 ? (
                  <small className="text-danger">
                    {value.changePricePercent}%
                  </small>
                ) : (
                  ""
                )}
                {/* <small className="text-danger">-1.30%</small> */}
              </h5>
            </div>
            <div className="volume-inner">
              <p className="m-0">
                <b>{value.currentPrice ? value.currentPrice : 0}</b>
              </p>
              <p className="m-0">
                Trade Volume: {value.oneDayVolume ? value.oneDayVolume : 0}{" "}
                {currency}
              </p>
            </div>
          </div>
        );
      });
      return html;
    }
  };

  const checkLogin = () => {
    if (!localStorage.getItem("token")) {
      toast.dismiss();
      toast.error("Please login to apply");
      return false;
    } else {
      toast.dismiss();
      window.location.href = "/applyListing";
    }
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        {/* <MainHeader /> */}

        <header className="home position-relative launch-home" id="home">
          <div className="container">
            <div className="home-outer launchpaid-outer launchpaid-off">
              <div className="banner-left">
                <h1>LISTING</h1>
                <h2>Looking to get your crypto project noticed? Our exchange is the perfect platform to showcase your digital assets and reach a wider audience.</h2>
                <p>With our robust security measures and user-friendly interface, you can list your crypto with confidence. Gain exposure to our growing community of crypto enthusiasts and investors hungry for the next big thing.
                </p>

                <div className="banner-btn-outer">
                  {/* <a href='javascript:void(0)' className='btn btn-primary'>Trade Now</a> */}

                  <button className="btn btn_man" onClick={checkLogin}>
                    Apply for Listing
                  </button>
                </div>
              </div>
              <div className="banner-right">
                <img
                  src="assets/img/listing-right.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </header>

        {/* <div className="trade-volume">
          <div className="container">
            <div className="apply-btn">
              <buttton className="btn btn_man" onClick={checkLogin}>
                Apply for Listing
              </buttton>
            </div>

            <div className="volume-main d-flex flex-wrap">{boxHtml()}</div>
          </div>
        </div> */}

        {/* <div className="listing-main">
          <div className="container">
            <div className="listing-outer">
              <div className="search-btn d-flex flex-wrap justify-content-between align-items-center">
                <div className="apply-btn">
                  <buttton className="btn btn_man" onClick={checkLogin}>
                    Apply for Listing
                  </buttton>
                </div>
                <div className="search-bar search-outer">
                  <form class="d-flex position-relative">
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <button class="btn position-absolute" type="submit">
                      &#x1F50E;
                    </button>
                  </form>
                </div>
              </div>
              <div className="listing-table">
                <table className=" table table-striped mb-0 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>24h Change</th>
                      <th>24h Vol (VD)</th>
                    </tr>
                  </thead>
                  {listHtml()}
                </table>
              </div>
            </div>
          </div>
        </div> */}

        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default Listing;
