import React from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";

const RiskStatement = () => {
  const meta = {
    title: `Risk Management | ${ProductName} Exchange`,
    description: `At ${ProductName} Exchange Live We work hard to ensure a highly secured and user friendly trading platform for the traders and users in India. Do Your multi currency crypto exchange.`,
    canonical: `https://${ProductName} exchange.in/crypto-buying-selling-online`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Multi Currency Crypto Exchange, Crypto Trading Platform, Bitcoin Exchange, Crypto Platform",
      },
    },
  };
  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="privacy_policy">
            <div className="container">
              <h2>VenCrypto Risk Statement</h2>
              <p>
                VenCrypto (hereinafter referred to as "VenCrypto" or "we" or
                "us") operates an online platform for the trading of digital
                assets, including cryptocurrencies and tokens (collectively
                referred to as "Coins"), which can be accessed at
                https://Vencryptoexchange.in/ (hereinafter referred to as the
                "Platform").
              </p>
              <h3>Restrictions on Trading</h3>
              <p>
                VenCrypto does not allow the trading of Coins that are
                considered "financial products" for legal purposes in India
                (also known as "securities" outside India) on its platform.
              </p>
              <h3>Important Warning</h3>

              <p>
                1. Trading in Coins is highly speculative and involves
                significant risk. There is a possibility of losing all or a part
                of your money or Coins placed on the Platform. Your use of the
                Platform is entirely at your own risk.
              </p>
              <p>
                2. Prior to trading on the Platform, it is crucial that you
                thoroughly read all available information, including the risks
                outlined below, and carefully consider your personal financial
                situation. If you are uncertain about any aspect of trading
                Coins, it is strongly recommended that you seek independent
                advice before using the Platform.
              </p>
              <h3>Support Requests and Complaints</h3>

              <p>
                1. We offer a complimentary complaints and IT support service
                for the Platform. Our aim is to acknowledge customer requests
                and complaints within three business days and to resolve them,
                if possible, within 5 to 15 business days.
              </p>
              <p>
                2. However, during periods of high trading activity on the
                Platform, our response times may be longer. This can occur due
                to the extreme volatility and market sentiment sensitivity of
                Coin markets. Thus, we cannot guarantee immediate responses and
                believe it is essential for users to be aware of possible
                delays.
              </p>
              <h3>Risks Associated with Using the VenCrypto Platform</h3>

              <p>
                1. <b>Market Risks</b> Coins are susceptible to extreme price
                volatility. The exchange price of a Coin may undergo significant
                changes, resulting in possible difficulty in transacting Coins
                or money at anticipated rates or prices. Such fluctuations can
                lead to substantial value changes and/or losses of Coins or
                money.
              </p>
              <p>
                2. Past performance is not a reliable indicator or guarantee of
                future performance. The value of Coins can decrease as well as
                increase.
              </p>
              <p>
                3. The value of Coins can be influenced by various factors,
                including but not limited to future sales or additional
                issuances (e.g., airdrops), negative publicity affecting the
                Coin issuer or project, failure to meet project expectations,
                technical setbacks or failures, cyber-attacks on the underlying
                network, fraud, theft, competition, and general global economic
                conditions. It is crucial to conduct thorough research on the
                Coins of interest, as their whitepapers or offer materials may
                list further relevant risks.
              </p>
              <h3>Risks Related to Processing of Transactions</h3>
              <p>
                1. There is a risk of unsettled or delayed transactions due to
                various issues, such as user error in providing transaction
                details, errors in transaction consideration delivery, increases
                in market or Platform volume, failures in the Platform
                processing systems or the underlying network or software. Once a
                digital currency transaction commences, it may not be
                reversible.
              </p>
              <p>
                1. <b>System Risks</b> All Coins and their associated
                transactions depend on underlying networks and software. As
                these technologies are still developing, they may be susceptible
                to technical weaknesses, bugs, system failures, and hacks by
                external parties. Such failures can impact the Platform network
                and software or a Coin's underlying network and software (e.g.,
                weaknesses in the underlying blockchain).
              </p>
              <p>
                2. Users should be aware of sophisticated hackers and potential
                phishing attacks or scams, where third parties impersonate
                VenCrypto sites, social media accounts, or support numbers to
                steal credentials. To prevent unauthorized account use,
                two-factor authentication for all transactions is strongly
                recommended. Users must not store their VenCrypto passwords
                insecurely on any personal device.
              </p>
              <p>
                3. Technical failures or attacks may affect users' ability to
                use the Platform, trade Coins, or withdraw money.
              </p>
              <p>
                4. VenCrypto will make reasonable efforts to inform users about
                any technical weaknesses, bugs, system failures, or hacks
                affecting the Platform or specific Coins traded on the Platform.
              </p>
              <p>
                5. Periodic maintenance or upgrades may impact users' ability to
                use the Platform, trade Coins, or withdraw money.
              </p>
              <h3>Risks Related to Security of Private Keys and Wallets</h3>
              <p>
                1. Users must exercise caution when selecting a wallet for
                storing or transmitting private keys related to their Coins. In
                case of wallet hacking or unauthorized access to private keys,
                users may lose some or all of their Coins. Private keys or
                wallet passcodes should not be shared with others.
              </p>
              <p>
                2. In case of forgetting or losing wallet passcodes, VenCrypto
                cannot provide a backup or details of private keys or passcodes
                due to the decentralized nature of Coins, potentially leading to
                the loss of Coins stored in that wallet.
              </p>
              <p>
                3. Users should opt for the highest level of security offered by
                their chosen wallet.
              </p>
              <h3>Cybersecurity Considerations</h3>
              <p>
                1. The transmission of information over the internet, including
                to and from the Platform, may not be entirely secure or
                error-free. Users should cease transactions if security breaches
                or system failures that pose security risks (such as malware,
                ransomware, or phishing) are detected.
              </p>
              <h3>Regulatory Risks</h3>
              <p>
                As of now, there is no specific regulation of Coins and Coin
                exchanges in India, and regulations may evolve rapidly. Existing
                laws and regulations may also be subject to limited guidance
                concerning their application to Coins and Coin exchanges.
                Changes in laws and regulations may adversely affect Coin
                trading and the Platform.
              </p>
              <p>
                2. VenCrypto may delist Coins if they are considered financial
                products under Indian law or for other reasons. Delisting may
                result in a limited market for selling the Coin, especially if
                it is not listed on another exchange.
              </p>
              <p>
                3. Users are responsible for ensuring compliance with the
                applicable laws concerning digital currency trading in their
                respective countries when using the Platform.
              </p>
              <p>
                4. Additionally, VenCrypto has no control over whether Coin
                issuers comply with laws in relevant jurisdictions. Regulatory
                actions against a Coin issuer or any other party may hinder Coin
                sales or cause value loss.
              </p>
              <p>
                5. Regulatory issues can also impact essential relationships,
                such as our relationship with banks, which may lead to the
                limitation of certain products.
              </p>
              <p>
                6. Users outside India may be subject to the laws and
                regulations of other countries, potentially affecting their use
                of the Platform or causing changes in Platform availability or
                operations.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default RiskStatement;
