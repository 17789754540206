import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

const baseUrl = myConstList.baseUrl;

export default function ExchangeKycHeader(props) {
  const [isDepositModelOpen, setisDepositModelOpen] = useState(false);
  const [kycStatus, setkycStatus] = useState("");
  const [device, setDevice] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [user, setUser] = useState("Guest");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [checked, setchecked] = useState(true);
  const [LofinInsert1, setLofinInsert1] = useState(false);
  const [usdtPortfolio, setUsdtPortfolio] = useState(0);
  const [inrPortfolio, setInrPortfolio] = useState(0);
  const [accHolder, setAccHolder] = useState("");
  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }
    const token = localStorage.getItem("token");
    if (token) {
      GetUserData();
    }
    // portfolioData();
    getGeoInfo();
    DeviceName();
    var theme = localStorage.getItem("theme");
    if (theme == null || theme == "null") {
      theme = false;
    }
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }
    if (LofinInsert1 == true) {
      LoginInert();
    }
    urlData();
  }, []);

  useEffect(() => {
    if (props.portfolioData && props.portfolioData.data.length > 0) {
      // setInrPortfolio(props.portfolioData && props.portfolioData[0].INR);
      setUsdtPortfolio(props.portfolioData && props.portfolioData.data[0].USDT);
    }
  }, [props.portfolioData]);

  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;

      if (resp.data[0].is_deleted == "1") {
        localStorage.clear();
      }
      if (resp.data[0].enable_2fa != "N" || resp.data.enable_2fa != "") {
        setLofinInsert1(true);
      }
      setEmail(resp.data[0].email);
      setAccHolder(resp.data[0].AccHolder);
      setMobile_no(resp.data[0].mobile_no);
      if (resp.data[0].nickname !== "null") {
        setUser(resp.data[0].nickname);
      }
      if (resp.data[0].nickname == "null" || resp.data[0].nickname == null) {
        setUser("Guest");
      }

      var token = localStorage.getItem("token");
      if (token != null) {
        if (
          resp.data[0].kycVerification_status === "N" ||
          resp.data[0].kycVerification_status === "null"
        ) {
        }
        setkycStatus(resp.data[0].kycVerification_status);
        if (resp.data[0].kycVerification_status === "Y") {
          setisDepositModelOpen(false);
        } else {
          setisDepositModelOpen(true);
        }
      }
    });
  };

  const urlData = () => {
    axios.get(baseUrl + "/frontapi/getUrl1").then((resp) => {
      var resp = resp.data;
      setUrlLink(
        `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`
      );
    });
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        setCountryName(data.country_name);
        setCountryName(data.city);
      })
      .catch((error) => {});
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
    }
    if (isAndroid) {
      setDevice("Android");
    }
    if (isIOS) {
      setDevice("IOS");
    }
    if (isDesktop) {
      setDevice("Desktop");
    }
  };

  const LoginInert = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var userIdDataIp = {
      ip: localStorage.getItem("ip"),
      device: device,
      browser: browserName + " " + browserVersion,
      osName: osName + " " + osVersion,
      cityName: cityName,
      countryName: countryName,
    };
    if (LofinInsert1 == false) {
      return;
    }
    async function getLoginInsert() {
      await axios.post(
        baseUrl + "/frontapi/login-insert",
        userIdDataIp,
        config
      );
    }
    getLoginInsert();
  };

  function modifyText(text) {
    if (text.length <= 16) {
      return text;
    } else {
      return text.substr(0, 13) + "...";
      // return text.substr(0, 13);
    }
  }

  const showHeaderPart = () => {
    var token = localStorage.getItem("token");

    if (token === null) {
      return (
        <ul className="">
          <li className="">
            <Link to="/login">Log in</Link>
          </li>
          <li className="">
            <Link to="/register">Sign up</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="">
          <li className="renone">Portfolio Value:</li>
          {/* <ToastContainer position="left" /> */}
          <li className="renone">
            <table>
              <tbody>
                <tr>
                  <div className="amount-inner d-flex">
                    <div className="amount-right ml-2">
                      <p className="mb-3">
                        <b> {parseFloat(usdtPortfolio)}</b> USDT
                      </p>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </li>

          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                {accHolder
                  ? modifyText(accHolder)
                  : email
                  ? modifyText(email)
                  : modifyText(mobile_no)}
                {/* {localStorage.getItem("email")} */}
              </span>
              <div className="dropdown-menu2">
                <Link className="a_flex" to="/profile">
                  <i class="fa fa-user-circle mr-1"></i> Profile
                </Link>
                {/* <Link className="a_flex" to="/mainWalletTransactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}
                <Link className="a_flex" to="/Kyc">
                  <i className="las la-share-square"></i> My KYC
                </Link>
                <Link className="a_flex" to="/myStacking">
                  <i className="fa fa-stack-exchange"></i> My Staking
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="las la-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="las  la-sign-in-alt"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    toast.success("Logout successfully");
    let interval = setInterval(() => {
      var theme = localStorage.getItem("theme");
      localStorage.clear();
      localStorage.setItem("theme", theme);
      window.location.href = "/login";
      clearInterval(interval);
    }, 1000);
  };

  const handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      props.graphColor(false);
      setchecked(false);
    } else {
      tags[0].classList.remove("dark-theme");
      props.graphColor(true);
      setchecked(true);
    }
    localStorage.setItem("theme", checked);
  };
  var token = localStorage.getItem("token");
  return (
    <div className="">
      <nav className="navbar navbar-expand-lg nav-scroll2">
        <div className="container-fluid ">
          <a className="navbar-brand" href ="/index">
            <img
              src="/assets/img/logo2.png"
              alt="header-Logo"
              className="logo logo1"
            />
            <img
              src="/assets/img/logo2.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav line align-items-center">
              <li
                className={
                  window.location.pathname == "/exchange/" + urlLink
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                {!token ? (
                  <a className="nav-link " href={"/exchange/" + urlLink}>
                    Exchange
                  </a>
                ) : (
                  <a className="nav-link " href={"/exchange/" + urlLink}>
                    Exchange
                  </a>
                )}
              </li>

              <li
                className={
                  window.location.pathname == "/account"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/account">
                  Account
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/mainWalletTransactions" ||
                  window.location.pathname == "/spotHistory" ||
                  window.location.pathname == "/coinDeposite" ||
                  window.location.pathname == "/coinFundRequest"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/mainWalletTransactions">
                  Transactions
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/spotBuyHistory" ||
                  window.location.pathname == "/spotSellHistory"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link " to="/spotBuyHistory">
                  Trade
                </Link>
              </li>

              <li className="nav-item">
                {checked == false ? (
                  <img
                    src="/assets/img/moon.png"
                    width="25px"
                    alt="moon"
                    className="moon-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                ) : (
                  <img
                    src="/assets/img/sun.png"
                    width="25px"
                    alt="sun"
                    className="sun-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                )}
              </li>
            </ul>
          </div>

          <div className="dashbord_menu">{showHeaderPart()}</div>
        </div>
      </nav>
    </div>
  );
}
