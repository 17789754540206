import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import DocumentMeta from "react-document-meta";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const SpotFunction = () => {
  const meta = {
    title: `Buy | Trade History | ${ProductName}  Exchange`,
    description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [mainWallet, setMainWallet] = useState(1);
  const [allData, setAllData] = useState("");
  const [showData, setShowData] = useState(1);
  const [TransactionsStatus, setTransactionsStatus] = useState("");

  useEffect(() => {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    trasactionListData();
    // WalletAllCoinBalance();
  }, []);

  const trasactionListData = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/transaction-list1", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          setAllData(resp.data);
          setTransactionsStatus(resp.status);

          OpenMain(resp.data[0]);
        }
      });
  };

  const OpenMain = (value) => {
    setMainWallet(value.coin_id);
    setShowData(value);
  };

  const showTableHtml = (value) => {
    if (TransactionsStatus === true) {
      const html = [];
      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{e.coin_pair}</td>
              <td> {e.per_price}</td>
              <td>{e.total_buy_get_amount}</td>
              <td>{e.buy_fees}</td>
              <td>{e.per_price * e.total_buy_get_amount + e.buy_fees}</td>
              <td>{Moment(e.created_at).format("lll")}</td>
            </tr>
          );
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  const renderCoinList = (allData) => {
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li onClick={() => OpenMain(item)}>
          <span className={active ? "active" : <></>} key={index}>
            {item.name}
          </span>
        </li>
      );
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="">
          <div className="bg_light">
            <div className="new_inpage">
              <div className="container container2">
                <div className="white_box">
                  <h5 className="nav-item">Trade History</h5>

                  <div className="buyselll_box">
                    <ul className="nav buy_sell_tab mb-2 ">
                      <li className="nav-item ">
                        <Link to="/spotBuyHistory" className="active">
                          {" "}
                          Buy{" "}
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="/spotSellHistory"> Sell </Link>
                      </li>
                    </ul>
                  </div>

                  <ul className="nav nav-tabs offer_beu spot">
                    {renderCoinList(allData || [])}
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="Balance">
                      <div className=" top_balance"></div>
                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>

                                    <th>Pair </th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Trade Fee</th>
                                    <th>Total</th>
                                    <th>Date & Time</th>
                                  </tr>
                                </thead>

                                {showTableHtml(showData || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane " id="Transactions">
                      <div className="tab-content">
                        <div className="tab-pane active " id="Ledger"></div>
                        <div className="tab-pane" id="Transactions2">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Created</th>
                                    <th>Deposit</th>
                                    <th>Withdraw</th>
                                    <th className="tr">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Nov 20, 2020</td>
                                    <td>200</td>
                                    <td>Description </td>
                                    <td className="tr">0</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </DocumentMeta>
  );
};

export default SpotFunction;
