import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import DocumentMeta from "react-document-meta";
import Moment from "moment";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const CoinWithdrawalFunction = () => {
  const meta = {
    title: `Withdrawal Transactions | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [mainWallet, setMainWallet] = useState(1);
  const [allData, setAllData] = useState("");
  const [showData, setShowData] = useState(1);
  const [TransactionsStatus, setTransactionsStatus] = useState("");

  useEffect(() => {
    if (!localStorage.id) {
      window.location.href = "/login";
    }

    trasactionList();
  }, []);

  const trasactionList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/coinFundRequest", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          setAllData(resp.data);
          setTransactionsStatus(resp.status);
          OpenMain(resp.data[0]);
        }
      });
  };

  const OpenMain = (value) => {
    setMainWallet(value.coin_id);
    setShowData(value);
  };

  const showTableHtml = (value) => {
    if (TransactionsStatus === true) {
      const html = [];

      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{e.coin_amount}</td>
              <td>{e.withdrawal_fees ? e.withdrawal_fees : 0}</td>
              <td className="rewidth_t">{e.tx_id}</td>
              <td>{e.tx_type}</td>
              <td>{e.tx_hash}</td>
              <td>{Moment.utc(e.created).local().format("lll")}</td>
              <td>
                {e.WithdrawlRequest === "2" ? (
                  <span>
                    Rejected{" "}
                    <Popup
                      trigger={<i className="fa fa-info-circle"></i>}
                      position="top center"
                    >
                      <div>{e.reject}</div>
                    </Popup>
                  </span>
                ) : e.WithdrawlRequest === "1" ? (
                  <span> Completed </span>
                ) : (
                  "Pending"
                )}
              </td>
            </tr>
          );
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  const renderCoinList = (allData) => {
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li key={index} onClick={() => OpenMain(item)}>
          <span className={active ? "active" : <></>}>{item.name}</span>
        </li>
      );
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="">
          <div className="bg_light">
            <div className="new_inpage">
              <div className="container container2">
                {/* <LeftTab /> */}
                <div className="white_box">
                  <ul className="nav nav-tabs2 mb-3 ">
                    <li className="nav-item">
                      <Link to="/mainWalletTransactions"> Main Wallet </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/spotHistory"> Spot </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinDeposite"> Deposit Coin </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinFundRequest" className="active">
                        {" "}
                        Withdrawal{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrDepositRequest"> INR Deposit Request </Link>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs offer_beu spot">
                    {renderCoinList(allData || [])}
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="Balance">
                      <div className="top_balance"></div>
                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Amount</th>
                                    <th>Withdrawal Fee</th>
                                    <th>Withdrawal Token ID</th>
                                    <th>Transaction Type</th>
                                    <th>Transaction Hash</th>
                                    <th>Date & Time</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                {showTableHtml(showData || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </DocumentMeta>
  );
};

export default CoinWithdrawalFunction;
