import React from "react";
import LoginForm from "./LoginElement/LoginForm";
import Nav from "../Common/Nav";
import MainHeader from "../Common/MainHeader";
import LoginFunction from "./LoginElement/LoginFunction";

const Login = () => {
  return (
    <div>
      <MainHeader />
      {/* <LoginForm /> */}
      <LoginFunction />
    </div>
  );
};

export default Login;
