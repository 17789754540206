import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ThirdPartyApi } from "./ThirdPartyApis";
import { ImageValidation } from "../Validation/ImageValidation";
import { passportApi } from "./Apis";
import * as myConstList from "../Common/BaseUrl";
import Rejectreason from "./Rejectreason";
const baseUrl = myConstList.baseUrl;

function Passport(props) {
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [passportStatus, setPassportStatus] = useState("0");
  const [passportImage, setPassportImage] = useState("");
  const [passportImageError, setPassportImageError] = useState("");
  const [useruploadPassportImage, setUseruploadPassportImage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setPassportStatus(props.userinfo[0].passport_status);
      props.userinfo[0].passport_image &&
        setImage(
          baseUrl + "/static/upload/" + props.userinfo[0].passport_image
        );
      setRejectionReason(props.userinfo[0].d5_rejectResion);
    }
  }, [props]);

  const handleChange = async (e) => {
    let name = e.target.name;
    if (name == "passportImage") {
      setPassportImage(e.target.files[0]);
      let error = ImageValidation("Passport", e.target.files[0]);
      setPassportImageError(error);
      e.target.files[0]
        ? setUseruploadPassportImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadPassportImage("");
    }
  };

  const handleSubmit = async () => {
    if (!passportImage) {
      setPassportImageError("This field is required");
      return false;
    }

    let error = ImageValidation("Passport", passportImage);
    setPassportImageError(error);

    if (error) {
      return false;
    }

    // if (passportImageError) {
    //   return false;
    // }

    // var reader = new FileReader()
    // reader.onload = function (base64) {
    //     localStorage["passportImage"] = base64;
    // }
    // reader.readAsDataURL(passportImage);
    // var data = new FormData();
    // data.append('file', passportImage);
    // ThirdPartyApi.passport.data = data

    // setTimeout(() => {
    setBtnDisable(true);
    // }, 50);

    var data = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var data = new FormData();
    data.append("passportImage", passportImage);

    let result = await passportApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
      window.location.reload();
      return false;
    } else {
      toast.error(result.message);
      props.resendResult(true);
    }
  };
  return (
    <>
      <div className="passport-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">Nation ID Verification</h6>
              <b className="mt-0">Requirements</b>
              <ul className="dit_ul mb-4">
                <li>
                  The photo/scan must be clear, in color and uncompressed.
                </li>
                <li>The photo/scan must be not edited and not cropped.</li>
                <li>
                  The document must be valid at least 3 months after the
                  verification submission date.
                </li>
                <li>
                  The full name and date of birth you provided in the "Personal
                  information" section{" "}
                </li>
                <li>should exactly match the ones in your document.</li>
                <li>Min size 10 Kb / Max size 2 Mb.</li>
              </ul>
              <div className="row">
                <div className="form-group col-lg-4 col-md-6">
                  <div id="file-upload-form" className="uploader mt-3">
                    <input
                      type="file"
                      id="passportImage"
                      accept="image/*"
                      name="passportImage"
                      onChange={handleChange}
                      disabled={
                        passportStatus == "0" || passportStatus == "3"
                          ? false
                          : true
                      }
                    />
                    <label for="passportImage" id="file-drag">
                      <img
                        src={
                          useruploadPassportImage !== ""
                            ? useruploadPassportImage
                            : image
                        }
                        className="img-fluid22"
                        alt="Identity"
                      />
                      <div id="start">
                        <span className="fasi"></span>
                        <div>Upload Nation ID Image</div>
                        <div id="notimage" className="hidden">
                          Please select an image
                        </div>
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages"></div>
                        <progress
                          className="progress"
                          id="file-progress"
                          value="0"
                        >
                          <span>0</span>%
                        </progress>
                      </div>
                    </label>
                  </div>
                  <p className="mt-0">*only jpeg, jpg & png files supported</p>

                  {passportImageError && (
                    <div className="text-danger">{passportImageError}</div>
                  )}
                </div>
              </div>
              <Rejectreason reason={rejectionReason} status={passportStatus} />
              {(passportStatus == "0" || passportStatus == "3") && (
                <button
                  className="btn btn_man w100px"
                  onClick={handleSubmit}
                  disabled={btnDisable || passportStatus == "1"}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Passport;
