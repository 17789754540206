import React from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";

const TermsOfConditions = () => {
  const meta = {
    title: `Terms & Conditions | ${ProductName} Exchange`,
    description: `${ProductName} Exchange takes crypto trading to a whole new level of performance and functionality. A Local Crypto Exchange Platform In India For Bitcoin Exchange.`,
    canonical: `https://${ProductName} Exchange.live/terms-&-conditions-of-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "High Limit Crypto Exchange, Crypto Buying Selling, Best Rate Crypto Exchange, Crypto Coin Exchange",
      },
    },
  };
  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="privacy_policy">
            <div className="container">
              <h2>VenCrypto’s Terms & Conditions</h2>
              <div className="count-outer">
                <h3>A. Introduction</h3>
                <ol>
                  <li>
                    Terms and Conditions apply to the VenCrypto website and
                    services.
                  </li>
                  <li>
                    Allows buying, selling, and exchanging supported Coins
                    through the Platform.
                  </li>
                  <li>Allows using Fiat Pegged Tokens when available.</li>
                  <li>Allows storing supported Coins in hosted Wallets.</li>
                  <li>
                    Users must read and agree to these Terms before using the
                    Platform or any Service.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>B. Understanding Your Risks</h3>
                <ol>
                  <li>Trading in Coins is speculative and high risk.</li>
                  <li>Users may lose some or all of their money or Coins.</li>
                  <li>Users must read the Vencrypto Risk Statement.</li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>C. Eligibility</h3>
                <ol>
                  <li>
                    Users must meet certain criteria to use the Platform and
                    Services.
                  </li>
                  <li>Users must be legally entitled to use the Platform.</li>
                  <li>Individual users must be 18 years or older.</li>
                  <li>
                    Entity users must be correctly formed and in good standing.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>D. Your Account</h3>
                <ol>
                  <li>
                    Users must open an Account to use the Platform and Services.
                  </li>
                  <li>
                    Proof of identity is required during the Account opening
                    process.
                  </li>
                  <li>
                    Users must provide accurate information during the Account
                    opening process.
                  </li>
                  <li>
                    Users are responsible for all activities that occur under
                    their Account.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>E. Suspension or Closure of Account by Admin</h3>
                <ol>
                  <li>
                    The platform can suspend, limit, restrict, or close an
                    Account for various reasons.
                  </li>
                  <li>
                    Suspension or closure may happen without notice in certain
                    cases.
                  </li>
                  <li>
                    The platform can suspend or close an Account if it suspects
                    a breach of Terms.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>F. Your Coin Balances</h3>
                <ol>
                  <li>
                    Coin Balances are part of the Account, allowing users to
                    send, receive, and store supported Coins.
                  </li>
                  <li>
                    Users must not attempt to use unsupported Coins in their
                    Account.
                  </li>
                  <li>
                    Users must not send Coins to the wrong wallet address.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>G. Trading on the Platform</h3>
                <ol>
                  <li>Users acknowledge the risks involved in Transactions.</li>
                  <li>
                    VenCrypto may impose restrictions for efficient processing
                    and risk reduction.
                  </li>
                  <li>
                    Users can only use the Platform for lawful and permitted
                    purposes.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>H. Platform Change and Business Disruptions</h3>
                <ol>
                  <li>
                    The Platform may undergo maintenance or upgrade, causing
                    temporary unavailability.
                  </li>
                  <li>Changes to the Platform may occur without notice.</li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>I. Supported Coin</h3>
                <ol>
                  <li>VenCrypto will publish a list of supported Coins.</li>
                  <li>
                    Users are responsible for determining whether to acquire,
                    exchange, or sell any Coin.
                  </li>
                  <li>
                    The list of supported Coins may change without notice.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>J. Payments</h3>
                <ol>
                  <li>Mistaken payments may not be reversible.</li>
                  <li>
                    Payments can be declined or reversed for various reasons.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>K. Other Services and Content</h3>
                <ol>
                  <li>Third-Party Content may be displayed on the Platform.</li>
                  <li>
                    Users interact with Third-Party Content at their own risk.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>L. Liability, Indemnities, and Force Majeure</h3>
                <ol>
                  <li>
                    VenCrypto disclaims liability for various losses and
                    damages.
                  </li>
                  <li>
                    Users agree to indemnify and hold VenCrypto harmless from
                    claims.
                  </li>
                  <li>Force majeure events may impact platform performance.</li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>M. Fees and Expenses</h3>
                <ol>
                  <li>
                    Users agree to pay fees associated with using the Services.
                  </li>
                  <li>Fees may change from time to time.</li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>N. Taxes</h3>
                <ol>
                  <li>
                    Users are responsible for understanding and paying
                    applicable taxes
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>O. Intellectual Property</h3>
                <ol>
                  <li>
                    All logos, content, materials, etc., on the Platform are
                    owned by VenCrypto.
                  </li>
                  <li>
                    Users have limited rights to access and use the Intellectual
                    Property.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>P. Your Privacy</h3>
                <ol>
                  <li>
                    Vencrypto’s privacy policy governs the use of user
                    information.
                  </li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>Q. Notices and Communication</h3>
                <ol>
                  <li>Users consent to receive electronic communications.</li>
                  <li>Users must keep their contact details up-to-date.</li>
                </ol>
              </div>
              <div className="count-outer">
                <h3>R. General</h3>
                <ol>
                  <li>VenCrypto may amend the Terms at any time.</li>
                  <li>
                    Assignment, transfer, and subcontracting rights are reserved
                    for VenCrypto.
                  </li>
                  <li>
                    Severability clause ensures invalid clauses do not affect
                    the rest of the Terms.
                  </li>
                  <li>Non-exercise of rights does not constitute a waiver.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default TermsOfConditions;
