import React, { useState, useEffect } from "react";
import axios from "axios";
import { ThirdPartyApi } from "./ThirdPartyApis";
import { ImageValidation } from "../Validation/ImageValidation";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { BankApi } from "./Apis";
const baseUrl = myConstList.baseUrl;

const Bank = (props) => {
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [acType, setAcType] = useState("");
  const [bankImage, setBankImage] = useState("");
  const [useruploadBankImage, setUseruploadBankImage] = useState("");

  const [acTypeError, setAcTypeError] = useState("");
  const [bankImageError, setBankImageError] = useState(false);

  const [bankName, setBankName] = useState("");
  const [accHolderName, setAccHolderName] = useState("");
  const [accHolderNameError, setAccHolderNameError] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accNumberError, setAccNumberError] = useState("");
  const [bankStatus, setBankStatus] = useState("0");
  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setAccHolderName(props.userinfo[0].AccHolder);
      setBankStatus(props.userinfo[0].bank_status);
      setAccNumber(props.userinfo[0].AccNum);
      setIfsc(props.userinfo[0].Ifsc);
      setCountryCode(props.userinfo[0].country_code);

      setAcType(props.userinfo[0].ac_type);
      props.userinfo[0].bank_image &&
        setImage(baseUrl + "/static/upload/" + props.userinfo[0].bank_image);
    }
  }, [props]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "ifsc") {
      setIfsc(value);
      if (!value) {
        setIfscCodeError("This field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        setIfscCodeError("Please enter a valid ifsc code");
        return false;
      }
      if (!value.match("^[A-Z]{4}0[A-Z0-9]{6}$")) {
        setIfscCodeError("Please enter a valid ifsc code");
        return false;
      }
      setIfscCodeError("");
    }

    if (name == "acType") {
      setAcType(value);
      if (!value) {
        setAcTypeError("Please select Account type");
        return false;
      }

      setAcTypeError("");
    }

    if (name == "swift") {
      // const regexSwift = /^[a-zA-Z0-9]{8,11}$/;

      const regexSwift = /^[A-Z]{4}[A-Z]{2}[A-Z]{2}\d{3}$/;
      setIfsc(value);
      if (!value) {
        setIfscCodeError("This field is required");
        return false;
      }

      if (!regexSwift.test(value)) {
        setIfscCodeError("Please enter a valid SWIFT code");
        return false;
      }
      setIfscCodeError("");

      // if (value.replace(/\s+/g, "").length == 0) {
      //   setIfscCodeError("Please enter a valid swift code");
      //   return false;
      // }
    }

    if (name == "accNumber") {
      setAccNumber(value);
      if (!value) {
        setAccNumberError("This field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        setAccNumberError("Please enter a valid account number");
        return false;
      }
      if (!value.match(/^[0-9]{6,17}$/)) {
        setAccNumberError("Please enter a valid account number");
        return false;
      }
      setAccNumberError("");
    }
    if (name == "accHolderName") {
      setAccHolderName(value);
      if (!value) {
        setAccHolderNameError("This field is required");
        return false;
      }
      // const regex = /^[A-Za-z]+$/;
      const regex = /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/;
      if (!regex.test(value)) {
        setAccHolderNameError("Please enter a valid name");
        return false;
      }

      // if (value.replace(/\s+/g, "").length == 0) {
      //   setAccHolderNameError("Please enter a valid account number");
      //   return false;
      // }
      setAccHolderNameError("");
    }

    if (name == "bankImage") {
      let error = ImageValidation("Bank image", value);
      setBankImageError(error);
      setBankImage(e.target.files[0]);
      e.target.files[0]
        ? setUseruploadBankImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadBankImage("");
    }
  };

  const handleSubmit = async () => {
    if (!acType && !accHolderName && !accNumber && !ifsc) {
      setAcTypeError("This field is required");
      setAccHolderNameError("This field is required");
      setAccNumberError("This field is required");
      setIfscCodeError("This field is required");
      return false;
    }

    if (!acType) {
      setAcTypeError("This field is required");
      return false;
    }

    if (!accHolderName) {
      setAccHolderNameError("This field is required");
      return false;
    }

    // const regex = /^[A-Za-z]+$/;

    const regex = /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/;

    if (!regex.test(accHolderName)) {
      setAccHolderNameError("Please enter a valid name");
      return false;
    }

    let hasLeadingSpace = /^\s/.test(accHolderName);
    let hasTrailingSpace = /\s$/.test(accHolderName);

    if (hasLeadingSpace) {
      setAccHolderNameError("Please enter a valid name");
      return false;
    }

    if (hasTrailingSpace) {
      setAccHolderNameError("Please enter a valid name");
      return false;
    }

    if (!accNumber) {
      setAccNumberError("This field is required");
      return false;
    }

    const allDigitsNotZero = !/^0*$/.test(accNumber);

    if (!allDigitsNotZero) {
      setAccNumberError("All digits cannot be zero");
      return false;
    }

    if (!ifsc) {
      setIfscCodeError("This field is required");
      return false;
    }

    if (countryCode == "+91") {
      if (ifsc.replace(/\s+/g, "").length == 0) {
        setIfscCodeError("Please enter a valid ifsc code");
        return false;
      }
    } else {
      // const regexSwift = /^[a-zA-Z0-9]{8,11}$/;
      const regexSwift = /^[A-Z]{4}[A-Z]{2}[A-Z]{2}\d{3}$/;
      if (!regexSwift.test(ifsc)) {
        setIfscCodeError("Please enter a valid SWIFT code");
        return false;
      }
    }

    if (!bankImage) {
      setBankImageError("This field is required");
      return false;
    }

    if (acTypeError || accNumberError || ifscCodeError || accHolderNameError) {
      return false;
    }

    setTimeout(() => {
      setBtnDisable(true);
    }, 50);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let formData = new FormData();

    formData.append("Bank", bankImage);
    formData.append("acType", acType);
    formData.append("Ifsc", ifsc);
    formData.append("AccNum", accNumber);
    formData.append("AccName", accHolderName);
    
    const result = await BankApi(formData, config);
    setBtnDisable(false);
    if (result.status) {
      props.resendResult(true);
      toast.success(result.message);
      window.location.reload();
    } else {
      props.resendResult(true);
      toast.error(result.message);
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      {/* <div className="bank-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12"> */}
      <div className="white_box">
        <h6 className="position-relative">
          Bank Verification {countryCode != "+91" ? "(Optional)" : ""}
        </h6>
        <b className="mt-0">Requirements</b>
        <ul className="dit_ul mb-4">
          <li>
            The document must be valid at least 3 months after the verification
            submission date.
          </li>
        </ul>
        <div className="row">
          <div className="form-group col-lg-4 col-md-6">
            <label>Account Type</label>
            <select
              className="form-control"
              name="acType"
              value={acType}
              onChange={handleChange}
              disabled={bankStatus !== "0" ? true : false}
            >
              <option value={""}>Select</option>
              <option value={"savings"}>Savings Account</option>
              <option value={"current"}>Current Account</option>
            </select>
            {acTypeError && <div className="text-danger">{acTypeError}</div>}
          </div>

          <div className="form-group col-lg-4 col-md-6">
            <label>Account Holder Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Account Holder Name"
              name="accHolderName"
              value={accHolderName}
              maxLength={40}
              onChange={handleChange}
              disabled={bankStatus !== "0" ? true : false}
            />
            {accHolderNameError && (
              <div className="text-danger">{accHolderNameError}</div>
            )}
          </div>
          <div className="form-group col-lg-4 col-md-6">
            <label>Account Number </label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Account Number"
              name="accNumber"
              value={accNumber}
              onKeyPress={handleValidation}
              onChange={handleChange}
              disabled={bankStatus !== "0" ? true : false}
            />
            {accNumberError && (
              <div className="text-danger">{accNumberError}</div>
            )}
          </div>

          {countryCode == "+91" && (
            <div className="form-group col-lg-4 col-md-6">
              <label>IFSC Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter IFSC Code"
                name="ifsc"
                value={ifsc}
                onChange={handleChange}
                disabled={bankStatus !== "0" ? true : false}
              />
              {ifscCodeError && (
                <div className="text-danger">{ifscCodeError}</div>
              )}
            </div>
          )}

          {countryCode != "+91" && (
            <div className="form-group col-lg-4 col-md-6">
              <label> SWIFT Code </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter SWIFT Code"
                name="swift"
                value={ifsc}
                onChange={handleChange}
                disabled={bankStatus !== "0" ? true : false}
              />
              {ifscCodeError && (
                <div className="text-danger">{ifscCodeError}</div>
              )}
            </div>
          )}

          <div className="form-group col-md-4">
            <label>PassBook / Cheque Book / Bank Statement</label>
            <div id="file-upload-form" className="uploader">
              <input
                type="file"
                id="file-upload2"
                name="bankImage"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                disabled={bankStatus !== "0" ? true : false}
              />
              <label for="file-upload2" id="file-drag">
                <img
                  src={useruploadBankImage !== "" ? useruploadBankImage : image}
                  className="img-fluid22"
                  alt="BankImage"
                />
                <div id="start">
                  <span className="fasi"></span>
                  <div>Bank Image</div>
                </div>
                <div id="response" className="hidden">
                  <div id="messages"></div>
                  <progress className="progress" id="file-progress" value="0">
                    <span>0</span>%
                  </progress>
                </div>
              </label>
            </div>
            <p className="mt-0">*only jpeg, jpg &amp; png files supported</p>
            {bankImageError && (
              <div className="text-danger">{bankImageError}</div>
            )}
          </div>
        </div>
        {bankStatus !== "1" && (
          <button
            className="btn btn_man w100px"
            onClick={handleSubmit}
            disabled={btnDisable || bankStatus == "1"}
          >
            Submit
          </button>
        )}
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default Bank;
