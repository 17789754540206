import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

const SpotHistoryFunction = () => {
  const meta = {
    title: `Spot Transactions | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  const [mainWallet, setMainWallet] = useState(1);
  const [allData, setAllData] = useState("");
  const [showData, setShowData] = useState(1);
  const [TransactionsStatus, setTransactionsStatus] = useState("");

  useEffect(() => {
    spotList();
  }, []);

  const spotList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/MyDeposite", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          setAllData(resp.data);
          setTransactionsStatus(resp.status);
          OpenMain(resp.data[0]);
        }
      });
  };

  const OpenMain = (value) => {
    setMainWallet(value.coin_id);
    setShowData(value);
  };

  const showTableHtml = (value) => {
    if (TransactionsStatus === true) {
      const html = [];
      let balance = 0;
      let index;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].transAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;

      value &&
        value.wallet &&
        value.wallet.forEach((e, i) => {
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }

          if (e.tx_type == "buy_exchange") {
            var Buy = "Buy";
          }
          if (e.tx_type == "sell_exchange") {
            var Buy = "Sell";
          }
          if (e.tx_type == "sell_exchange" && e.remark == "adminFees") {
            var Buy = "Sell (fees)";
          }
          if (e.tx_type == "buy_exchange" && e.remark == "adminFees") {
            var Buy = "Buy (fees)";
          }
          html.push(
            <tr>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {i + 1}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.coin_amount}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {" "}
                {e.tx_type !== "Wallet to transactions"
                  ? e.tx_type == "buy_exchange" || e.tx_type == "sell_exchange"
                    ? Buy
                    : e.tx_type
                  : "Wallet to spot"}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {WalletBAlnace.toFixed(7)}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.coin_pair}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {Moment(e.created).format("lll")}
              </td>
              <td
                style={
                  e.tx_type == "sell_exchange" ||
                  e.tx_type == "Withdrawal" ||
                  e.tx_type == "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.status == "completed" && "Completed"}
              </td>
            </tr>
          );
          WalletBAlnace = WalletBAlnace - e.coin_amount;
        });
      return <tbody className="">{html}</tbody>;
    }
  };

  const renderCoinList = (allData) => {
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li onClick={() => OpenMain(item)}>
          <span className={active ? "active" : <></>} key={index}>
            {item.name}
          </span>
        </li>
      );
    });
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="">
          <div className="bg_light">
            <div className="new_inpage">
              <div className="container container2">
                <div className="white_box">
                  <ul className="nav nav-tabs2 mb-3 ">
                    <li className="nav-item">
                      <Link to="/mainWalletTransactions"> Main Wallet </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/spotHistory" className="active">
                        {" "}
                        Spot{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinDeposite"> Deposit Coin </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/coinFundRequest"> Withdrawal </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrDepositRequest"> INR Deposit Request </Link>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs offer_beu spot">
                    {renderCoinList(allData || [])}
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="Balance">
                      <div className=" top_balance"></div>
                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Amount</th>
                                    <th>Transaction Type</th>
                                    <th>Total Balance</th>
                                    <th>Pair</th>
                                    <th>Date & Time</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                {showTableHtml(showData || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane " id="Transactions">
                      <div className="tab-content">
                        <div className="tab-pane active " id="Ledger"></div>
                        <div className="tab-pane" id="Transactions2">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Created</th>
                                    <th>Deposit</th>
                                    <th>Withdraw</th>
                                    <th className="tr">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Nov 20, 2020</td>
                                    <td>200</td>
                                    <td>Description </td>
                                    <td className="tr">0</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </DocumentMeta>
  );
};

export default SpotHistoryFunction;
