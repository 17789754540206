import React from "react";

function Rejectreason(props) {
  return (
    <>
      {props.status == "3" && (
        <div style={{ color: "red" }}>
          {props.status == "3" ? "Rejected" : "Verified"} - {props.reason}
          <br />
        </div>
      )}
      {props.status == "1" && (
        <div style={{ color: "red" }}>
          <h5>Waiting for admin to verify</h5>
        </div>
      )}
    </>
  );
}

export default Rejectreason;
