import React from "react";
import Footer from "../Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "../Common/ProductName";
import MainHeader from "../Common/MainHeader";

const Announcements = () => {
  const meta = {
    title: `Announcements | ${ProductName}  Exchange`,
    description: `Download User Friendly Crypto Exchange App, A Secure Crypto Exchange Platform For BTC, ETH, TRX, DOGE & Other Crypto Currencies In India. Visit ${ProductName}  Exchange Live Website.`,
    canonical: `https://${ProductName}  Exchange.in/announcements`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Platform, Cryptocurrencies App, Indian Crypto Exchange, Bitcoin Exchange",
      },
    },
  };
  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
        </div>
      </DocumentMeta>
      <Footer />
    </div>
  );
};

export default Announcements;
