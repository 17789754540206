import React from "react";
import BigNumber from "bignumber.js";
import Moment from "moment";
const MarketTrade = (props) => {
  var trades = props.trades;
  var currentPair = props.currentPair;
  const tradesArr = Object.values(trades);
  const reversedTrades = tradesArr.reverse();
  let currentp = new BigNumber(0);
  let rows = [];
  let numRows = Object.keys(reversedTrades).length;
  let number;
  if (numRows < 10) {
    number = numRows;
  } else {
    number = 10;
  }
  for (var i = 0; i < number; i++) {
    let newp = new BigNumber(reversedTrades[i].cost);
    rows.push(
      <tr className="ng-scope">
        <td className="crypt-up col-4">
          <span className={newp.gte(currentp) ? "text-success" : "text-danger"}>
            {newp.toFormat(null, 1)}
          </span>
        </td>
        <td className="col-4">
          {new BigNumber(reversedTrades[i].amount).toFormat(null, 1)}
        </td>
        <td className="col-4">{currentPair}</td>
        <td>
          <td>{new Date(reversedTrades[i].timestamp).toLocaleTimeString()}</td>
        </td>
      </tr>
    );
    currentp = new BigNumber(reversedTrades[i].cost);
  }

  return <>{rows}</>;
};

export default MarketTrade;
